import {React,useState} from "react";
import Masonry from "react-smart-masonry";
import '../../Tags/Tags.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBell, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import Profile1 from '../../../assets/icons/profile1.png';
import Profile2 from '../../../assets/icons/profile2.png';
import Profile3 from '../../../assets/icons/profile3.png';
import Union from '../../../assets/icons/Union-19.svg';
import clipboardNotes from '../../../assets/icons/8725602_clipboard_notes_icon.svg';
import SubTag from '../../../assets/icons/subTag.svg';
import SubShare from '../../../assets/icons/subShare.svg';
import resizeIcon from "../../../assets/icons/resizeIcon.svg";
import logo from "../../../assets/images/facebooklogo.png";
import Cardimage from "../../../assets/images/cardImage.png";
import axios from "axios";
import { useEffect } from "react"; 
import SingleCard from "../../../shades/cards/singleCard"
const breakpoints = { mobile: 0, tablet: 900, desktop: 1200 };

function RecentCards() {
    const [buckets,setBuckets]=useState([])
    const [RecentCard,setRecentCard]=useState([])
    const [isLoadings, setIsLoadings] = useState(false);
    const [fileUpdate,setFileUpdate]=useState(false)
    useEffect(() => {
        // api url
    
        const token = localStorage.getItem("token");
    
        axios
          .get(
            `
              https://api.zimzee.com/api/dashboard/recent-items`,
            
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(function (response) {
            
            setRecentCard(response?.data?.data?.cardsList);
            // setbookmarksRefresh(false);
            setIsLoadings(false);
            // setIsLoadings(false);
            // setFilesData(response?.data?.data)
            // setNotes(response?.data?.data?.note?.bookmarksList);
            // setTag(response?.data?.data?.tags?.bookmarksList);
            // console.log("filessssssssssss", response?.data?.data?.bookmarksList);
            // setFileRefresh(false);
            // updateFiles()
            // setCardList(response.data.data);
          })
          .catch(function (error) {
            console.log(error);
            setIsLoadings(false);
          });
      }, [fileUpdate]);
    useEffect(() => {
        // Function to fetch data from the API and update the state
        const token = localStorage.getItem('token');
        const fetchBuckets = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_API}buckets/user-buckets`,{
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the request header
              },
            }); // Replace this with the actual API endpoint
            setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        if (token) {
          fetchBuckets();
        }
      }, []);
      useEffect(()=>{
console.log(RecentCard,"inside recent card")
      },[RecentCard])
      const cardUpdate=()=>{
        setFileUpdate(!fileUpdate)
      }
    const filterDropDown = [
        { name: 'Created Date', href: '#' },
        { name: 'Recent Activity', href: '#' },
        { name: 'Ascending Order', href: '#' },
        { name: 'Descending Order', href: '#' },
        { name: 'Manual', href: '#' },
      ]
    
      const [filtersText, setFiltersText] = useState(filterDropDown[0].name);
      const bucketId = useParams();

      const handleFilterText = (text) => {
          setFiltersText(text);
        }
    
            
      function classNames(...classes) { 
        return classes.filter(Boolean).join(' ')
      }
return(
   
    <>
    {isLoadings ? (
        <div className="App-loading">
          <div className="App-logo" title="Zimzee" />
        </div>
      ) : (
<div className="tags-page recents-cards">

<div className="grid gap-[15px] grid-cols-2 ">
<Masonry
            breakpoints={breakpoints}
            columns={{ mobile: 1, tablet: 3, desktop: 3 }}
            gap={{ mobile: 2, tablet: 1, desktop: 15 }}
            style={{ display: "flex" }}
              // style={{paddingBottom:"10px"}}
            >
                    {RecentCard && Array.isArray(RecentCard) && RecentCard?.map((card)=>{ 
    const sharedWithObjects = card?.shared
    ? card.shared
      .filter(shares => shares.sharedWith != null)
      .map(shares => shares.sharedWith)
    : [];
                     console.log(card);
                    return <SingleCard key={card?._id} sharedBy={[]}
                    sharedUsers={sharedWithObjects}  starred={card?.starred} buckets={buckets} cardUpdate={cardUpdate} cardId={card?._id} cardType={card?.cardType} sharedLength ={card?.shared?.length} title={card?.title} content ={card?.content} note={card?.note} tags={card?.tags} postDetail={card?.postDetail? card?.postDetail : ""} updated={card?.updatedAt}  />
                  })}
            </Masonry>
            </div>
</div>
      )}
      </>

)
}
export default RecentCards;