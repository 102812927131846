import { React, useState ,useEffect} from "react";
import './PersonalDetails.scss'
import ProfileImg from '../../../assets/icons/profileImg.svg';
import DismissButton from "../../../assets/icons/dismissButton.svg";
import axios from "axios";
import { toastify } from '../../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import Avatar from 'react-avatar';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
function PersonalDetails() {
  const navigate = useNavigate();
  const [passwordPopup, setPasswordPopup] = useState(false);
  const [emailPopup, setEmailPopup] = useState(false);
  const [invitepopup, setInvitePopup] = useState(false);
  const[firstName,setFirstName]=useState('')
  const[lastName,setLastName]=useState('')
  const[email,setEmail]=useState('')
  const [currentPass,setCurrentPass]=useState('')
  const[newPassword,setNewPassword]=useState('')
  const[confirmPass,setConfirmPass]=useState('')
  const [selectedProfile,setSelectedProfile]=useState('')
  const[chooseProfile,setChooseProfile]=useState(null)
  const [dataRefresh,setDataRefresh]=useState(false)
  const [disableButton,setDisableButton]=useState(false)
  const[errorMsg,setErrorMsg]=useState('')
  const [fullName,setFullName]=useState('')
  useEffect(()=>{ 
    if(!newPassword||!confirmPass||!currentPass){
      setDisableButton(true)
    }
    else if(newPassword.length < 8)
    {
      setErrorMsg('Password length must be of 8 characters')
      setDisableButton(true)
    }
    else if(newPassword!=confirmPass){
setDisableButton(true)
setErrorMsg('Confirm Password must be same')
    }
    else{
      setErrorMsg('')
      setDisableButton(false)
    }

  },[confirmPass,newPassword,currentPass,disableButton,errorMsg])
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
 
      try {
        const response = await axios.get(
          'https://api.zimzee.com/api/users/me',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response, 'Me response');
        setDataRefresh(false)
        setFirstName(response?.data?.data?.firstName)
        setLastName(response?.data?.data?.lastName)
        setFullName(response?.data?.data?.fullName)
        setEmail(response?.data?.data?.email)
        setSelectedProfile(response?.data?.data?.imageUrl)
       
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // Call the async function immediately

   
  
    // The empty dependency array means this effect runs once after initial render.
  }, [dataRefresh]);
  const updateEmail = async () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const token = localStorage.getItem('token');
      try {
        const response = await axios.post('https://api.zimzee.com/api/users/chnage-emailAddress',{
        currentPassword: currentPass,
        email:newPassword
    },
        {
          headers: {
              Authorization: `Bearer ${token}`,
            }
        }
        );
        console.log(response);
        toastify("success", response.data.message);
        setEmailPopup(false);
      //  setIsLoading(false);
      } catch (error) {
        console.error(error);
        toastify("error", error.response.data.message );
      }
  }

  const deleteUserAccount = async () => {
      const token = localStorage.getItem('token');
      try {
            const response = await axios.delete(`${process.env.REACT_APP_API}users/delete-userAccount`,
              {
                headers: {
                    Authorization: `Bearer ${token}`,
                  }
              }
          );
          console.log(response);
          toastify("success", response.data.message);
          setInvitePopup(false);
          localStorage.removeItem('userData');
          localStorage.removeItem('token');
          localStorage.removeItem('settingsToken')
          localStorage.removeItem('cardFilter');
          localStorage.removeItem('bucketFilter');
          localStorage.removeItem('bucketView');
          navigate('/');
      //  setIsLoading(false);
      } catch (error) {
        console.error(error);
        toastify("error", error.response.data.message );
      }
  }

  const updatePassword = async () => {
    
    const userData = JSON.parse(localStorage.getItem('userData'));
    const token = localStorage.getItem('token');
     try {
       const response = await axios.patch('https://api.zimzee.com/api/users/password-update',{
        currentPassword: currentPass,
        password:newPassword
    },
       {
         headers: {
             Authorization: `Bearer ${token}`,
           }
       }
       );
       console.log(response);
       toastify("success", response.data.message);
      //  setIsLoading(false);
     } catch (error) {
       console.error(error);
       toastify("error", error.response.data.message );
     }
   };
  const SaveProfileData=async()=>{
    const token = localStorage.getItem('token');
    const formData = new FormData();

    formData.append('firstName',firstName)
    formData.append('lastName',lastName)
    if(chooseProfile != null){
      formData.append('profileImage',chooseProfile) 
    }
    // formData.append('userId', userData._id);
    // formData.append('bucketId', selectedBucket);
    // formData.append('cardType', "bookmark");
    // formData.append('content',data);
    try {
      
      const response = await axios.patch(`${process.env.REACT_APP_API}users/profile-update`,formData, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request header
        },
      });
      toastify("success", response.data.message);
      // if(response){
      //   setDataRefresh(true)
      // }
  
    } catch (error) {
      console.error('Error fetching data:', error);
      toastify("error", error.response.data.message );
    }
  }
  const handleImageSelect=(e)=>{
    const file = e.target.files[0];
    setChooseProfile(file)
  if (file) {
    // Use FileReader to convert the selected file to a data URL
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedProfile(reader.result);
    };
    reader.readAsDataURL(file);
  }
  }
  const handleEmailPopup = () => {
    setEmailPopup(true);
  }
  const handlePasswordPopup = () => {
    setPasswordPopup(true);
  }
  function closePasswordPopup() {
    setPasswordPopup(false);
  }
  function closeEmailPopup(){
    setEmailPopup(false);
  }
const handleFirstName=(e)=>{
setFirstName(e.target.value)
}
const handleLastName=(e)=>{
setLastName(e.target.value)
}
  const handleInvitePopup = () => {
    setInvitePopup(true);
  }

  const closeInvitePopup = () => {
    setInvitePopup(false);
  }

  return(
    <div className="personaldetails-section">
      <div className="profiledetails-content">Profile</div>
      <div className="flex uploadwhole-section">
        <ToastContainer/>
      {selectedProfile ? (
        <div>
          <img className="profiledetailimg-content" src={selectedProfile} alt="profileImg" />
        </div>
      ) : (
        <div>
          {/* Display a default image or a placeholder */}
          <Avatar name={`${firstName} ${lastName}`} size="130"round={true} />
          {/* <img className="profiledetailimg-content" src={ProfileImg} alt="profileImg" /> */}
        </div>
      )}
        <div className="profilewhole-section">
          <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
          <label for="file-input" style={{cursor:"pointer"}}>
          <span className="emailbuckets-context">Upload picture</span>
          </label>
            <input type="file" id="file-input" style={{display:"none"}} name="Upload picture"  onChange={handleImageSelect}/>
            {/* <input type="file" id="file-input" style={{display:"none"}} onChange={handleImageSelect} /> */}
          </button> 
        </div>
      </div>
      <div className="flex userdetailswhole-section">
        <div className="userdetails-section">
          <input className="userdetails-content" type="text" name="firstName" value={firstName} placeholder="First Name" onChange={handleFirstName} required />
        </div>
        <div className="userdetails-section">
          <input className="userdetails-content" type="text" name="lastName" value={lastName} onChange={handleLastName} placeholder="Last Name" required />
        </div>
      </div>
      <hr/>
      <div className="account-content">Account Security</div>
      <div className="flex">
        <div className="accountdetails-section">
          <input className="accountdetails-content" type="text" name="email" placeholder="Email Address" value={email} readOnly/>
        </div>
        <div className="changedetailsdesktop-content" onClick={handleEmailPopup}>Change email address</div>
        <div className="changedetailsmbl-content">Change</div>
      </div>
      <div className="password-content">Password</div>
      <div className="passwordlogin-content">Set a permanent password to login to your account.</div>
      <div className="flex secondsectionpersonal-content">
        <div className="accountdetails-section"> 
          <input className="accountdetails-content" type="text" name="name" placeholder="***********" readOnly />
        </div>
        <div className="changedetailsdesktop-content" onClick={handlePasswordPopup}>Change password</div>
        <div className="changedetailsmbl-content" onClick={handlePasswordPopup}>Change</div>
      </div>
      {passwordPopup &&
       <div className="bookmarksfilesuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closePasswordPopup}>
          <div className="bookmarkssubfilesupload-section" onClick={(e)=>e.stopPropagation()}>
            <div className="fileupload-section flex">
              <div className="fileupload-content">Change password</div>
              <div className="dismissSubfiles-content" onClick={closePasswordPopup}><img src={DismissButton} alt="dismissButton" /></div>
            </div>
            <hr className="paswordline-content"/>
            <div className="letter-content">Use a password at least 8 letters long with both letters and numbers</div>
            <div className="password-section">
               <input className="userdetails-content" type="text" name="name" placeholder="Current Password" onChange={(e)=>{setCurrentPass(e.target.value)}} />
            </div>
            <div className="password-section second-section">
               <input className="userdetails-content" type="text" name="name" placeholder="New Password" onChange={(e)=>{setNewPassword(e.target.value)}} />
            </div>
            <div className="password-section second-section">
               <input className="userdetails-content" type="text" name="name" placeholder="Confirm Password" onChange={(e)=>{setConfirmPass(e.target.value)}} />
            </div>
            {errorMsg?<div className="password-section second-section">
               {errorMsg}
            </div>:null}
            
            <div className="flex justify-end">
              <div className="flex remainderlast-section">
                <div className="cancel-content" style={{cursor:"pointer"}} onClick={closeInvitePopup}>Cancel</div>
                {disableButton ?<button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))',background:"#ff66009c"}} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" disabled>
                    <span className="emailbuckets-context">Change password</span>
                </button>  :<button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" onClick={updatePassword}>
                    <span className="emailbuckets-context">Change password</span>
                </button> }
                
              </div> 
            </div>
          </div>
        </div>
      }
      {
        emailPopup && 
        <div className="bookmarksfilesuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeEmailPopup}>
          <div className="bookmarkssubfilesupload-section" onClick={(e)=>e.stopPropagation()}>
            <div className="fileupload-section flex">
              <div className="fileupload-content">Change Email</div>
              <div className="dismissSubfiles-content" onClick={closeEmailPopup}><img src={DismissButton} alt="dismissButton" /></div>
            </div>
            <hr className="paswordline-content"/>
            <div className="letter-content"></div>
            <div className="password-section">
               <input className="userdetails-content" type="email" name="changeEmail" placeholder="New Email" onChange={(e)=>{setNewPassword(e.target.value)}} />
            </div>
            <div className="password-section second-section">
               <input className="userdetails-content" type="text" name="name" placeholder="Current Password" onChange={(e)=>{setCurrentPass(e.target.value)}} />
            </div>
            {errorMsg?<div className="password-section second-section">
               {errorMsg}
            </div>:null}
            
            <div className="flex justify-end">
              <div className="flex remainderlast-section">
                <div className="cancel-content" style={{cursor:"pointer"}} onClick={closeEmailPopup}>Cancel</div>
                <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" onClick={updateEmail}>
                    <span className="emailbuckets-context">Change email</span>
                </button> 
                
              </div> 
            </div>
          </div>
        </div>
      }
      <hr/>
      <div className="close-content" onClick={handleInvitePopup}>Close your Zimzee account</div>
      <div className="passwordlogin-content">Closing your account will permanently remove your data and content.</div>
      {invitepopup &&
        <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }}>
          <div className="subinviteupload-section">
            <div className="teaminvitesection flex">
              <div className="teammembers-content">Delete my account</div>
              <div className="dismissSubfiles-content" onClick={closeInvitePopup}><img src={DismissButton} alt="dismissButton" /></div>
            </div>
            <hr/>
            <div className="actiontext-content">This action cannot be undone. Closing your account will permanently remove your data and content. Are you sure you want to delete your account?</div>
            <div className="flex justify-end lastfile-section">
              <div className="cancelteam-content" onClick={closeInvitePopup}>Cancel</div>
              <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white justify-end">
                <span className="saveteambutton-context" onClick={deleteUserAccount}>Yes</span>
              </button>
            </div>
          </div>
        </div> 
      }
      <div className="lastsection-content">
        <div className="flex remainderlast-section">
          <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
            <span className="emailbuckets-context" onClick={SaveProfileData}>Save Changes</span>
          </button> 
          <div className="cancel-content">Cancel</div>
        </div> 
      </div> 
    </div>
  );
}
export default PersonalDetails; 