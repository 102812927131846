import { React, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import "../../../PricingPlans/Plans/PlanCheckout.scss";
import "./../../Plan Details/AddPayment/AddPayment.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import magnifier from "../../../../assets/icons/magnifyGlass.svg";
import Visacards from "../../../../assets/icons/Visa Cards.svg";
import Visacardsdark from "../../../../assets/icons/Visa card-dark mode.svg";
import Bankcardsdark from "../../../../assets/icons/bank-dark mode (1).svg";
import Bank from "../../../../assets/icons/bank-grey.svg";
import Cardtab from "../../../../assets/icons/card-grey.svg";
import Bankorange from "../../../../assets/icons/bank-orange.svg";
import Cardorange from "../../../../assets/icons/card-orange.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import TagsInput from "react-tagsinput";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import SetupForm from './SetupForm';

const stripePromise = loadStripe('pk_test_51NirjwECd7IPo9wQqnEOpaKs1NayfLxeQXJ0cG4Gd8sw8eo9LrbQoVZid8XhRIqHabPo12d2OyXUWgRe8BkQTH3Q00gwcBBE64');

function AddPayment() {
  const [clientSecret, setClientSecret] = useState("");
  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchAttachPayment = async () => {
        try{
          const response = await axios.get(
            `${process.env.REACT_APP_API}subscriptions/attach-paymentMethod`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the request header
              },
            }
          ); // Replace this with the actual API endpoint
          console.log(response);
          if(response.data.data.client_secret){
            setClientSecret(response.data.data.client_secret);
          }
        } catch (error) {
          console.error(error);
          // toastify("error", error.response.data.message );
        }
    }
    if (token) {
      fetchAttachPayment();
    }
  }, []);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
    // appearance:appearance,
    // layout: {
    //   type: 'tabs',
    //   defaultCollapsed: false,
    // },
    // wallets: {
    //   applePay:'auto'
    // }
  };

  return (
    <>
    {!clientSecret ? (
      <div className="App-loading">
        <div className="App-logo" title="Zimzee" />
      </div>
    ) : (
    <div className="pricing-checkout add-payment">
      <div className="pagination-checkout">
        <p>
          <span><a href="/app/myProfile">Plan details</a></span> / <a href="/app/profileAddpayment">Payment method</a>
        </p>
      </div>
      <h4>Payment</h4>
      {
       clientSecret ?
       <Elements stripe={stripePromise} options={options}>
          <SetupForm clientSecret={clientSecret}/>
      </Elements>
      : ""}
    </div>
    )}
    </>
  );
}
export default AddPayment;
