import { React, Fragment, useState, useRef, useEffect } from "react";
import "./Files.scss";
import "../Tags/Tags.scss";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNoteSticky,
  faPencil,
  faTrash,
  faFolder,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import * as fontIcons from "@fortawesome/free-solid-svg-icons";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { Menu, Dialog, Transition } from "@headlessui/react";
import ThreeDots from "../../assets/icons/threeDots.svg";
import Closeicon from "../../assets/icons/close-icon.svg";
import Filesicon from "../../assets/icons/File icon.svg";
import DeleteIcon from "../../assets/icons/DarkGrey-Delete.svg";
import LightDelete from "../../assets/icons/Grey_Delete.svg";
import ListView from "../../assets/icons/listView.svg";
import FilesDetailIcon from "../../assets/icons/filesdetailicon.svg";
import { toastify } from "../../components/Toast";
import parse from "html-react-parser";
import { ToastContainer, toast } from "react-toastify";
import compactCardIcon from "../../assets/icons/gridcard.svg";
import Document from "../../assets/images/document.svg";
import ImageFolder from "../../assets/images/imageFolder.svg";
import PDF from "../../assets/images/pdf.svg";
import ClipBoard from "../../assets/icons/subClipboard.svg";
import SubStar from "../../assets/icons/subStar.svg";
import SubTag from "../../assets/icons/subTag.svg";
import SubShare from "../../assets/icons/subShare.svg";
import Profile1 from "../../assets/icons/profile1.png";
import Profile2 from "../../assets/icons/profile2.png";
import ProjectImg2 from "../../assets/images/Projects/projectImg2.png";
import Profile3 from "../../assets/icons/profile3.png";
import CardImage1 from "../../assets/images/cardImage1.png";
import CardImage2 from "../../assets/images/cardImage2.png";
import CardImage3 from "../../assets/images/cardImage3.svg";
import linkIcon from "../../assets/icons/linksicon.svg";
import UploadIcon from "../../assets/icons/uploadIcon.svg";
import ListviewIcon from "../../assets/icons/listBlackIcon.svg";
import GridviewIcon from "../../assets/icons/gridgreyIcon.svg";
import DismissButton from "../../assets/icons/dismissButton.svg";
import SortMbl from "../../assets/icons/sortmbl.svg";
import Union from "../../assets/icons/Union-19.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import clipboardNotes from "../../assets/icons/8725602_clipboard_notes_icon.svg";
import Quill from "quill";
// import axios from "axios";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Select from "react-select";
import BlackDismiss from "../../assets/icons/dismissButton.svg";
import SingleFile from "../../shades/cards/SingleFile";
import ShareCard from "../../shades/cards/shareCard";
import magnifier from "../../assets/icons/magnifyGlass.svg";
import Dropdown from "../../assets/icons/Dropdown.svg";
import { useNavigate } from "react-router-dom";
// import DropdownExtension from '../../assets/icons/dropdownextension.svg'
// import PlusExtension from '../../assets/icons/Extension.svg'

function Files() {
  const [isListView, setIsListView] = useState(true);
  const [isGridView, setIsGridView] = useState(true);
  const [isListViewIcon, setIsListViewIcon] = useState(false);
  const [isGridViewIcon, setIsGridViewIcon] = useState(false);
  const [isListViewOpen, setIsListViewOpen] = useState(false);
  const [isGridViewOpen, setIsGridViewOpen] = useState(true);
  const [filepopup, setFilePopup] = useState(false);
  const [note, setNotes] = useState("");
  const [textInput, setTextInput] = useState("");
  const [files, setFiles] = useState([]);
  const [filesData, setFilesData] = useState([]);
  const [FileSharePopup, setFileSharePopup] = useState(false);
  const [ShareFileId, setShareFileId] = useState("");
  const [isActive, setIsActive] = useState("");
  const fileInputRef = useRef(null);
  const [buckets, setBuckets] = useState([]);
  const [isEditorFocused, setIsEditorFocused] = useState(false);
  const [ClickedCardId, setClickedCardId] = useState("");
  let [isOpens, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedBucket, setSelectedBucket] = useState("");
  const [filterSelectedBucket, setFilterSelectedBucket] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fileUpdate, setFileUpdate] = useState(false);
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [isSubTagOpen, setIsSubTagOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [tagOptions, setTagOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBucket, setSearchBucket] = useState("");
  const [filterBucket, setFilterBucket] = useState("");
  const [hoveredOption, setHoveredOption] = useState(null);
  const [hoveredSubDropdown, setHoveredSubDropdown] = useState(null);
  const [inputValueSet, setInputValueSet] = useState("");
  const [isBucketOpen, setIsBucketOpen] = useState(false);
  const [isMenuBucket, setMenuBucket] = useState(false);
  const [filterTag, setFiltertag] = useState("");
  const [filterTagId, setFilterTagId] = useState("");
  const [IsFilterTagOpen, setIsFilterTagOpen] = useState(false);
  const [FileRefresh, setFileRefresh] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [appTheme, setAppTheme] = useState("");
  const navigate = useNavigate();
  let cardFilterv = "";
  if (!localStorage.getItem("cardFilter")) {
    localStorage.setItem("cardFilter", "Created_Date");
    cardFilterv = "Created_Date";
  } else {
    cardFilterv = localStorage.getItem("cardFilter");
  }
  const [cardFilterDefault, setCardFilterDefault] = useState(cardFilterv);
  useEffect(() => {
    const handleStorageChange = () => {
      console.log("app theme");
      const theme = localStorage.getItem("theme");
      setAppTheme(theme);
    };

    window.addEventListener("storage", handleStorageChange);

    // Force a re-render when localStorage changes
    setAppTheme(localStorage.getItem("theme"));

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  useEffect(() => {
    console.log(IsFilterTagOpen, "filter tags opne");
    console.log(files, "selected files");
  }, [tags, IsFilterTagOpen, files]);
  useEffect(() => {
    // Function to fetch data from the API and update the state
    const token = localStorage.getItem("token");
    const fetchBuckets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}buckets/user-buckets`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, []);
  const fetchTags = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API}tags/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request header
          },
        }
      );
      console.log(response?.data?.data?.name, "tags list");
      setTagOptions(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);
  const updateFiles = () => {
    setFileUpdate(!fileUpdate);
  };
  const [searchValue, setSearchValue] = useState("");
  const [subtags, setSubTags] = useState([]);
  const [tagActiveId, setTagActiveId] = useState(0);
  const [tagActiveName, setTagActiveName] = useState("");
  useEffect(() => {
    // api url

    const token = localStorage.getItem("token");

    axios
      .post(
        `
      https://api.zimzee.com/api/cards/cards-with-files`,
        {
          filterValue: cardFilterDefault,
          limit: 100,
          bucketId: filterSelectedBucket ? filterSelectedBucket : "",
          tagId: filterTagId ? filterTagId : "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setIsLoading(false);
        setFilesData(response?.data?.data);
        setNotes(response?.data?.data?.note);
        setTag(response?.data?.data?.tags);
        // setFileUrl(response?.data?.data?.content?.imageUrl[0]?.url)
        console.log("fileurl", response?.data?.data?.content?.imageUrl[0]?.url);
        console.log("filessssssssssss", response?.data?.data);
        setFileRefresh(false);
        // setCardList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [
    cardFilterDefault,
    fileUpdate,
    filterSelectedBucket,
    filterTagId,
    FileRefresh,
  ]);
  useEffect(() => {
    setTimeout(() => {
      console.log(filesData, "filessssss title");
    }, 5000);
  }, [filesData]);

  const handleRemoveFile = (indexToRemove) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(indexToRemove, 1);
      return updatedFiles;
    });
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files);
    const currentFiles = files.length; // Current number of files in state
    const totalSize = newFiles.reduce((acc, file) => acc + file.size, 0);

    // Validate the total number of files
    if (currentFiles + newFiles.length > 3) {
      toastify("error", "Please select up to 3 files.");
      return;
    }

    // Validate the size of each file
    for (const file of newFiles) {
      if (newFiles.length == 1 && file.size > 10 * 1024 * 1024) {
        // 10 MB limit
        toastify("error", "File size should be less than 10 MB.");
        return;
      }
    }

    // Validate total size for 2-3 files
    if (newFiles.length > 1 && totalSize > 30 * 1024 * 1024) {
      // 30 MB limit
      toastify("error", "Selected files should be less than 30 MB.");
      return;
    }

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, value]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== value)
      );
    }
  };
  const updateCard = async (cardId, star) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://api.zimzee.com/api/cards/update",
        { cardId: cardId, starred: star },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      setFileRefresh(true);
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
  };
  const handleTagsChange = (newTags) => {
    setTags(newTags);
    const inputElement = document.querySelector(".react-tagsinput-input");

    // Check if the input element exists before calling focus
    if (inputElement) {
      inputElement.focus();
    }
  };
  const handleStarClick = async (cardId, star) => {
    await updateCard(cardId, !star);
  };

  const modules = {
    clipboard: {
      matchVisual: false,
    },
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        {
          color: [
            "#FF0000", // Red
            "#00FF00", // Green
            "#0000FF", // Blue
            "#FFFF00", // Yellow
            "#00FFFF", // Cyan
            "#FF00FF", // Magenta
            "#000000", // Black
            "#FFFFFF", // White
            "#808080", // Gray

            // Web-safe colors
            "#800000", // Maroon
            "#808000", // Olive
            "#000080", // Navy
            "#800080", // Purple
            "#008080", // Teal
            "#C0C0C0", // Silver
            // Shades of gray
            "#A9A9A9", // Dark gray
            "#696969", // Dim gray
            "#D3D3D3", // Light gray
            "#DCDCDC", // Gainsboro

            // Pastel colors
            "#FFB6C1", // Light pink
            "#90EE90", // Light green
            "#ADD8E6", // Light blue
            "#E6E6FA", // Lavender

            // CSS named colors
            "#FF6347", // Tomato
            "#F4A460", // Sandy Brown
            "#6495ED", // Cornflower Blue
            "#9932CC", // Dark Orchid
          ],
        },
      ],
      [
        {
          background: [
            "#FF0000", // Red
            "#00FF00", // Green
            "#0000FF", // Blue
            "#FFFF00", // Yellow
            "#00FFFF", // Cyan
            "#FF00FF", // Magenta
            "#000000", // Black
            "#FFFFFF", // White
            "#808080", // Gray

            // Web-safe colors
            "#800000", // Maroon
            "#808000", // Olive
            "#000080", // Navy
            "#800080", // Purple
            "#008080", // Teal
            "#C0C0C0", // Silver
            // Shades of gray
            "#A9A9A9", // Dark gray
            "#696969", // Dim gray
            "#D3D3D3", // Light gray
            "#DCDCDC", // Gainsboro

            // Pastel colors
            "#FFB6C1", // Light pink
            "#90EE90", // Light green
            "#ADD8E6", // Light blue
            "#E6E6FA", // Lavender

            // CSS named colors
            "#FF6347", // Tomato
            "#F4A460", // Sandy Brown
            "#6495ED", // Cornflower Blue
            "#9932CC", // Dark Orchid
          ],
        },
      ],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleSharePop = (id) => {
    console.log(id, "share card id");
    setShareFileId(id);
    setFileSharePopup(true);
  };
  const handleCardClick = (cardId) => {
    setClickedCardId(cardId);
  };
  const handleEditorFocus = () => {
    setIsEditorFocused(true);
    const elements = document.getElementsByClassName("ql-toolbar");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("my-class-selected");
    }
  };
  const handleClick = () => {
    setIsEditorFocused(true);
    const elements = document.getElementsByClassName("ql-toolbar");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("my-class-selected");
    }
  };
  const handleEditorClick = () => {
    setIsEditorFocused(true);
    const elements = document.getElementsByClassName("ql-toolbar");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("my-class-selected");
    }
  };
  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedHTML = clipboardData.getData("text/html");
    const quill = this.reactQuillRef.getEditor();
    const selection = quill.getSelection();
    const index = selection ? selection.index : 0;
    const delta = quill.clipboard.convert(pastedHTML);
    quill.updateContents(delta, Quill.sources.USER);
    quill.setSelection(index + delta.length(), Quill.sources.SILENT);
  };
  const handleEditorBlur = () => {
    setIsEditorFocused(false);
    const elements = document.getElementsByClassName("ql-toolbar");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("my-class-selected");
    }
  };
  const handleKeyUp = () => {
    setIsEditorFocused(true);
    const elements = document.getElementsByClassName("ql-toolbar");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("my-class-selected");
    }
  };
  const handleFocus = (e) => {
    e.stopPropagation();
    setIsTagOpen(true);
    setIsBucketOpen(false);
  };
  const handleTagFocus = (e) => {
    e.stopPropagation();
    setIsFilterTagOpen(true);
    setMenuBucket(false);
  };

  const handleBlur = () => {
    setIsTagOpen(false);
  };

  const handleMouseEnter = (option) => {
    console.log(option, "hovered option.......");
    setHoveredOption(option);
  };

  const handleMouseLeave = () => {
    setHoveredOption(null);
  };
  const handleFilterBucketInputChange = (event) => {
    setFilterBucket(event.target.value);
  };
  const handleFilterTagInputChange = (e) => {
    setFiltertag(e.target.value);
  };
  const handleBucketInputChange = (event) => {
    setSearchBucket(event.target.value);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const options = tagOptions.map((item) => ({
    value: item._id,
    label: item.name,
  }));
  const filteredOptions = tagOptions.filter((option) =>
    option.name.toLowerCase().includes(inputValueSet.toLowerCase())
  );

  const handleSetInputValue = (e) => {
    setInputValueSet(e);
    setIsTagOpen(true);
  };

  const filteredBuckets = buckets?.filter((bucket) => {
    const bucketNameMatches = bucket.name.toLowerCase().includes(searchBucket);
    const subBucketMatches = bucket.subBuckets?.some((subBucket) =>
      subBucket.name.toLowerCase().includes(searchBucket)
    );
    return bucketNameMatches || subBucketMatches;
  });
  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      const newTag = selectedOption.name;
      setTags([...tags, newTag]);
      setSelectedOption(null);
      setInputValue("");
      handleTagsChange([...tags, newTag]);
      handleBlur();
    }
  };
  const handleFilterSelectChange = (e, selectedOption) => {
    console.log(selectedOption, "selected taggggggggggg");
    e.stopPropagation();
    if (selectedOption) {
      const newTag = selectedOption.name;
      setFiltertag(newTag);
      setFilterTagId(selectedOption._id);
      setFilterBucket("");
      // setSelectedOption(null);
      // setInputValue('');
      // handleTagsChange([...tags, newTag]);
      // handleBlur();
    }
    setIsFilterTagOpen(false);
  };

  const handleSubtagSelectChange = (subtag) => {
    const newTag = subtag.name;
    setTags([...tags, newTag]);
    setHoveredOption(hoveredOption);
    handleTagsChange([...tags, newTag]);
    handleBlur();
  };
  const handleSubBucketSelectChange = (subbucket) => {
    const newBucket = subbucket._id;
    setSelectedBucket(newBucket);
    setSearchBucket(subbucket.name);
    setIsBucketOpen(false);
  };
  const handleFilterSubBucketSelectChange = (subbucket) => {
    const newBucket = subbucket._id;
    setFilterSelectedBucket(newBucket);
    setFilterBucket(subbucket.name);
    setMenuBucket(false);
  };

  const deleteCard = async () => {
    setIsOpen(true);
  };

  const handleDeleteCard = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        "https://api.zimzee.com/api/cards/delete",
        {
          data: { deletedIds: selectedItems },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      setSelectedItems([]);
      CloseDeleteModal();
      updateFiles();
    } catch (error) {
      console.error(error);
      CloseDeleteModal();
      setIsLoading(false);
      toastify("error", error.response.data.message);
    }
  };
  const handleSubmit = async () => {
    const tagsPayload = JSON.stringify(tags.map((tag) => ({ name: tag })));
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    setIsLoading(true);
    const formData = new FormData();
    formData.append("tags", tagsPayload);
    formData.append("note", note ? note : "");
    formData.append("userId", userData._id);
    formData.append("bucketId", selectedBucket);
    files.forEach((file) => {
      formData.append("myFiles", file);
    });

    const response = await axios
      .post("https://api.zimzee.com/api/cards/upload-files", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (response.data.statusCode == 201) {
          toastify("success", response.data.message);
          setFiles("");
          setSelectedBucket("");
          setNotes("");
          updateFiles();
          setIsLoading(false);
          closeFilePopup();
          console.log("Data sent successfully!");
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        toastify("error", error.response.data.message);
      });
  };
  const convertBytesToMB = (bytes) => {
    if (typeof bytes !== "number" || isNaN(bytes)) {
      return "Invalid file size";
    }

    const megabytes = bytes / (1024 * 1024);
    return megabytes.toLocaleString(undefined, { maximumFractionDigits: 2 });
  };
  const handleFilterBucketChange = (bucket) => {
    const Selectbucket = bucket._id;
    setFilterSelectedBucket(Selectbucket);
    const newBucket = bucket.name;
    setFilterBucket(newBucket);
    setMenuBucket(false);
    setFiltertag("");
    setFilterTagId("");
  };
  const handleBucketChange = (bucket) => {
    const Selectbucket = bucket._id;
    setSelectedBucket(Selectbucket);
    const newBucket = bucket.name;
    setSearchBucket(newBucket);
    setIsBucketOpen(false);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleProcedureContentChange = (content, delta, source, editor) => {
    setNotes(content);
  };
  const handleFilterBucketOpen = (e) => {
    e.stopPropagation();
    setMenuBucket(!isMenuBucket);
    setIsFilterTagOpen(false);
  };
  const handleBucketOpen = (e) => {
    e.stopPropagation();
    setIsBucketOpen(!isBucketOpen);
    setIsTagOpen(false);
  };
  const cardsRefresh = () => {
    setFileRefresh(!FileRefresh);
  };
  const handleFileChange = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.target.files);
    const currentFiles = files.length; // Current number of files in state
    const totalSize = newFiles.reduce((acc, file) => acc + file.size, 0);

    // Validate the total number of files
    if (currentFiles + newFiles.length > 3) {
      toastify("error", "Please select up to 3 files.");
      return;
    }

    // Validate the size of each file
    for (const file of newFiles) {
      if (newFiles.length == 1 && file.size > 10 * 1024 * 1024) {
        // 10 MB limit
        toastify("error", "File size should be less than 10 MB.");
        return;
      }
    }

    // Validate total size for 2-3 files
    if (newFiles.length > 1 && totalSize > 30 * 1024 * 1024) {
      // 30 MB limit
      toastify("error", "Selected files should be less than 30 MB.");
      return;
    }

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFilePopup = () => {
    setFilePopup(true);
  };
  const handleClickBrowse = () => {
    fileInputRef.current.value = null;
    fileInputRef.current.click();
  };

  function closeFilePopup() {
    setFilePopup(false);
    setTags([]);
    handleBlur();
    setSearchBucket("");
    setFiles([]);
    setNotes("");
    setIsBucketOpen(false);
    setInputValueSet("");
  }

  let [isOpenss, setIsOpens] = useState(false);

  const handleIsListView = () => {
    setIsListView(false);
    setIsGridView(false);
    setIsListViewOpen(true);
    setIsGridViewOpen(false);
    setIsListViewIcon(true);
    setIsGridViewIcon(true);
  };

  const handleIsGridView = () => {
    setIsListView(true);
    setIsGridView(true);
    setIsGridViewOpen(true);
    setIsListViewOpen(false);
    setIsListViewIcon(false);
  };

  const handleIsListViewIcon = () => {
    setIsListView(true);
    setIsGridView(false);
    setIsListViewOpen(false);
    setIsGridViewOpen(true);
    setIsListViewIcon(false);
    // setIsGridViewIcon(true)
  };

  const handleIsGridViewIcon = () => {
    setIsListView(true);
    setIsGridView(true);
    setIsListViewOpen(false);
    setIsGridViewOpen(true);
    setIsListViewIcon(false);
    setIsGridViewIcon(false);
  };

  function closeCardModal() {
    // setSelectedItems([]);
    setIsOpens(false);
  }

  const CloseDeleteModal = () => {
    setIsOpen(false);
  };
  const handleClearBucket = (e) => {
    e.stopPropagation();
    console.log("clear bcuket filter");
    setFilterSelectedBucket("");
    setFilterBucket("");
  };
  const handleClearTag = (e) => {
    e.stopPropagation();
    console.log("clear tags filter");
    setFiltertag("");
    setFilterTagId("");
  };
  const bucketNav = [
    // { icon: faPencil, name: 'Edit Name', href: '#' },
    // { icon: faNoteSticky, name: 'Create New Card', href: '#' },
    // { icon: faTrash, name: 'Delete', href: '#' },
    // { icon: faFolder, name: 'Customize New Card', href: '#' },
    { icon: faFolder, name: "Download", href: "#" },
  ];

  const fileNav = [
    { icon: faNoteSticky, name: "Select All", href: "javascript:void(0)" },
    { icon: faTrash, name: "Unselect All", href: "javascript:void(0)" },
  ];

  const filterFilesDropDown = [
    { name: "Created Date", href: "#" },
    { name: "Recent Activity", href: "#" },
    { name: "Ascending Order", href: "#" },
    { name: "Descending Order", href: "#" },
  ];
  const handleAction = (item, url, type) => {
    console.log(url, "selected file url");
    if (item.name === "Download") {
      handleDownloadFile(url, type);
    }
  };
  const handleDownloadFile = (Url, type) => {
    console.log(type, "File Type");
    // return
    const token = localStorage.getItem("token");
    if (Url) {
      setIsLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API}cards/download-file`,
          { fileUrl: Url },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // You should read the response data as an array buffer
          const base64Data = response?.data?.data?.fileBase64;
          if (base64Data) {
            setIsLoading(false);
          }
          const byteCharacters = atob(base64Data);

          // Convert the data to an array of bytes
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Create a Blob from the byte array
          const blob = new Blob([byteArray], { type });

          // Create an Object URL for the Blob
          const url = URL.createObjectURL(blob);

          // Create an anchor element to trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = `image.${type}`; // Set the desired filename and file extension
          a.style.display = "none";
          document.body.appendChild(a);

          // Simulate a click on the anchor to trigger the download
          a.click();

          // Clean up by revoking the Object URL
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    }
  };
  const handleFileDownload = (url) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.target = "_blank";
    downloadLink.click();
  };
  const closeSharePop = () => {
    console.log("close share pop");
    setFileSharePopup(false);
  };
  const [filtersText, setFiltersText] = useState(
    cardFilterv == "Created_Date"
      ? filterFilesDropDown[0].name
      : cardFilterv == "Descending_Order"
      ? filterFilesDropDown[3].name
      : cardFilterv == "Ascending_Order"
      ? filterFilesDropDown[2].name
      : cardFilterv == "Recent_Activity"
      ? filterFilesDropDown[1].name
      : ""
  );
  const handleFilterFilesText = (text) => {
    setFiltersText(text);
    console.log(text, "handlefiltertext");
    setIsLoading(true);

    const filterValues =
      text == "Created Date"
        ? "Created_Date"
        : text == "Descending Order"
        ? "Descending_Order"
        : text == "Ascending Order"
        ? "Ascending_Order"
        : text == "Recent Activity"
        ? "Recent_Activity"
        : "";
    localStorage.setItem("cardFilter", filterValues);
    setCardFilterDefault(filterValues);
    const token = localStorage.getItem("token");

    axios
      .post(
        `${process.env.REACT_APP_API}cards/cards-with-files`,
        { filterValue: filterValues, limit: 10000 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setIsLoading(false);
        setFilesData(response?.data?.data);
        // setCardList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const filterDropDown = [
    { name: "Fashion trends 2022 sketch", href: "#" },
    { name: "Fashion bucket", href: "#" },
    { name: "Cooking receipes", href: "#" },
    { name: "Inspirations", href: "#" },
  ];

  const [FiltersText, setbucketFiltersText] = useState("Buckets");

  const filterBucketsDropDown = [
    { name: "Fashion trends 2022 sketch", href: "#" },
    { name: "Fashion bucket", href: "#" },
    { name: "Cooking receipes", href: "#" },
    { name: "Inspirations", href: "#" },
  ];

  const [settagsFiltersText] = useState(filterBucketsDropDown[0].name);

  const handleFilterText = (text) => {
    console.log(text, "select from buckets filter");
    setbucketFiltersText(text);
    setDropdownOpen(false);
  };

  const handleSelectClick = (text) => {
    if (text == "Select All") {
      var checkboxes = document.querySelectorAll(
        '.cardHolder-cards input[type="checkbox"]'
      );
      var checkedValues = []; // Array to store the values of checked checkboxes

      checkboxes.forEach(function (checkbox) {
        if (!checkbox.checked) {
          var checked = true; // Replace with your desired checked state
          checkbox.checked = checked;
          if (checkbox.checked) {
            checkedValues.push(checkbox.value);
            setSelectedItems((prevSelectedItems) => [
              ...prevSelectedItems,
              checkbox.value,
            ]);
          }
        } else {
          var checked = false; // Replace with your desired checked state
          checkbox.checked = checked;
          setSelectedItems([]);
        }
      });
    } else if (text == "Unselect All") {
      var checkboxes = document.querySelectorAll(
        '.cardHolder-cards input[type="checkbox"]'
      );
      var checkedValues = []; // Array to store the values of checked checkboxes

      checkboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
          var checked = false; // Replace with your desired checked state
          checkbox.checked = checked;
          setSelectedItems([]);
        }
      });
    }
  };

  const handleFilterTagText = (text) => {
    settagsFiltersText(text);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // const handleFilterFilesText = (text) => {
  //   setFiltersSelectText(text);
  // }

  const handleSearchChange = async (event) => {
    setSearchValue(event.target.value);
    const token = localStorage.getItem("token");

    await axios
      .get(`${process.env.REACT_APP_API}tags/list/${event.target.value}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        // setIsLoading(false);
        if (response?.data?.data && response?.data?.data.length) {
          setSubTags(response?.data?.data);
          setTagActiveId(response?.data?.data[0]._id);
        } else {
          setSubTags([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      {isLoading ? (
        <div className="App-loading">
          <div className="App-logo" title="Zimzee" />
        </div>
      ) : (
        <div
          className="files-wholesection"
          onClick={() => {
            setMenuBucket(false);
            setIsFilterTagOpen(false);
          }}
        >
          <ToastContainer />
          <div className="bucketHeader">
            <div className="grid grid-cols">
              <div className="bucketFeature col-span-2">
                <div className="flex items-center">
                  <h2 className="text-xl mybuckets-content dark:text-[#fff]">
                    Files
                  </h2>
                  <div className="subbucketheaderdesktop-section">
                    <button
                      style={{
                        filter:
                          "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                      }}
                      className="buttonpadding-content mx-8 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]"
                      onClick={handleFilePopup}
                    >
                      <FontAwesomeIcon
                        className="pl-[23px] pr-[17.73px]"
                        icon={fontIcons.faPlus}
                      />
                      <span className="createbutton-context">New</span>
                    </button>
                  </div>
                  <div className="subbucketheadermbl-section">
                    <button
                      style={{
                        filter:
                          "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                      }}
                      className="buttonpadding-content mx-2 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]"
                      onClick={handleFilePopup}
                    >
                      <FontAwesomeIcon
                        className="pl-[23px] pr-[17.73px]"
                        icon={fontIcons.faPlus}
                      />
                      <span className="createbutton-context">New</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          {FileSharePopup && (
            <div
              className="filesuploadpopup relative !z-[999]"
              style={{ height: "100%" }}
              onClick={closeSharePop}
            >
              <div
                className="subinviteupload-section"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="teaminvitesection flex">
                  <div className="teammembers-content">Share</div>
                  <div
                    className="dismissSubfiles-content"
                    onClick={closeSharePop}
                  >
                    <img src={BlackDismiss} alt="dismissButton" />
                  </div>
                </div>
                <ShareCard
                  shareBucketId={ShareFileId}
                  closeSharePop={closeSharePop}
                  cardsRefresh={cardsRefresh}
                />
              </div>
            </div>
          )}
          {filepopup && (
            <div
              className="filesuploadpopup relative !z-[999]"
              style={{ height: "100%" }}
              onClick={closeFilePopup}
            >
              <div
                className="subfilesupload-section"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsBucketOpen(false);
                  setIsTagOpen(false);
                }}
              >
                <div className="fileupload-section flex">
                  <div className="fileupload-content">File Upload</div>
                  <div
                    className="dismissSubfiles-content"
                    onClick={closeFilePopup}
                  >
                    <img src={DismissButton} alt="dismissButton" />
                  </div>
                </div>
                <hr />
                <div className="uploadtext-content mb-[12px]">
                  Upload files for secure storage
                </div>
                <div className="flex items-center rounded-3xl border-0 menusubbucketitems-popupcontent">
                  <div className="w-full">
                    <strong className="createdsubtext-content pr-4">
                      <div
                        className="flex fliesbucket-inputcontent"
                        onClick={handleBucketOpen}
                      >
                        <input
                          type="text"
                          placeholder="Select a bucket"
                          className="filespopupbg-content"
                          value={searchBucket}
                          onChange={handleBucketInputChange}
                        />
                        <div className="dropdownfilespopup-content">
                          <FontAwesomeIcon
                            style={{ fontSize: "12px", color: "#707070" }}
                            icon={faChevronDown}
                          />
                        </div>
                        <div className="tab-scrolls filescard">
                          {isBucketOpen ? (
                            <ul className="dropdown">
                              {filteredBuckets?.map((option) => (
                                <li key={option._id}>
                                  <p onClick={() => handleBucketChange(option)}>
                                    {option.name}
                                    {option.subBuckets &&
                                    option.subBuckets.length > 0 ? (
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "12px",
                                          color: "#707070",
                                        }}
                                      />
                                    ) : null}
                                  </p>
                                  {option.subBuckets &&
                                  option.subBuckets.length > 0 ? (
                                    <ul className="sub-dropdowns ">
                                      {option.subBuckets.map((subTag) => (
                                        <li key={subTag._id}>
                                          <p
                                            onClick={() =>
                                              handleSubBucketSelectChange(
                                                subTag
                                              )
                                            }
                                          >
                                            -- {subTag.name}
                                          </p>
                                        </li>
                                      ))}
                                    </ul>
                                  ) : null}
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </div>
                      </div>
                    </strong>
                  </div>

                  {/* <div><FontAwesomeIcon className="iconimg-content" style={{ fontSize: '12px', color: '#707070' }} icon={faChevronDown} /></div> */}
                </div>
                <div className="pos-tagsdropdown">
                  <div
                    className="flex items-center rounded-3xl border-0 menusubitems-content"
                    onClick={handleFocus}
                  >
                    <div className="w-full">
                      <div className="flex files-tags">
                        <TagsInput
                          value={tags}
                          onChange={handleTagsChange}
                          inputValue={inputValueSet}
                          onChangeInput={(e) => handleSetInputValue(e)}
                        />
                        <FontAwesomeIcon
                          className="fileicon-content"
                          style={{}}
                          icon={faChevronDown}
                          onClick={handleFocus}
                        />
                      </div>

                      {/* <button onClick={handleAddFromDropdown}>Add Tag from Dropdown</button> */}
                    </div>
                  </div>
                  {isTagOpen && filteredOptions.length ? (
                    <div className="pos-dropdown">
                      <nav>
                        <div className="tab-scrolls tagscard">
                          <ul className="dropdown">
                            {filteredOptions.map((option) => (
                              <li
                                key={option._id}
                                onMouseEnter={() => handleMouseEnter(option)}
                                onMouseLeave={handleMouseLeave}
                              >
                                <p onClick={() => handleSelectChange(option)}>
                                  {option.name}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </nav>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="border bg-[#f5f5f5] border-[#e5e5e5] dark:border-[#dedede] border-1 rounded-md overflow-hidden"
                  onClick={handleClick}
                  style={{
                    resize: "horizontal",
                    height: "200px",
                    marginBottom: "24px",
                  }}
                >
                  {(isEditorFocused == false && !note) ||
                  (isEditorFocused == false && note == "<p><br></p>") ? (
                    <div
                      className="addnote-text"
                      style={{ paddingLeft: "14px", marginTop: "10px" }}
                    >
                      <p>Add Notes</p>
                    </div>
                  ) : (
                    ""
                  )}
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    value={note}
                    onChange={handleProcedureContentChange}
                    onFocus={handleEditorFocus}
                    onClick={handleEditorClick}
                    onBlur={handleEditorBlur}
                    onKeyUp={handleKeyUp}
                    onPaste={handlePaste}
                  />
                  {isEditorFocused && (
                    <div
                      className="backgroundcard-rightcontent bg-[#F7F7F7] px-3 py-4 flex items-center"
                      onClick={handleClick}
                    >
                      {note}
                    </div>
                  )}
                </div>

                <div
                  className="browser-content"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div>
                    <span
                      className="browse-content"
                      onClick={handleClickBrowse}
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        ref={fileInputRef}
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        onClick={(e) => (e.target.value = null)}
                        style={{ display: "none" }}
                      />
                      <img
                        className="uploadimg-content"
                        src={UploadIcon}
                        alt="UploadIcon"
                      />
                    </span>
                  </div>
                  <div className="drag-content">
                    Drag & Drop, or{" "}
                    <span
                      className="browse-content"
                      onClick={handleClickBrowse}
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        ref={fileInputRef}
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        onClick={(e) => (e.target.value = null)}
                        style={{ display: "none" }}
                      />
                      Browse
                    </span>{" "}
                    your files
                  </div>

                  {/* {files.length > 0 && (
                    <span className="mx-5 my-1 ">
                      {files.length} files selected
                    </span>
                  )} */}
                </div>
                {files &&
                  files.map((file, index) => {
                    return (
                      <div
                        className="mt-[30px] files-selected flex"
                        key={index}
                      >
                        <img
                          className="mr-[8px]"
                          src={Filesicon}
                          alt="UploadIcon"
                        />
                        {/* <p className="uploadfile-name">{file?.name}</p> */}
                        <div className="tooltip">
                          <span className="tooltip-text fileshrename">
                            <p className="designfile-textcontent">
                              {file?.name}
                            </p>
                          </span>
                          <span className="design-textcontent">
                            {file?.name}
                          </span>
                        </div>
                        <img
                          className="justify-end ml-[auto]"
                          src={Closeicon}
                          alt="UploadIcon"
                          onClick={() => handleRemoveFile(index)}
                        />
                      </div>
                    );
                  })}

                {/* <div className="mt-[15px] files-selected flex">
                  <img className="mr-[8px]" src={Filesicon} alt="UploadIcon" />
                  <p className="uploadfile-name">Fashion trends</p>
                  <img
                    className="justify-end ml-[auto]"
                    src={Closeicon}
                    alt="UploadIcon"
                  />
                </div> */}
                <div className="flex justify-end lastfile-section">
                  <div
                    className="cancel-content"
                    style={{ cursor: "pointer" }}
                    onClick={closeFilePopup}
                  >
                    Cancel
                  </div>
                  <button
                    style={{
                      filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                    }}
                    className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white"
                    onClick={handleSubmit}
                  >
                    <span className="savebutton-context">Upload</span>
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="subbucketheaderdesktop-section mb-[20px]">
            <div className="cards-filter  mt-[18px] flex justify-between">
              <div className="flex items-center">
                <div className="card-count flex items-center">
                  <h4 className="cardssubBuckets-content">Files</h4>
                  <span className="subbucketsbg-content background-primary text-white rounded-full ml-4 text-[16px]">
                    {filesData?.length}
                  </span>
                </div>
                {/* <div className="flex fliesbucket-inputcontent" onClick={handleBucketOpen}>
                <input
                  type="text"
                  placeholder="Select a bucket"
                  className="filespopupbg-content"
                  value={searchBucket}
                  onChange={handleBucketInputChange}  
                />
              <div className="dropdownfilespopup-content"><FontAwesomeIcon style={{ fontSize: '12px', color: '#707070' }} icon={faChevronDown} /></div>
              <div className="tab-scrolls filescard">
               
                      

{isMenuBucket ?<ul className="dropdown">
                        {filteredBuckets?.map((option) => (
                          <li
                            key={option._id}
                        
                          >
                            <span onClick={() => handleBucketChange(option)}>{option.name}{option.subBuckets && option.subBuckets.length > 0 ? <FontAwesomeIcon style={{ fontSize: '12px', color: '#707070' }}/> :null}</span>
                            { option.subBuckets && option.subBuckets.length > 0 ? (
                              <ul className="sub-dropdowns ">
                                {option.subBuckets.map((subTag) => (
                                  <li  key={subTag._id}><span onClick={() => handleSubBucketSelectChange(subTag)}>-- {subTag.name}</span></li>
                                ))}
                              </ul>
                            ) : null}
                          </li> 
                        ))}
                      </ul> :null}

                      

                   
              </div>
            </div> */}
                <Menu as="div" className="relative px-2">
                  <Menu.Button className="relative ml-3 inline-flex items-center px-1 py-2 text-sm font-semibold text-gray-900 ">
                    {/* <FontAwesomeIcon className="text-lg text-[#707070]" icon={faEllipsisVertical} /> */}
                    <img
                      className="threedots-content"
                      src={ThreeDots}
                      alt="threeDots"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {fileNav.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-sm text-gray-700"
                              )}
                              onClick={() => {
                                handleSelectClick(item.name);
                              }}
                            >
                              <span className="pr-2">
                                <FontAwesomeIcon
                                  className="text-[#797979]"
                                  icon={item.icon}
                                />
                              </span>
                              <span className="text-black">{item.name}</span>
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
                {selectedItems.length > 0 ? (
                  <img
                    src={appTheme === "light" ? DeleteIcon : LightDelete}
                    alt="deleteIcon"
                    className="px-3"
                    onClick={() => deleteCard()}
                  />
                ) : (
                  <img
                    src={appTheme === "light" ? LightDelete : DeleteIcon}
                    alt="deleteIcon"
                    className="px-3"
                    // onClick={() => deleteCard()}
                  />
                )}

                <Transition appear show={isOpens} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={CloseDeleteModal}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              Delete
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to delete?
                              </p>
                            </div>

                            <div className="mt-4">
                              <button
                                style={{
                                  background: "#FF6600",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={() => handleDeleteCard()}
                              >
                                Yes
                              </button>
                              <button
                                style={{
                                  background: "#000",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={CloseDeleteModal}
                              >
                                No
                              </button>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
                {/* <img src={DeleteIcon} alt="deleteIcon" className="px-3" /> */}
                {/* <Menu as="div" className="relative flex-shrink-0">
              <div>
                <Menu.Button className="flex rounded-full focus:outline-none bordered">
                  <div className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500 bucketswhole-spacecontent">
                    <strong className="createdbuckettext-content pr-4">{FiltersText}</strong>
                    <FontAwesomeIcon className="dropdownicon-bucketcontent" style={{fontSize:'12px'}} icon={faChevronDown} />
                  </div>
                </Menu.Button>
              </div> 
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">                           
                {buckets?.map((item) => (
  <Menu.Item key={item.name}>
    {({ active }) => (
      <a
        onClick={() =>{ handleFilterText(item.name)
          toggleDropdown();}
        }

        href={item.href}
        className={classNames(
          active ? 'bg-gray-100' : '',
          'block py-2 px-4 text-[13px] text-gray-700'
        )}
      >
        <span>
          {item.name}
          {item.subBuckets && item.subBuckets.length > 0 ? <FontAwesomeIcon style={{ fontSize: '12px', color: '#707070' }} /> : null}
        </span>
   
        {item.subBuckets && item.subBuckets.length > 0 ? (
          <Fragment>
            {item.subBuckets.map((subTag) => (
              <li key={subTag._id}>
                <span onClick={(e) =>{
                  e.stopPropagation()
  setbucketFiltersText(subTag.name)
                } 
                  
                }>
                  {subTag.name}
                </span>
              </li>
            ))}
          </Fragment>
        ) : null}
      </a>
    )}
  </Menu.Item>
))}



                </Menu.Items>
              </Transition>
          </Menu> */}
                <div className="flex items-center rounded-3xl border-0 menusubbucketitems-content bucketspacefile-content">
                  <div className="w-full">
                    <strong className="createdsubtext-content pr-4">
                      <div
                        className="flex fliesbucket-inputcontent"
                        onClick={handleFilterBucketOpen}
                      >
                        <input
                          type="text"
                          placeholder="Buckets"
                          className="filespopupbg-content"
                          value={filterBucket}
                          onChange={handleFilterBucketInputChange}
                          readOnly
                        />
                        <div className="dropdownfilespopup-content">
                          {filterBucket ? (
                            <FontAwesomeIcon
                              style={{ fontSize: "12px" }}
                              icon={fontIcons.faXmark}
                              onClick={handleClearBucket}
                            />
                          ) : (
                            <FontAwesomeIcon
                              style={{ fontSize: "12px" }}
                              icon={faChevronDown}
                            />
                          )}
                        </div>
                        <div className="tab-scrolls filescard">
                          {isMenuBucket ? (
                            <ul className="dropdown">
                              {/* <li
                                style={{ textAlign: "right", fontSize: "12px" }}
                                onClick={handleClearBucket}
                              >
                                clear
                              </li> */}
                              {buckets?.map((option) => (
                                <li key={option._id}>
                                  <p
                                    onClick={() =>
                                      handleFilterBucketChange(option)
                                    }
                                  >
                                    {option.name}
                                    {option.subBuckets &&
                                    option.subBuckets.length > 0 ? (
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "12px",
                                          color: "#707070",
                                        }}
                                      />
                                    ) : null}
                                  </p>
                                  {option.subBuckets &&
                                  option.subBuckets.length > 0 ? (
                                    <ul className="sub-dropdowns ">
                                      {option.subBuckets.map((subTag) => (
                                        <li key={subTag._id}>
                                          <p
                                            onClick={() =>
                                              handleFilterSubBucketSelectChange(
                                                subTag
                                              )
                                            }
                                          >
                                            -- {subTag.name}
                                          </p>
                                        </li>
                                      ))}
                                    </ul>
                                  ) : null}
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </div>
                      </div>
                    </strong>
                  </div>

                  {/* <div><FontAwesomeIcon className="iconimg-content" style={{ fontSize: '12px', color: '#707070' }} icon={faChevronDown} /></div> */}
                </div>
                <div className="flex items-center rounded-3xl border-0 menusubbucketitems-content">
                  <div className="w-full">
                    <strong className="createdsubtext-content pr-4">
                      <div
                        className="flex fliesbucket-inputcontent"
                        onClick={handleTagFocus}
                      >
                        <input
                          type="text"
                          placeholder="Tags"
                          className="filespopupbg-content"
                          value={filterTag}
                          onChange={handleFilterTagInputChange}
                          readOnly
                        />
                        <div className="dropdownfilespopup-content">
                          {filterTag ? (
                            <FontAwesomeIcon
                              style={{ fontSize: "12px" }}
                              icon={fontIcons.faXmark}
                              onClick={handleClearTag}
                            />
                          ) : (
                            <FontAwesomeIcon
                              style={{ fontSize: "12px" }}
                              icon={faChevronDown}
                            />
                          )}
                        </div>
                        <div className="tab-scrolls filescard">
                          {IsFilterTagOpen ? (
                            <ul className="dropdown">
                              {/* <li
                                style={{ textAlign: "right", fontSize: "12px" }}
                                onClick={handleClearTag}
                              >
                                clear
                              </li> */}
                              {tagOptions?.map((option) => (
                                <li key={option._id}>
                                  <p
                                    onClick={(e) =>
                                      handleFilterSelectChange(e, option)
                                    }
                                  >
                                    {option.name}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </div>
                      </div>
                    </strong>
                  </div>

                  {/* <div><FontAwesomeIcon className="iconimg-content" style={{ fontSize: '12px', color: '#707070' }} icon={faChevronDown} /></div> */}
                </div>
                {/* <div className="flex items-center rounded-3xl border-0 menusubitems-content" onClick={handleTagFocus}>
              <div className="w-full">
                <div className="flex">
                <input
                  type="text"
                  placeholder="Tags"
                  className="filespopupbg-content"
                  value={filterTag}
                  onChange={handleFilterSelectChange}
                  readOnly  
                />
                  <FontAwesomeIcon className="fileicon-content" style={{  }} icon={faChevronDown} onClick={handleTagFocus}/>
                </div>
               
              
      {IsFilterTagOpen && (
        <div>
     
    
      <nav>
      <div className="tab-scrolls tagscard">
        <ul className="dropdown">  
          {tagOptions.map((option) => (
            <li
              key={option._id}
               
            >
              <span onClick={() => handleFilterSelectChange(option)}>{option.name}</span>
             
            </li>
          ))}
        </ul>
      </div>
      </nav>
           </div>
      )} 
 


    
              </div>
            </div> */}
                {/* <Menu as="div" className="relative flex-shrink-0">
              <div>
                <Menu.Button className="flex rounded-full focus:outline-none bordered">
                  <div href="#" className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500 bucketswhole-spacecontent">
                    <strong className="createdbuckettext-content pr-4">Tags</strong>
                    <FontAwesomeIcon className="dropdownicon-bucketcontent" style={{fontSize:'12px'}} icon={faChevronDown} />
                  </div>
                </Menu.Button>
              </div> 
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">                           
                  {filterBucketsDropDown.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a
                          onClick={()=>{handleFilterTagText(item.name)}}
                          href={item.href}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block py-2 px-4 text-[13px] text-gray-700'
                          )}
                        >
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
          </Menu> */}

                <Transition appear show={isOpenss} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={closeCardModal}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              Delete
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to delete?
                              </p>
                            </div>

                            <div className="mt-4">
                              <button
                                style={{
                                  background: "#FF6600",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              >
                                Yes
                              </button>
                              <button
                                style={{
                                  background: "#000",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={closeCardModal}
                              >
                                No
                              </button>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
                {/* <FontAwesomeIcon className="transform rotate-45 px-3" icon={faArrowUp } /> */}
              </div>
              <div className="flex">
                <div className="flex items-center justify-end">
                  <div className="filter display-content">
                    {isListView && (
                      <div onClick={handleIsListView}>
                        <a className="viewGrid" href="#">
                          <img
                            className="mt-1 listcard-imgcontent"
                            src={ListView}
                            style={{ color: "#000000" }}
                            alt="listView"
                          />
                        </a>
                      </div>
                    )}
                    {isListViewIcon && (
                      <div onClick={handleIsListViewIcon}>
                        <a className="viewGrid" href="#">
                          <img
                            className="mt-1 listcard-imgcontent"
                            src={ListviewIcon}
                            alt="listviewIcon "
                          />
                        </a>
                      </div>
                    )}
                    {isGridView && (
                      <div onClick={handleIsGridView}>
                        <a className="viewGrid" href="#">
                          <img
                            className="mt-1 compact-imgcontent"
                            src={compactCardIcon}
                            alt="compactIcon"
                          />
                        </a>
                      </div>
                    )}
                    {isGridViewIcon && (
                      <div onClick={handleIsGridViewIcon}>
                        <a className="viewGrid" href="#">
                          <img
                            className="mt-1 compact-imgcontent"
                            src={GridviewIcon}
                            alt="compactIcon"
                          />
                        </a>
                      </div>
                    )}
                  </div>

                  <span className="sorttext-content mx-3 dark:text-[#acacac]">
                    Sort By
                  </span>
                  <Menu as="div" className="relative flex-shrink-0">
                    <div>
                      <Menu.Button className="flex rounded-full focus:outline-none ">
                        <a
                          href="#"
                          className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                        >
                          <strong className="createdtext-content pr-4">
                            {filtersText}
                          </strong>
                          <FontAwesomeIcon
                            style={{ fontSize: "12px", color: "#707070" }}
                            icon={faChevronDown}
                          />
                        </a>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {filterFilesDropDown.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                onClick={() => {
                                  handleFilterFilesText(item.name);
                                }}
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <div className="subbucketheadermbl-section ">
            <div className="cards-filter  mt-[18px] ml-[10px] mr-[10px] flex justify-between">
              <div className="flex items-center">
                <div className="card-count flex items-center">
                  <h4 className="cardssubBuckets-content">Files</h4>
                  <span className="subbucketsbg-content background-primary text-white rounded-full ml-4 text-[16px]">
                    {filesData?.length}
                  </span>
                </div>
                <Menu as="div" className="relative px-2">
                  <Menu.Button className="relative ml-3 inline-flex items-center px-1 py-2 text-sm font-semibold text-gray-900 ">
                    {/* <FontAwesomeIcon className="text-lg text-[#707070]" icon={faEllipsisVertical} /> */}
                    <img
                      className="threedots-content"
                      src={ThreeDots}
                      alt="threeDots"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {bucketNav.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-sm text-gray-700"
                              )}
                            >
                              <span className="pr-2">
                                <FontAwesomeIcon
                                  className="text-[#797979]"
                                  icon={item.icon}
                                />
                              </span>
                              <span className="text-black">{item.name}</span>
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
                <img
                  src={DeleteIcon}
                  alt="deleteIcon"
                  className="px-3"
                  onClick={() => deleteCard()}
                />
                <Transition appear show={isOpens} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={CloseDeleteModal}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              Delete
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to delete?
                              </p>
                            </div>

                            <div className="mt-4">
                              <button
                                style={{
                                  background: "#FF6600",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={() => handleDeleteCard()}
                              >
                                Yes
                              </button>
                              <button
                                style={{
                                  background: "#000",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={CloseDeleteModal}
                              >
                                No
                              </button>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
                <img src={DeleteIcon} alt="deleteIcon" className="px-3" />

                <Transition appear show={isOpenss} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={closeCardModal}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              Delete
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to delete?
                              </p>
                            </div>

                            <div className="mt-4">
                              <button
                                style={{
                                  background: "#FF6600",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              >
                                Yes
                              </button>
                              <button
                                style={{
                                  background: "#000",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={closeCardModal}
                              >
                                No
                              </button>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
                {/* <FontAwesomeIcon className="transform rotate-45 px-3" icon={faArrowUp } /> */}
              </div>
              <div className="flex">
                <div className="flex items-center justify-end">
                  <div className="filter display-content">
                    {/* {isListView &&
                <div onClick={handleIsListView}>
                  <a className="viewGrid" href="#">
                    <img className="mt-1 mr-3" src={ListView} style={{ color: '#000000' }} alt="listView" />
                  </a>
                </div>
              }
              {isListViewIcon &&
                <div onClick={handleIsListViewIcon}>
                  <a className="viewGrid" href="#">
                    <img className="mt-1 mr-3" src={ListviewIcon} alt="listviewIcon " />
                  </a>
                </div>

              } */}
                    {/* {isGridView &&
                <div onClick={handleIsGridView}>
                  <a className="viewGrid" href="#">
                    <img className="mt-1 mr-3" src={compactCardIcon} alt="compactIcon" />
                  </a>
                </div>
              }
              {isGridViewIcon &&
                <div onClick={handleIsGridViewIcon}>
                  <a className="viewGrid" href="#">
                    <img className="mt-1 mr-3" src={GridviewIcon} alt="compactIcon" />
                  </a>
                </div>
              } */}
                  </div>

                  <div>
                    <Menu
                      as="div"
                      className="sortmbl-content relative flex-shrink-0"
                    >
                      <div>
                        <Menu.Button className="flex rounded-full dark:text-[#acacac] focus:outline-none">
                          <a
                            href="#"
                            className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500 "
                          >
                            <img src={SortMbl} alt="SortMbl" />
                          </a>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="filter-dropdown dropdownmbl-content absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {filterDropDown.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  onClick={() => {
                                    handleFilterText(item.name);
                                  }}
                                  href={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block py-2 px-4 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isListViewOpen && (
            <div>
              <div className="flex fileswhole-section">
                <div className="file-section fileheading-content filespace-content">
                  File name
                </div>
                <div className="bucket-section fileheading-content">
                  Bucket name
                </div>
                <div className="upload-section fileheading-content">
                  Upload date
                </div>
                <div className="sharedwith-section fileheading-content">
                  Shared with
                </div>
                <div className="size-section fileheading-content">Size</div>
                <div className="action-section fileheading-content">Action</div>
              </div>
              <hr />
              {filesData.map((file) => {
                return (
                  <div
                    className="flex spacefiles-content"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/app/card-detail/${file?._id}`);
                    }}
                  >
                    <div className="file-section flex">
                      <input
                        id="default-checkbox"
                        onClick={(e) => e.stopPropagation()}
                        type="checkbox"
                        value={file._id}
                        onChange={handleCheckboxChange}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <img
                        className="pl-3 pr-3"
                        src={FilesDetailIcon}
                        alt="PDF"
                      />
                      <div className="tooltip">
                        <span className="tooltip-text bucketshrename">
                          <p className="block text-backgroundshare">
                            {file?.title}
                          </p>
                        </span>
                        <span className="bucket-title">{file.title}</span>
                      </div>
                    </div>
                    <div className="bucket-section filesection-content">
                      {file?.bucketId?.name}
                    </div>
                    <div className="upload-section filesection-content">
                      {new Date(file.createdAt).toLocaleString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </div>
                    <div className="sharedwith-section">
                      {file?.shared?.map((share) =>
                        share?.sharedWith?.fullName ? (
                          <div key={share._id}>
                            {share.sharedWith.fullName},
                          </div>
                        ) : null
                      ) || "-"}
                    </div>
                    <div className="size-section filesection-content">
                      {convertBytesToMB(file?.sizeInBytes)} MB
                    </div>
                    <div
                      className="action-section flex"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <a href={"javascript:void(0)"}>
                        {" "}
                        {file.note && file.note != "" ? (
                          <div className="tooltip">
                            <span className="tooltip-text">
                              <p>{file.note ? parse(file.note) : ""}</p>
                            </span>
                            <img
                              className="tooltip-icon"
                              src={clipboardNotes}
                              style={{
                                width: "20px",
                                marginRight: "24px",
                                paddingTop: "4px",
                              }}
                            />
                          </div>
                        ) : (
                          <img
                            src={ClipBoard}
                            style={{
                              width: "20px",
                              marginRight: "24px",
                              paddingTop: "4px",
                            }}
                            alt="card-note-image/>"
                          />
                        )}
                      </a>
                      <button
                        type="button"
                        onClick={() =>
                          handleStarClick(file?._id, file?.starred === true)
                        }
                      >
                        {" "}
                        {file?.starred === true ? (
                          <img
                            src={Union}
                            alt="subStar"
                            style={{ width: "20px", marginRight: "24px" }}
                          />
                        ) : (
                          <img
                            src={SubStar}
                            style={{ marginRight: "24px" }}
                            alt="subStar"
                          />
                        )}
                      </button>
                      <img
                        className=""
                        src={SubShare}
                        alt="subShare"
                        style={{ marginRight: "24px" }}
                        onClick={() => handleSharePop(file?._id)}
                      />

                      <div className="iconsRight flex iconsalign-content">
                        <Menu as="div" className="relative">
                          <Menu.Button className="relative inline-flex items-center text-sm font-semibold text-gray-900 ">
                            {/* <FontAwesomeIcon className="text-lg text-[#707070]" icon={faEllipsisVertical} /> */}
                            {/* <img src={ThreeDots} alt="threeDots" /> */}
                            <FontAwesomeIcon
                              className="text-[#707070] text-[22px]"
                              icon={faEllipsisVertical}
                            />
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {bucketNav.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <a
                                      href={`javascript:void(0);`}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block py-2 px-4 text-sm text-gray-700"
                                      )}
                                      onClick={() =>
                                        handleAction(
                                          item,
                                          file?.content?.imageUrl[0]?.url,
                                          file?.title.split(".").reverse()[0]
                                        )
                                      }
                                    >
                                      <span className="pr-2">
                                        <FontAwesomeIcon
                                          className="text-[#797979]"
                                          icon={item.icon}
                                        />
                                      </span>
                                      <span className="text-black">
                                        {item.name}
                                      </span>
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {isGridViewOpen && (
            <div className="filescard-section flex flex-wrap">
              {filesData.map((file) => {
                const sharedWithObjects = file?.shared
                  ? file.shared
                      .filter((shares) => shares.sharedWith != null)
                      .map((shares) => shares.sharedWith)
                  : [];
                return (
                  <SingleFile
                    key={file?._id}
                    Url={file?.content?.imageUrl[0]?.url}
                    handleSharePop={handleSharePop}
                    starred={file?.starred}
                    buckets={buckets}
                    cardId={file?._id}
                    sharedUsers={sharedWithObjects}
                    sharedBy={[]}
                    cardType={file?.cardType}
                    sharedLength={file?.shared.length}
                    title={file?.title}
                    note={file?.note}
                    tags={file?.tags}
                    created={file?.createdAt}
                    content={file?.content}
                    handleCheckboxChange={handleCheckboxChange}
                    handleCardClick={handleCardClick}
                    cardsRefresh={cardsRefresh}
                    setIsLoading={setIsLoading}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default Files;
