import { React, Fragment, useState, useRef, useEffect } from "react";
import "./Bookmarks.scss";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Masonry from "react-smart-masonry";
import {
  faNoteSticky,
  faPencil,
  faTrash,
  faFolder,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import * as fontIcons from "@fortawesome/free-solid-svg-icons";
import { Menu, Dialog, Transition } from "@headlessui/react";
import ThreeDots from "../../assets/icons/threeDots.svg";
// import DeleteIcon from "../../assets/icons/delete.svg";
import ListView from "../../assets/icons/listView.svg";
import compactCardIcon from "../../assets/icons/gridcard.svg";
import ClipBoard from "../../assets/icons/subClipboard.svg";
import SubStar from "../../assets/icons/subStar.svg";
import SubTag from "../../assets/icons/subTag.svg";
import SubShare from "../../assets/icons/subShare.svg";
import Profile1 from "../../assets/icons/profile1.png";
import Profile2 from "../../assets/icons/profile2.png";
import Profile3 from "../../assets/icons/profile3.png";
import CardImage1 from "../../assets/images/cardImage1.png";
import linkIcon from "../../assets/icons/linksicon.svg";
import BookmarksIcon from "../../assets/images/bookmarksIcon.svg";
import BookmarksImg1 from "../../assets/images/bookmarksimg1.png";
import BookmarksImg2 from "../../assets/images/bookmarksimg2.png";
import BookmarksImg3 from "../../assets/images/bookmarksimg3.png";
import BookmarksImg4 from "../../assets/images/bookmarksimg4.png";
import BookmarksImg5 from "../../assets/images/bookmarksimg5.png";
import BookmarksImg6 from "../../assets/images/bookmarksimg6.png";
import ListviewIcon from "../../assets/icons/listBlackIcon.svg";
import GridviewIcon from "../../assets/icons/gridgreyIcon.svg";
import BookmarksShared from "../../assets/icons/bookmarksShared.svg";
import { toastify } from "../../components/Toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadIcon from "../../assets/icons/uploadIcon.svg";
import DismissButton from "../../assets/icons/dismissButton.svg";
import BookmarkList from "../../components/Bookmark List/bookmarklist";
import DeleteIcon from "../../assets/icons/DarkGrey-Delete.svg";
import LightDelete from "../../assets/icons/Grey_Delete.svg";
import magnifier from "../../assets/icons/magnifyGlass.svg";
import SortMbl from "../../assets/icons/sortmbl.svg";
import Dropdown from "../../assets/icons/Dropdown.svg";
import BlackDismiss from "../../assets/icons/dismissButton.svg";
import TagsInput from "react-tagsinput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from "quill";
import "react-tagsinput/react-tagsinput.css";
import SingleBook from "../SingleBookmark/singlebookmark";
import ShareCard from "../../shades/cards/shareCard";
function BookmarksGrid() {
  const breakpoints = { mobile: 0, tablet: 900, desktop: 1200 };
  let [isOpenss, setIsOpens] = useState(false);
  const [isListBookmarksView, setIsListBookmarksView] = useState(true);
  const [isGridBookmarksView, setIsGridBookmarksView] = useState(true);
  const [isListBookmarksViewIcon, setIsListBookmarksViewIcon] = useState(false);
  const [isGridBookmarksViewIcon, setIsGridBookmarksViewIcon] = useState(false);
  const [isListBookmarksViewOpen, setIsListBookmarksViewOpen] = useState(false);
  const [isGridBookmarksViewOpen, setIsGridBookmarksViewOpen] = useState(true);
  const [bookmarksfilespopup, setBookmarksfilesPopup] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [inputValueSet, setInputValueSet] = useState("");
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [subtags, setSubTags] = useState([]); 
  const [tagActiveId, setTagActiveId] = useState(0);
  const [tagOptions, setTagOptions] = useState([]);
  const [tagActiveName, setTagActiveName] = useState("");
  const [bookmarkCards, setBookMarkCards] = useState([]);
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [bookmarksRefresh, setbookmarksRefresh] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isLoadings, setIsLoadings] = useState(false);
  const [notes, setNotes] = useState("");
  const [MainMenuText, setMainMenuText] = useState("");
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [searchBucket, setSearchBucket] = useState("");
  const [filterBucket, setFilterBucket] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [buckets, setBuckets] = useState([]);
  const [hoveredOption, setHoveredOption] = useState(null);
  const [isEditorFocused, setIsEditorFocused] = useState(false);
  const [isBucketOpen, setIsBucketOpen] = useState(false);
  const [selectedBucket, setSelectedBucket] = useState("");
  const [bookmarkUrl, setBookmarkUrl] = useState("");
  const [filterSelectedBucket, setFilterSelectedBucket] = useState("");
  const [isMenuBucket, setMenuBucket] = useState(false);
  const [IsFilterTagOpen, setIsFilterTagOpen] = useState(false);
  const [filterTag, setFiltertag] = useState("");
  const [filterTagId, setFilterTagId] = useState("");
  const [ShareBookmarkId, setShareBookmarkId] = useState("");
  const [FileRefresh, setFileRefresh] = useState(false);
  const [BookmarkSharePopup, setBookmarkSharePopup] = useState(false);
  const [appTheme,setAppTheme]=useState("")
  useEffect(() => {
    const handleStorageChange = () => {
      console.log('app theme')
      const theme = localStorage.getItem("theme");
      setAppTheme(theme);
    };
  
    window.addEventListener('storage', handleStorageChange);
  
    // Force a re-render when localStorage changes
    setAppTheme(localStorage.getItem("theme"));
  
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  },[]);
  useEffect(() => {
    console.log(IsFilterTagOpen, "filter tags opne");
  }, [tags, IsFilterTagOpen]);

  useEffect(() => {
    console.log(document.title, "console document title");
  }, []);
  const bucketNav = [
    // {icon:faPencil, name: 'Edit Name', href: 'javascript:void(0)' },
    { icon: faNoteSticky, name: "Select All", href: "javascript:void(0)" },
    { icon: faTrash, name: "Unselect All", href: "javascript:void(0)" },
  ];

  const closeSharePop = () => {
    console.log("close share pop");
    setBookmarkSharePopup(false);
  };

  const filterFilesDropDown = [
    { name: "Created Date", href: "#" },
    { name: "Recent Activity", href: "#" },
    { name: "Ascending Order", href: "#" },
    { name: "Descending Order", href: "#" },
  ];
  const modules = {
    clipboard: {
      matchVisual: false,
    },
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        {
          color: [
            "#FF0000", // Red
            "#00FF00", // Green
            "#0000FF", // Blue
            "#FFFF00", // Yellow
            "#00FFFF", // Cyan
            "#FF00FF", // Magenta
            "#000000", // Black
            "#FFFFFF", // White
            "#808080", // Gray

            // Web-safe colors
            "#800000", // Maroon
            "#808000", // Olive
            "#000080", // Navy
            "#800080", // Purple
            "#008080", // Teal
            "#C0C0C0", // Silver
            // Shades of gray
            "#A9A9A9", // Dark gray
            "#696969", // Dim gray
            "#D3D3D3", // Light gray
            "#DCDCDC", // Gainsboro

            // Pastel colors
            "#FFB6C1", // Light pink
            "#90EE90", // Light green
            "#ADD8E6", // Light blue
            "#E6E6FA", // Lavender

            // CSS named colors
            "#FF6347", // Tomato
            "#F4A460", // Sandy Brown
            "#6495ED", // Cornflower Blue
            "#9932CC", // Dark Orchid
          ],
        },
      ],
      [
        {
          background: [
            "#FF0000", // Red
            "#00FF00", // Green
            "#0000FF", // Blue
            "#FFFF00", // Yellow
            "#00FFFF", // Cyan
            "#FF00FF", // Magenta
            "#000000", // Black
            "#FFFFFF", // White
            "#808080", // Gray

            // Web-safe colors
            "#800000", // Maroon
            "#808000", // Olive
            "#000080", // Navy
            "#800080", // Purple
            "#008080", // Teal
            "#C0C0C0", // Silver
            // Shades of gray
            "#A9A9A9", // Dark gray
            "#696969", // Dim gray
            "#D3D3D3", // Light gray
            "#DCDCDC", // Gainsboro

            // Pastel colors
            "#FFB6C1", // Light pink
            "#90EE90", // Light green
            "#ADD8E6", // Light blue
            "#E6E6FA", // Lavender

            // CSS named colors
            "#FF6347", // Tomato
            "#F4A460", // Sandy Brown
            "#6495ED", // Cornflower Blue
            "#9932CC", // Dark Orchid
          ],
        },
      ],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];
  let cardFilterv = "";
  if (!localStorage.getItem("cardFilter")) {
    localStorage.setItem("cardFilter", "Created_Date");
    cardFilterv = "Created_Date";
  } else {
    cardFilterv = localStorage.getItem("cardFilter");
  }
  console.log(cardFilterv, "cardFilterv");
  const [cardFilterDefault, setCardFilterDefault] = useState(cardFilterv);
  const [FiltersSelectText, setFiltersSelectText] = useState(
    cardFilterv == "Created_Date"
      ? filterFilesDropDown[0].name
      : cardFilterv == "Descending_Order"
      ? filterFilesDropDown[3].name
      : cardFilterv == "Ascending_Order"
      ? filterFilesDropDown[2].name
      : ""
  );
  const handleChangeUrl = (e) => {
    let newUrl = e.target.value;

    setBookmarkUrl(newUrl);
  };
  const handleSortFilterText = async (text) => {
    // setFiltersText(text);
    console.log(text, "handlefiltertext");

    setFiltersSelectText(text);
    const filterValues =
      text == "Created Date"
        ? "Created_Date"
        : text == "Descending Order"
        ? "Descending_Order"
        : text == "Ascending Order"
        ? "Ascending_Order"
        : text == "Recent Activity"
        ? "Recent_Activity"
        : "";
    localStorage.setItem("cardFilter", filterValues);
    setbookmarksRefresh(true);
    setCardFilterDefault(filterValues);
  };
  const fetchTags = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API}tags/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request header
          },
        }
      );
      console.log(response?.data?.data?.name, "tags list");
      setTagOptions(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    // api url

    const token = localStorage.getItem("token");

    axios
      .post(
        `
          https://api.zimzee.com/api/cards/cards-with-bookmark`,
        {
          filterValue: cardFilterDefault,
          limit: 10000,
          bucketId: filterSelectedBucket ? filterSelectedBucket : "",
          tagId: filterTagId ? filterTagId : "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setBookMarkCards(response?.data?.data);
        setbookmarksRefresh(false);
        setIsLoadings(false);
        // setIsLoadings(false);
        // setFilesData(response?.data?.data)
        setNotes(response?.data?.data?.note);
        setTag(response?.data?.data?.tags);
        console.log("filessssssssssss", response?.data?.data);
        setFileRefresh(false);
        // setCardList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [cardFilterDefault, filterSelectedBucket, filterTagId, bookmarksRefresh]);
  useEffect(() => {
    // Function to fetch data from the API and update the state
    const token = localStorage.getItem("token");
    const fetchBuckets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}buckets/user-buckets`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ); // Replace this with the actual API endpoint
        setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      setIsLoadings(true);
      try {
        const response = await axios.post(
          "https://api.zimzee.com/api/cards/cards-with-bookmark",
          {
            filterValue: cardFilterDefault,
            limit: 10000,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response, "bookmarks response");
        setBookMarkCards(response?.data?.data);
        setbookmarksRefresh(false);
        setIsLoadings(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // Call the async function immediately

    // The empty dependency array means this effect runs once after initial render.
  }, [bookmarksRefresh]);
  const handleDeleteCard = async () => {
    console.log(selectedItems);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        "https://api.zimzee.com/api/cards/delete",
        {
          data: { deletedIds: selectedItems },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      setSelectedItems([]);
      closeCardModal();
      cardsRefresh();
    } catch (error) {
      console.error(error);
      closeCardModal();
      toastify("error", error.response.data.message);
    }
  };
  const cardsRefresh = () => {
    setbookmarksRefresh(!bookmarksRefresh);
  };
  const handleBlur = () => {
    setIsTagOpen(false);
  };
  const handleProcedureContentChange = (content, delta, source, editor) => {
    setNotes(content);
  };
  const handleFilterBucketChange = (bucket) => {
    const Selectbucket = bucket._id;
    setFilterSelectedBucket(Selectbucket);
    const newBucket = bucket.name;
    setFilterBucket(newBucket);
    setMenuBucket(false);
    setFiltertag("");
    setFilterTagId("");
  };
  const handleEditorFocus = () => {
    setIsEditorFocused(true);
    const elements = document.getElementsByClassName("ql-toolbar");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("my-class-selected");
    }
  };
  const handleEditorClick = () => {
    setIsEditorFocused(true);
    const elements = document.getElementsByClassName("ql-toolbar");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("my-class-selected");
    }
  };
  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedHTML = clipboardData.getData("text/html");
    const quill = this.reactQuillRef.getEditor();
    const selection = quill.getSelection();
    const index = selection ? selection.index : 0;
    const delta = quill.clipboard.convert(pastedHTML);
    quill.updateContents(delta, Quill.sources.USER);
    quill.setSelection(index + delta.length(), Quill.sources.SILENT);
  };
  const handleEditorBlur = () => {
    setIsEditorFocused(false);
    const elements = document.getElementsByClassName("ql-toolbar");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("my-class-selected");
    }
  };
  const handleKeyUp = () => {
    setIsEditorFocused(true);
    const elements = document.getElementsByClassName("ql-toolbar");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("my-class-selected");
    }
  };
  const handleClick = () => {
    setIsEditorFocused(true);
    const elements = document.getElementsByClassName("ql-toolbar");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("my-class-selected");
    }
  };
  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      const newTag = selectedOption.name;
      setTags([...tags, newTag]);
      setSelectedOption(null);
      setInputValueSet("");
      handleTagsChange([...tags, newTag]);
      handleBlur();
    }
  };

  const handleFilterSelectChange = (e, selectedOption) => {
    console.log(selectedOption, "selected taggggggggggg");
    e.stopPropagation();
    if (selectedOption) {
      const newTag = selectedOption.name;
      setFiltertag(newTag);
      setFilterTagId(selectedOption._id);
      setFilterBucket("");
      // setSelectedOption(null);
      // setInputValue('');
      // handleTagsChange([...tags, newTag]);
      // handleBlur();
    }
    setIsFilterTagOpen(false);
  };

  const handleTagsChange = (newTags) => {
    setTags(newTags);
    const inputElement = document.querySelector(".react-tagsinput-input");

    // Check if the input element exists before calling focus
    if (inputElement) {
      inputElement.focus();
    }
  };
  const handleFocus = (e) => {
    e.stopPropagation();
    setIsTagOpen(true);
    setIsBucketOpen(false);
  };
  const handleTagFocus = (e) => {
    e.stopPropagation();
    setIsFilterTagOpen(true);
    setMenuBucket(false);
  };

  const handleMouseEnter = (option) => {
    console.log(option, "hovered option.......");
    setHoveredOption(option);
  };
  const handleBucketChange = (bucket) => {
    const Selectbucket = bucket._id;
    setSelectedBucket(Selectbucket);
    const newBucket = bucket.name;
    setSearchBucket(newBucket);
    setIsBucketOpen(false);
  };
  const handleMouseLeave = () => {
    setHoveredOption(null);
  };
  const handleFilterBucketInputChange = (event) => {
    setFilterBucket(event.target.value);
  };
  const handleFilterTagInputChange = (e) => {
    setFiltertag(e.target.value);
  };
  const handleBucketInputChange = (event) => {
    setSearchBucket(event.target.value);
  };
  const filteredOptions = tagOptions.filter((option) =>
    option.name.toLowerCase().includes(inputValueSet.toLowerCase())
  );

  const handleSetInputValue = (e) => {
    setInputValueSet(e);
    setIsTagOpen(true);
  };

  const filteredBuckets = buckets?.filter((bucket) => {
    const bucketNameMatches = bucket.name.toLowerCase().includes(searchBucket);
    const subBucketMatches = bucket.subBuckets?.some((subBucket) =>
      subBucket.name.toLowerCase().includes(searchBucket)
    );
    return bucketNameMatches || subBucketMatches;
  });
  const handleBucketOpen = (e) => {
    e.stopPropagation();
    setIsBucketOpen(!isBucketOpen);
    setIsTagOpen(false);
  };
  const handleSubBucketSelectChange = (subbucket) => {
    const newBucket = subbucket._id;
    setSelectedBucket(newBucket);
    setSearchBucket(subbucket.name);
    setIsBucketOpen(false);
  };

  const handleFilterSubBucketSelectChange = (subbucket) => {
    const newBucket = subbucket._id;
    setFilterSelectedBucket(newBucket);
    setFilterBucket(subbucket.name);
    setMenuBucket(false);
  };
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, value]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== value)
      );
    }
  };
  const handleFilterBucketOpen = (e) => {
    e.stopPropagation();
    setMenuBucket(!isMenuBucket);
    setIsFilterTagOpen(false);
  };
  const handleFilterFilesText = (text) => {
    setFiltersSelectText(text);
  };
  const handleCardClick = (text) => {
    if (text == "Select All") {
      var checkboxes = document.querySelectorAll(
        '.cardHolder-cards input[type="checkbox"]'
      );
      var checkedValues = []; // Array to store the values of checked checkboxes

      checkboxes.forEach(function (checkbox) {
        if (!checkbox.checked) {
          var checked = true; // Replace with your desired checked state
          checkbox.checked = checked;
          if (checkbox.checked) {
            checkedValues.push(checkbox.value);
            setSelectedItems((prevSelectedItems) => [
              ...prevSelectedItems,
              checkbox.value,
            ]);
          }
        } else {
          var checked = false; // Replace with your desired checked state
          checkbox.checked = checked;
          setSelectedItems([]);
        }
      });
    } else if (text == "Unselect All") {
      var checkboxes = document.querySelectorAll(
        '.cardHolder-cards input[type="checkbox"]'
      );
      var checkedValues = []; // Array to store the values of checked checkboxes

      checkboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
          var checked = false; // Replace with your desired checked state
          checkbox.checked = checked;
          setSelectedItems([]);
        }
      });
    }
  };

  const handleIsGridBookmarksView = () => {
    setIsListBookmarksView(true);
    setIsGridBookmarksView(true);
    setIsGridBookmarksViewOpen(true);
    setIsListBookmarksViewOpen(false);
    setIsListBookmarksViewIcon(false);
  };

  const handleIsListBookmarksView = () => {
    setIsListBookmarksView(false);
    setIsGridBookmarksView(false);
    setIsListBookmarksViewOpen(true);
    setIsGridBookmarksViewOpen(false);
    setIsListBookmarksViewIcon(true);
    setIsGridBookmarksViewIcon(true);
  };

  const handleIsListBookmarksViewIcon = () => {
    setIsListBookmarksView(true);
    setIsGridBookmarksView(false);
    setIsListBookmarksViewOpen(false);
    setIsGridBookmarksViewOpen(true);
    setIsListBookmarksViewIcon(false);
    // setIsGridViewIcon(true)
  };

  const handleIsGridBookmarksViewIcon = () => {
    setIsListBookmarksView(true);
    setIsGridBookmarksView(true);
    setIsListBookmarksViewOpen(false);
    setIsGridBookmarksViewOpen(true);
    setIsListBookmarksViewIcon(false);
    setIsGridBookmarksViewIcon(false);
  };

  const handlebookmarksfilesDrop = (event) => {
    event.preventDefault();

    const newFiles = Array.from(event.dataTransfer.files);
    setFiles(newFiles);
  };
  const handleSharePop = (id) => {
    console.log(id, "share card id");
    setShareBookmarkId(id);
    setBookmarkSharePopup(true);
  };
  const handlebookmarksDragOver = (event) => {
    event.preventDefault();
  };
  const handleBookmarkfilesChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles(newFiles);
  };
  const handleBookmarksfilesPopup = () => {
    setBookmarksfilesPopup(true);
  };
  const handlebookmarkClickBrowse = () => {
    fileInputRef.current.click();
  };

  function closeBookmarksfilesPopup() {
    setBookmarksfilesPopup(false);
    setIsBucketOpen(false);
    setTags([]);
    setNotes("");
    setSearchBucket("");
    setIsTagOpen(false);
    setInputValueSet("");
    setBookmarkUrl("");
  }
  const handleAddBookmarks = async () => {
    const tagsPayload = JSON.stringify(tags.map((tag) => ({ name: tag })));
    const token = localStorage.getItem("token");
    const userData = JSON.parse(localStorage.getItem("userData"));
    const formData = new FormData();
    const content = {
      html: "test",
      sourceUrl: bookmarkUrl,
      imageUrl: [],
    };
    const data = JSON.stringify(content);

    formData.append("tags", tagsPayload);
    formData.append("note", notes ? notes : "");
    formData.append("title", "title");
    formData.append("userId", userData._id);
    formData.append("bucketId", selectedBucket);
    formData.append("cardType", "bookmark");
    formData.append("content", data);
    formData.append(
      "postDetail",
      JSON.stringify({ title: "sdasdas", imageUrl: "sdasdsadsad" })
    );
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}cards/add`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request header
          },
        }
      );
      toastify("success", response.data.message);
      closeBookmarksfilesPopup();
      setSelectedBucket("");
      setNotes("");
      setbookmarksRefresh(true);
    } catch (error) {
      toastify("error", error.response.data.message );
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchChange = async (event) => {
    setSearchValue(event.target.value);
    const token = localStorage.getItem("token");

    await axios
      .get(`${process.env.REACT_APP_API}tags/list/${event.target.value}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        // setIsLoading(false);
        if (response?.data?.data && response?.data?.data.length) {
          setSubTags(response?.data?.data);
          setTagActiveId(response?.data?.data[0]._id);
        } else {
          setSubTags([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const filterDropDown = [
    { name: "Fashion trends 2022 sketch", href: "#" },
    { name: "Fashion bucket", href: "#" },
    { name: "Cooking receipes", href: "#" },
    { name: "Inspirations", href: "#" },
  ];

  const [setbucketFiltersText] = useState(filterDropDown[0].name);

  const filterBucketsDropDown = [
    { name: "Fashion trends 2022 sketch", href: "#" },
    { name: "Fashion bucket", href: "#" },
    { name: "Cooking receipes", href: "#" },
    { name: "Inspirations", href: "#" },
  ];

  const [settagsFiltersText] = useState(filterBucketsDropDown[0].name);

  const handleFilterText = (text) => {
    setbucketFiltersText(text);
  };

  const handleFilterTagText = (text) => {
    settagsFiltersText(text);
  };

  function closeCardModal() {
    // setSelectedItems([]);
    setIsOpens(false);
  }

  const handleClearBucket = (e) => {
    e.stopPropagation();
    console.log("clear bcuket filter");
    setFilterSelectedBucket("");
    setFilterBucket("");
  };
  const handleClearTag = (e) => {
    e.stopPropagation();
    console.log("clear tags filter");
    setFiltertag("");
    setFilterTagId("");
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      {isLoadings ? (
        <div className="App-loading">
          <div className="App-logo" title="Zimzee" />
        </div>
      ) : (
        <div
          className="bookmarksGrid-wholesection"
          onClick={() => {
            setMenuBucket(false);
            setIsFilterTagOpen(false);
          }}
        >
          <div className="bucketHeader">
            <div className="grid grid-cols">
              <div className="bucketFeature col-span-2">
                <div className="flex items-center">
                  <h2 className="text-xl mybuckets-content dark:text-[#fff]">
                    Bookmarks
                  </h2>

                  {/* <div className="subbucketheaderdesktop-section">
                    <button
                      style={{
                        filter:
                          "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                      }}
                      className="buttonpadding-content mx-8 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]"
                      onClick={handleBookmarksfilesPopup}
                    >
                      <FontAwesomeIcon
                        className="pl-[23px] pr-[17.73px]"
                        icon={fontIcons.faPlus}
                      />
                      <span className="createbutton-context">Add</span>
                    </button>
                  </div> */}
                  <div className="subbucketheadermbl-section">
                    <button
                      style={{
                        filter:
                          "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                      }}
                      className="buttonpadding-content mx-2 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]"
                      onClick={handleBookmarksfilesPopup}
                    >
                      <FontAwesomeIcon
                        className="pl-[23px] pr-[17.73px]"
                        icon={fontIcons.faPlus}
                      />
                      <span className="createbutton-context">Add</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />

          {bookmarksfilespopup && (
            <div
              className="bookmarksfilesuploadpopup relative !z-[999]"
              style={{ height: "100%" }}
              onClick={closeBookmarksfilesPopup}
            >
              <div
                className="bookmarkssubfilesupload-section"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsBucketOpen(false);
                  setIsTagOpen(false);
                }}
              >
                <div className="fileupload-section flex">
                  <div className="fileupload-content">Add Bookmark</div>
                  <div
                    className="dismissSubfiles-content"
                    onClick={closeBookmarksfilesPopup}
                  >
                    <img src={DismissButton} alt="dismissButton" />
                  </div>
                </div>
                <hr />
                <div className="uploadtext-content">
                  Enter url to save website
                </div>
                <div className="flex items-center rounded-3xl border-0 menusubitems-content">
                  <input
                    type="text"
                    placeholder="https://example.com"
                    className="filespopupbookmarkbg-content"
                    value={bookmarkUrl}
                    onChange={handleChangeUrl}
                    autoFocus
                  />
                </div>
                <div className="flex bg-[#f5f5f5] items-center rounded-3xl border-0 menusubbucketitems-content">
                  <div className="w-full">
                    <strong className="createdsubtext-content pr-4">
                      <div
                        className="flex fliesbucket-inputcontent"
                        onClick={handleBucketOpen}
                      >
                        <input
                          type="text"
                          placeholder="Select a bucket"
                          className="filespopupbg-content"
                          value={searchBucket}
                          onChange={handleBucketInputChange}
                        />
                        <div className="dropdownfilespopup-content">
                          <FontAwesomeIcon
                            style={{ fontSize: "12px" }}
                            icon={faChevronDown}
                          />
                        </div>
                        <div className="tab-scrolls filescard">
                          {isBucketOpen ? (
                            <ul className="dropdown">
                              {filteredBuckets?.map((option) => (
                                <li key={option._id}>
                                  <p onClick={() => handleBucketChange(option)}>
                                    {option.name}
                                    {option.subBuckets &&
                                    option.subBuckets.length > 0 ? (
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "12px",
                                          color: "#707070",
                                        }}
                                      />
                                    ) : null}
                                  </p>
                                  {option.subBuckets &&
                                  option.subBuckets.length > 0 ? (
                                    <ul className="sub-dropdowns ">
                                      {option.subBuckets.map((subTag) => (
                                        <li key={subTag._id}>
                                          <p
                                            onClick={() =>
                                              handleSubBucketSelectChange(
                                                subTag
                                              )
                                            }
                                          >
                                            -- {subTag.name}
                                          </p>
                                        </li>
                                      ))}
                                    </ul>
                                  ) : null}
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </div>
                      </div>
                    </strong>
                  </div>
                  {/* <div><FontAwesomeIcon className="iconimg-content" style={{ fontSize: '12px', color: '#707070' }} icon={faChevronDown} /></div> */}
                </div>
                <div className="pos-tagsdropdown">
                  <div
                    className="flex items-center rounded-3xl border-0 menusubitems-content"
                    onClick={handleFocus}
                  >
                    <div className="w-full">
                      <div className="flex bookmarks-tags">
                        <TagsInput
                          value={tags}
                          onChange={handleTagsChange}
                          inputValue={inputValueSet}
                          onChangeInput={(e) => handleSetInputValue(e)}
                        />
                        <FontAwesomeIcon
                          className="fileicon-content"
                          style={{}}
                          icon={faChevronDown}
                          onClick={handleFocus}
                        />
                      </div>

                      {/* <button onClick={handleAddFromDropdown}>Add Tag from Dropdown</button> */}
                    </div>
                  </div>
                  {isTagOpen && filteredOptions.length ? (
                    <div className="pos-dropdown">
                      {/* <input
                        type="text"
                        placeholder="Search a tag"
                        value={searchTerm}
                        onChange={handleInputChange}
                        
                      /> */}

                      <nav>
                        <div className="tab-scrolls tagscardbookmark ">
                          <ul className="dropdown">
                            {filteredOptions.map((option) => (
                              <li
                                key={option._id}
                                onMouseEnter={() => handleMouseEnter(option)}
                                onMouseLeave={handleMouseLeave}
                              >
                                <p onClick={() => handleSelectChange(option)}>
                                  {option.name}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </nav>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="border border-[#e5e5e5] bg-[#f5f5f5] dark:border-[#dedede] border-1 rounded-md overflow-hidden"
                  onClick={handleClick}
                  style={{
                    resize: "horizontal",
                    height: "200px",
                    marginBottom: "30px",
                  }}
                >
                  {(isEditorFocused == false && !notes) ||
                  (isEditorFocused == false && notes == "<p><br></p>") ? (
                    <div
                      className="addnote-text"
                      style={{ paddingLeft: "14px", marginTop: "10px" }}
                    >
                      <p>Add Notes</p>
                    </div>
                  ) : (
                    ""
                  )}
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    value={notes}
                    onChange={handleProcedureContentChange}
                    onFocus={handleEditorFocus}
                    onClick={handleEditorClick}
                    onBlur={handleEditorBlur}
                    onKeyUp={handleKeyUp}
                    onPaste={handlePaste}
                  />
                  {isEditorFocused && (
                    <div
                      className="backgroundcard-rightcontent bg-[#F7F7F7] px-3 py-4 flex items-center"
                      onClick={handleClick}
                    >
                      {notes}
                    </div>
                  )}

                  {/* <input
type="text"
className="createdsubtext-content pr-4"
value={notes}
onChange={handleAddNotes}
/> */}
                </div>
                <div className="flex justify-end lastfile-section">
                  <div
                    className="cancel-content"
                    onClick={closeBookmarksfilesPopup}
                    style={{ cursor: "pointer" }}
                  >
                    Cancel
                  </div>
                  <button
                    style={{
                      filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                    }}
                    className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white"
                  >
                    <span
                      className="savebutton-context"
                      onClick={handleAddBookmarks}
                    >
                      Save Bookmark
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="subbucketheaderdesktop-section">
            <div className="cards-filter mt-[18px] flex justify-between">
              <div className="flex items-center">
                <div className="card-count flex items-center">
                  <h4 className="cardssubBuckets-content">Bookmarks</h4>
                  <span className="subbucketsbg-content background-primary text-white rounded-full ml-4 text-[16px]">
                    {bookmarkCards?.length}
                  </span>
                </div>
                {isListBookmarksViewOpen ?<div className="mr-6"></div>:<>
                <Menu as="div" className="relative px-2">
                  <Menu.Button className="relative ml-3 inline-flex items-center px-1 py-2 text-sm font-semibold text-gray-900 ">
                    {/* <FontAwesomeIcon className="text-lg text-[#707070]" icon={faEllipsisVertical} /> */}
                    <img
                      className="threedots-content"
                      src={ThreeDots}
                      alt="threeDots"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {bucketNav.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={`javascript:void(0);`}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-sm text-gray-700"
                              )}
                              onClick={() => {
                                handleCardClick(item.name);
                              }}
                            >
                              <span className="pr-2">
                                <FontAwesomeIcon
                                  className="text-[#797979]"
                                  icon={item.icon}
                                />
                              </span>
                              <span className="text-black">{item.name}</span>
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              
                {selectedItems.length > 0 ?
                <img
                src={appTheme==="light"?DeleteIcon:LightDelete}
                alt="deleteIcon"
                className="px-3"
                onClick={() => setIsOpens(true)}
              />
              :
              <img
              src={appTheme==="light"?LightDelete:DeleteIcon}
              alt="deleteIcon"
              className="px-3"
              // onClick={() => deleteCard()}
            />
              }
              </>}
                <div className="flex items-center rounded-3xl border-0 menusubbucketitems-content bucketspacefile-content">
                  <div className="w-full">
                    <strong className="createdsubtext-content pr-4">
                      <div
                        className="flex fliesbucket-inputcontent"
                        onClick={handleFilterBucketOpen}
                      >
                        <input
                          type="text"
                          placeholder="Buckets"
                          className="filespopupbg-content"
                          value={filterBucket}
                          onChange={handleFilterBucketInputChange}
                          readOnly
                        />
                        <div className="dropdownfilespopup-content">
                          {filterBucket?<FontAwesomeIcon
                            style={{ fontSize: "12px" }}
                            icon={fontIcons.faXmark}
                            onClick={handleClearBucket}
                          />:<FontAwesomeIcon
                            style={{ fontSize: "12px" }}
                            icon={faChevronDown}
                          />}
                        </div>
                        <div className="tab-scrolls filescard">
                          {isMenuBucket ? (
                            <ul className="dropdown">
                              {/* <li
                                style={{ textAlign: "right", fontSize: "12px" }}
                                onClick={handleClearBucket}
                              >
                                clear
                              </li> */}
                              {buckets?.map((option) => (
                                <li key={option._id}>
                                  <p
                                    onClick={() =>
                                      handleFilterBucketChange(option)
                                    }
                                  >
                                    {option.name}
                                    {option.subBuckets &&
                                    option.subBuckets.length > 0 ? (
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "12px",
                                          color: "#707070",
                                        }}
                                      />
                                    ) : null}
                                  </p>
                                  {option.subBuckets &&
                                  option.subBuckets.length > 0 ? (
                                    <ul className="sub-dropdowns ">
                                      {option.subBuckets.map((subTag) => (
                                        <li key={subTag._id}>
                                          <p
                                            onClick={() =>
                                              handleFilterSubBucketSelectChange(
                                                subTag
                                              )
                                            }
                                          >
                                            -- {subTag.name}
                                          </p>
                                        </li>
                                      ))}
                                    </ul>
                                  ) : null}
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </div>
                      </div>
                    </strong>
                  </div>

                  {/* <div><FontAwesomeIcon className="iconimg-content" style={{ fontSize: '12px', color: '#707070' }} icon={faChevronDown} /></div> */}
                </div>
                <div className="flex items-center rounded-3xl border-0 menusubbucketitems-content">
                  <div className="w-full">
                    <strong className="createdsubtext-content pr-4">
                      <div
                        className="flex fliesbucket-inputcontent"
                        onClick={handleTagFocus}
                      >
                        <input
                          type="text"
                          placeholder="Tags"
                          className="filespopupbg-content"
                          value={filterTag}
                          onChange={handleFilterTagInputChange}
                          readOnly
                        />
                        <div className="dropdownfilespopup-content">
                          {filterTag?<FontAwesomeIcon
                            style={{ fontSize: "12px" }}
                            icon={fontIcons.faXmark}
                            onClick={handleClearTag}
                          />:<FontAwesomeIcon
                          style={{ fontSize: "12px" }}
                          icon={faChevronDown}
                        />}
                         
                        </div>
                        <div className="tab-scrolls filescard">
                          {IsFilterTagOpen ? (
                            <ul className="dropdown">
                              {/* <li
                                style={{ textAlign: "right", fontSize: "12px" }}
                                onClick={handleClearTag}
                              >
                                clear
                              </li> */}
                              {tagOptions?.map((option) => (
                                <li key={option._id}>
                                  <p
                                    onClick={(e) =>
                                      handleFilterSelectChange(e, option)
                                    }
                                  >
                                    {option.name}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </div>
                      </div>
                    </strong>
                  </div>

                  {/* <div><FontAwesomeIcon className="iconimg-content" style={{ fontSize: '12px', color: '#707070' }} icon={faChevronDown} /></div> */}
                </div>
                {BookmarkSharePopup && (
                  <div
                    className="filesuploadpopup relative !z-[999]"
                    style={{ height: "100%" }}
                    onClick={closeSharePop}
                  >
                    <div
                      className="subinviteupload-section"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="teaminvitesection flex">
                        <div className="teammembers-content">Share</div>
                        <div
                          className="dismissSubfiles-content"
                          onClick={closeSharePop}
                        >
                          <img src={BlackDismiss} alt="dismissButton" />
                        </div>
                      </div>
                      <ShareCard
                        shareBucketId={ShareBookmarkId}
                        closeSharePop={closeSharePop}
                        cardsRefresh={cardsRefresh}
                      />
                    </div>
                  </div>
                )}
                <Transition appear show={isOpenss} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={closeCardModal}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              Delete
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to delete?
                              </p>
                            </div>

                            <div className="mt-4">
                              <button
                                style={{
                                  background: "#FF6600",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={handleDeleteCard}
                              >
                                Yes
                              </button>
                              <button
                                style={{
                                  background: "#000",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={closeCardModal}
                              >
                                No
                              </button>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
                {/* <FontAwesomeIcon className="transform rotate-45 px-3" icon={faArrowUp } /> */}
              </div>
              <div className="flex">
                <div className="flex items-center justify-end">
                  <div className="filter display-content">
                    {isListBookmarksView && (
                      <div onClick={handleIsListBookmarksView}>
                        <a className="viewGrid" href="#">
                          <img
                            className="mt-1 listcard-imgcontent"
                            src={ListView}
                            style={{ color: "#000000" }}
                            alt="listView"
                          />
                        </a>
                      </div>
                    )}
                    {isListBookmarksViewIcon && (
                      <div onClick={handleIsListBookmarksViewIcon}>
                        <a className="viewGrid" href="#">
                          <img
                            className="mt-1 listcard-imgcontent"
                            src={ListviewIcon}
                            alt="listviewIcon "
                          />
                        </a>
                      </div>
                    )}
                    {isGridBookmarksView && (
                      <div onClick={handleIsGridBookmarksView}>
                        <a className="viewGrid" href="#">
                          <img
                            className="mt-1 compact-imgcontent"
                            src={compactCardIcon}
                            alt="compactIcon"
                          />
                        </a>
                      </div>
                    )}
                    {isGridBookmarksViewIcon && (
                      <div onClick={handleIsGridBookmarksViewIcon}>
                        <a className="viewGrid" href="#">
                          <img
                            className="mt-1 compact-imgcontent"
                            src={GridviewIcon}
                            alt="compactIcon"
                          />
                        </a>
                      </div>
                    )}
                  </div>

                  <span className="sorttext-content mx-3 dark:text-[#acacac]">
                    Sort By
                  </span>
                  <Menu as="div" className="relative flex-shrink-0">
                    <div>
                      <Menu.Button className="flex rounded-full focus:outline-none ">
                        <a
                          href="#"
                          className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                        >
                          <strong className="createdtext-content pr-4">
                            {FiltersSelectText}
                          </strong>
                          <FontAwesomeIcon
                            style={{ fontSize: "12px", color: "#707070" }}
                            icon={faChevronDown}
                          />
                        </a>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {filterFilesDropDown.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                onClick={() => {
                                  handleSortFilterText(item.name);
                                }}
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
          <div className="subbucketheadermbl-section ">
            <div className="cards-filter mt-[18px] ml-[10px] mr-[18px] flex justify-between">
              <div className="flex items-center">
                <div className="card-count flex items-center">
                  <h4 className="cardssubBuckets-content">Bookmarks</h4>
                  <span className="subbucketsbg-content background-primary text-white rounded-full ml-4 text-[16px]">
                    6
                  </span>
                </div>
                <Menu as="div" className="relative px-2">
                  <Menu.Button className="relative inline-flex items-center px-1 py-2 text-sm font-semibold text-gray-900 ">
                    {/* <FontAwesomeIcon className="text-lg text-[#707070]" icon={faEllipsisVertical} /> */}
                    <img
                      className="threedots-content"
                      src={ThreeDots}
                      alt="threeDots"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {bucketNav.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={`javascript:void(0);`}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-sm text-gray-700"
                              )}
                            >
                              <span className="pr-2">
                                <FontAwesomeIcon
                                  className="text-[#797979]"
                                  icon={item.icon}
                                />
                              </span>
                              <span className="text-black">{item.name}</span>
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
                <img src={DeleteIcon} alt="deleteIcon" className="px-3" />
                {/* <FontAwesomeIcon className="transform rotate-45 px-3" icon={faArrowUp } /> */}
              </div>
              <div className="flex">
                <div className="flex items-center justify-end">
                  <div className="filter display-content">
                    {/* {isListBookmarksView &&
                        <div onClick={handleIsListBookmarksView}>
                        <a className="viewGrid" href="#">                            
                            <img className="mt-1 mr-3" src={ListView} style={{color:'#000000'}}  alt="listView" />
                        </a>
                        </div>
                    }
                    {isListBookmarksViewIcon &&
                      <div onClick={handleIsListBookmarksViewIcon}> 
                        <a className="viewGrid" href="#">                           
                        <img className="mt-1 mr-3" src={ListviewIcon } alt="listviewIcon " />
                        </a>
                      </div>
                       
                    } */}
                    {/* {isGridBookmarksView &&
                        <div onClick={handleIsGridBookmarksView}>
                        <a className="viewGrid" href="#">                            
                            <img className="mt-1 mr-3 compact-imgcontent" src={compactCardIcon} alt="compactIcon" />
                        </a>
                        </div>
                    }
                    {isGridBookmarksViewIcon &&
                        <div onClick={handleIsGridBookmarksViewIcon}>
                        <a className="viewGrid" href="#">                            
                            <img className="mt-1 mr-3 compact-imgcontent" src={GridviewIcon} alt="compactIcon" />
                        </a>
                        </div>
                    } */}
                  </div>

                  <div>
                    <Menu
                      as="div"
                      className="sortmbl-content relative flex-shrink-0"
                    >
                      <div>
                        <Menu.Button className="flex rounded-full dark:text-[#acacac] focus:outline-none">
                          <a
                            href="#"
                            className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500 "
                          >
                            <img src={SortMbl} alt="SortMbl" />
                          </a>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="filter-dropdown dropdownmbl-content absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {filterDropDown.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  onClick={() => {
                                    handleFilterText(item.name);
                                  }}
                                  href={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block py-2 px-4 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isListBookmarksViewOpen && (
            <div>
              <BookmarkList
                bookmarkCards={bookmarkCards}
                cardsRefresh={cardsRefresh}
                handleSharePop={handleSharePop}
              />
            </div>
          )}
          {/* <div className="mansorydesktop-section"> */}

          {isGridBookmarksViewOpen && (
            <div className="filescard-section flex flex-wrap">
              {bookmarkCards.map((card) => {
                const sharedWithObjects = card?.shared
                ? card.shared
                  .filter(shares => shares.sharedWith != null)
                  .map(shares => shares.sharedWith)
                : [];
                return (
                  <>
                    <SingleBook
                      cardsRefresh={cardsRefresh}
                      card={card._id}
                      note={card.note}
                      tags={card?.tags}
                      sharedBy={[]}
                      sharedUsers={sharedWithObjects}
                      starred={card.starred}
                      cardId={card._id}
                      title={card.title}
                      content={card.content}
                      handleSharePop={handleSharePop}
                      updated={card.createdAt}
                      buckets={buckets}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                  </>
                );
              })}
            </div>
          )}
        </div>
      )}
      <ToastContainer />
    </>
  );
}
export default BookmarksGrid;
