import { React, useEffect, useState } from "react";
import "../Bucket.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import EmojiPicker, {
  EmojiClickData,
  Emoji,
  EmojiStyle,
} from "emoji-picker-react";
import {
  faCoffee,
  faStar,
  faHeart,
  faCircle,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import Color from "../../assets/icons/Color.svg";
import search from "../../assets/icons/Color selector.svg";
import Image from "../../assets/icons/image (1) 1.svg";
import Icon from "../../assets/icons/shapes 1.svg";
import Face from "../../assets/icons/happy (1) 1.svg";
import image1 from "../../assets/images/Rectangle 2996.png";
import image2 from "../../assets/images/Rectangle 2997.png";
import image3 from "../../assets/images/Rectangle 2998.png";
import image4 from "../../assets/images/Rectangle 2999.png";
import image5 from "../../assets/images/Rectangle 3000.png";
import image6 from "../../assets/images/Rectangle 3001.png";
import image7 from "../../assets/images/Rectangle 3002.png";
import image8 from "../../assets/images/Rectangle 3003.png";
import image9 from "../../assets/images/Rectangle 3005.png";
import image10 from "../../assets/images/Rectangle 3006.png";
import shareIcon from "../../assets/icons/bucketShareIcon.svg";
import unpinIcon from "../../assets/icons/Pin.svg";
import ThreeDots from "../../assets/icons/threeDots.svg";
import Profile1 from "../../assets/icons/profile1.png";
import Profile2 from "../../assets/icons/profile2.png";
import Profile3 from "../../assets/icons/profile3.png";
import Close from "../../assets/icons/Vector (6).svg";
import {
  faFolder as fasFaFolder,
  faFile as fasFaFile,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Frame from "../../assets/icons/Frame.png";
import { SwatchesPicker } from "react-color";
import CustomizeClose from "../../assets/icons/customizeclose.svg";

function PopupFinal({
  selectedBucketName,
  selectedBucketId,
  closeCustomPopup,
  bucketUpdate,
}) {
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [background, setBackground] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageLibrary, setImageLibrary] = useState([]);
  const [RefreshImgLibrary, setRefreshImgLibrary] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [RecentColors, setRecentColors] = useState([]);
  const importAll = (r) => { 
    return r.keys().map(r);
  };

  const allData = importAll(
    require.context("../../assets/icons/Custom-Bucket-Icons", false, /\.svg$/)
  );
  useEffect(() => {
    // api url

    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API}buckets/user-library-images`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setImageLibrary(response?.data?.data);
        console.log(response);
        setRefreshImgLibrary(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [RefreshImgLibrary]);
  useEffect(() => {
    console.log(selectedEmoji, "selected emojiiiiiiiii");
    console.log(selectedIcon, "selected iconnnnnnnnnnnnnnnnnnn");
    console.log(background, "selected colorrrrrrrrr");
  }, [selectedEmoji, selectedIcon, background]);
  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API}buckets/single/${selectedBucketId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log(response);
        setRecentColors(response?.data?.data?.bucketDesignId?.recentColors);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const combinedArray = RecentColors
    ? RecentColors.reduce((acc, subArray) => acc.concat(subArray), [])
    : [];

  // Sort the combined array based on the "addedAt" property
  const sortedColors = combinedArray.sort(
    (a, b) => new Date(b.addedAt) - new Date(a.addedAt)
  );
  // const sortedColors =RecentColors ? [...RecentColors].sort((a, b) => {
  //   return new Date(b.addedAt) - new Date(a.addedAt);
  // }):[]

  //  const RecentColors=[
  //   {name:"color1",hex:"#9a3412"},
  //   {name:"color2",hex:"#fef3c7"},
  //   {name:"color3",hex:"#5eead4"},
  //   {name:"color4",hex:"#c4b5fd"},

  //  ]
  const sampleImages = [
    {
      name: "image1",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900032047_1.svg",
    },
    {
      name: "image2",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900177257_Rectangle%203013.svg",
    },
    {
      name: "image3",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900216711_2.svg",
    },
    {
      name: "image4",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900280540_3.svg",
    },
    {
      name: "image5",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900311752_4.svg",
    },
    {
      name: "image6",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900348180_5.svg",
    },
    {
      name: "image7",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900370715_6.svg",
    },
    {
      name: "image8",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900397406_7.svg",
    },
    {
      name: "image9",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900422324_8.svg",
    },
    {
      name: "image10",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694417289921_Rectangle%203011.svg",
    },
    {
      name: "image11",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900492957_Rectangle%202996.svg",
    },
    {
      name: "image13",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900664866_Rectangle%202997.svg",
    },
    {
      name: "image14",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900723838_Rectangle%202999.svg",
    },
    {
      name: "image15",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900753372_Rectangle%203001.svg",
    },
    {
      name: "image16",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900784290_Rectangle%203002.svg",
    },
    {
      name: "image17",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900833872_Rectangle%203004.svg",
    },
    {
      name: "image18",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1693900859280_Rectangle%203006.svg",
    },
    {
      name: "image19",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694417031940_Rectangle%203008.svg",
    },
    {
      name: "image20",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694005336568_vertical-shot-sea-with-ferries-wheel-distance-blue-sky%201.svg",
    },
    {
      name: "image21",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694005478561_victoria-harbor-hong-kong.svg",
    },
    {
      name: "image22",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694005555944_wide-angle-shot-boat-top-ocean-clear-sunny-sky.svg",
    },
    {
      name: "image23",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694005618589_sailing-yacht-gliding-blue-waves-smoothly-generative-ai.svg",
    },
    {
      name: "image24",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694005664004_skyscrapers-from-low-angle-view%201.svg",
    },
    {
      name: "image25",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694005721261_travel-sunrise-idyllic-relax-natural.svg",
    },
    {
      name: "image26",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694006218243_large-luxurious-cruise-ship-sailing-sea-sunny-day-ai-generative.svg",
    },
    {
      name: "image27",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694006377321_low-view-modern-skyscrapers-office-buildings.svg",
    },
    {
      name: "image28",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694006416909_nature-holiday-spring-river-sunset.svg",
    },
    {
      name: "image29",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694006459117_office-skyscrapers-business-district.svg",
    },
    {
      name: "image30",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694006493283_pelican-swimming-lake-golden-cloudy-sky-sunset.svg",
    },
    {
      name: "image31",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694006691292_ferris-wheel-amusement-park-around-yokohama-city.svg",
    },
    {
      name: "image32",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694006734213_fishing-boats-water-sea-with-beautiful-clear-blue-sky.svg",
    },
    {
      name: "image33",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694006866740_high-angle-shot-wooden-deck-seashore-leading-sea-sunset.svg",
    },
    {
      name: "image34",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694007120179_3d-cruise-ship-tropical-beach-paradise-samoa.svg",
    },
    {
      name: "image35",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694007230692_beach-with-palm-trees-sun-setting.svg",
    },
    {
      name: "image36",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694007272273_beautiful-landscape-shot-sailboats-sea-pink-sky.svg",
    },
    {
      name: "image37",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694007304440_beautiful-shot-lake-pukaki-mount-cook-new-zealand.svg",
    },
    {
      name: "image38",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694007379123_boat-tropical-river-with-blue-sky.svg",
    },
    {
      name: "image39",
      s3Url:
        "https://zimzee.s3.us-east-1.amazonaws.com/uploads/1694007421594_cruise.svg",
    },
  ];
  const combinedImages = [
    ...imageLibrary.map((img) => ({ ...img, isLibraryImage: true })),
    ...sampleImages,
  ];
  const handleChangeComplete = (color) => {
    console.log(color.hex, "selected colorrrr");
    // setBackground(color.hex);
    setSelectedColor({
      type: "color",
      value: color.hex,
    });
  };
  const handleSearchColor = (e) => {
    console.log(e.target.value, "selected color");
    const searchColor = e.target.value;
    if (searchColor === "#" || searchColor.length < 4) {
      // Handle the case when only '#' is typed
      setSelectedColor(null);
    } else {
      setSelectedColor({
        type: "color",
        value: searchColor,
      });
    }

    if (searchColor === "") {
      setSelectedColor(null);
    }
  };
  const SaveCustomBucket = () => {
    const token = localStorage.getItem("token");
    console.log(selectedColor, "api color selection");
    let bucketBackground = background ? background : selectedColor;
    let BackgroundBucket =
      bucketBackground === null
        ? { type: "color", value: "#74CFFB" }
        : bucketBackground;
    let foreground = [];
    if (selectedEmoji) {
      foreground = [...foreground, { type: "emoji", value: selectedEmoji }];
    }
    if (selectedIcon) {
      foreground = [
        ...foreground,
        {
          type: "icon",
          value: {
            selectedIcon,
            selectedColor: selectedColor ?? { type: "color", value: "#74CFFB" },
          },
        },
      ];
    }

    axios
      .patch(
        `${process.env.REACT_APP_API}buckets/update`,
        {
          bucketId: selectedBucketId,
          background: BackgroundBucket,
          foregroundItems: foreground,
          applyToAll: isChecked,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        closeCustomPopup();
        bucketUpdate();
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleColorChange = (event) => {
    const newColor = event.target.value;
    setSelectedColor({
      type: "color",
      value: newColor,
    });
  };

  const handleImageSelect = async (e) => {
    const file = e.target.files[0];

    console.log(file, "selected fileeeeeeee");
    if (file) {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("imageFile", file);
      try {
        const response = await axios.post(
          "https://api.zimzee.com/api/buckets/upload-library-image",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setRefreshImgLibrary(true);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handleDeleteImage = (url, id) => {
    const token = localStorage.getItem("token");
    console.log(id, "url ND ID");
    axios
      .delete(
        `${process.env.REACT_APP_API}buckets/delete-user-library-image`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },

          data: {
            url: url,
            imageId: id,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        setRefreshImgLibrary(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const iconList = [
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    // Add more icons here
  ];

  const sampleImg = [
    { url: image1 },
    { url: image2 },
    { url: image3 },
    { url: image4 },
    { url: image5 },
    { url: image6 },
    { url: image7 },
    { url: image8 },
    { url: image9 },
    { url: image10 },
  ];
  const handleEmojiSelect = (emojiData) => {
    console.log(emojiData.unified, "unified emojiiiii");
    setSelectedEmoji(emojiData.unified);
  };

  const handleImageClick = (img) => {
    setBackground({
      type: "image",
      value: img,
    });
  };
  const handleIconSelect = (icon) => {
    console.log(icon, "sel icon icon");
    setSelectedIcon(icon);
  };

  return (
    <div className="popup-upload flex customize">
      <div className="w-[60%] dark:bg-[#0A1D42] left">
        <h3 className="px-[20px] pt-[20px]">Customize Bucket</h3>
        <Tabs>
          <TabList className="justify-between px-[20px]">
            <Tab>
              <img src={Color} alt="color" /> Color
            </Tab>
            <Tab>
              <img src={Image} alt="color" /> Images
            </Tab>
            <Tab>
              <img src={Icon} alt="color" /> Icons
            </Tab>
            <Tab>
              <img src={Face} alt="color" />
              Emojis
            </Tab>
          </TabList>
          <TabPanel>
            <div className="flex justify-between pb-[5px]">
              <div>
                <div className="searchwhole-content flex item-center ">
                  <div className="w-full">
                    <label
                      htmlFor="search"
                      className="dark:border-white-700 sr-only"
                    >
                      Search
                    </label>
                    <div className="relative">
                      <input
                        id="search"
                        value={
                          background?.type === "image"
                            ? null
                            : selectedColor?.value
                        }
                        name="search"
                        className="dark:bg-[#19296e] dark:text-white search-input block w-full rounded-3xl border-0 bg-[#F5F5F5] py-2.5 pr-3 text-gray-900 placeholder:text-gray-400 focus:outline-none m:text-sm sm:leading-6"
                        placeholder="eg:#000000"
                        type="text"
                        onChange={handleSearchColor}
                      />
                      <div className="input-color-picker">
                        <label className="flex">
                          <img
                            className="magnifier"
                            src={search}
                            alt="magnifying-glass"
                          />
                          <input
                            type="color"
                            className="d-none"
                            name="favcolor"
                            onChange={handleColorChange}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recent-color flex">
                <p className="pr-[8px]">Recently Used Colors</p>
                {RecentColors &&
                  RecentColors?.map((color) => {
                    return (
                      <div
                        style={{
                          background: `${color.color}`,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedColor({
                            type: "color",
                            value: color.color,
                          });
                        }}
                      ></div>
                    );
                  })}
              </div>
            </div>
            <div className="color-upload">
              <div className="colors">
                <div>
                  <SwatchesPicker onChangeComplete={handleChangeComplete} />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="bgimage-upload">
              <div className="grid">
                <div className="image-upload">
                  <label
                    for="file-input"
                    class="custom-button"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      className="pt-[10px] text-center"
                      src={Frame}
                      alt="frame-content"
                    />
                    <h6 className="my-[3px]">Select Files</h6>
                    <p className="mb-[20px]">Recommended image size 150x80</p>
                  </label>
                  <input
                    type="file"
                    id="file-input"
                    style={{ display: "none" }}
                    onChange={handleImageSelect}
                  />
                </div>
                {combinedImages.map((image, index) => (
                  <div key={index}>
                    <div className="flex">
                      <img
                        src={image.s3Url}
                        alt={image.name}
                        className="imageupload-content"
                        onClick={() => handleImageClick(image.s3Url)}
                      />
                      {image.isLibraryImage && (
                        <img
                          className="customizepopup-content"
                          src={CustomizeClose}
                          alt="customizeClose-content"
                          onClick={() =>
                            handleDeleteImage(image.s3Url, image._id)
                          }
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="icon-upload">
              <div className="grid">
                {allData.map((item, index) => (
                  <div
                    key={index}
                    className={`icon-item ${
                      selectedIcon === item ? "selected" : ""
                    }`}
                    onClick={() => handleIconSelect(item)}
                  >
                    <img src={item} className="customizeicon-bucket" />
                    {/* <FontAwesomeIcon className="customizeicon-bucket" icon={item.icon} color="gray"/>  */}
                  </div>
                ))}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="emojis">
              <EmojiPicker onEmojiClick={handleEmojiSelect} />
            </div>
          </TabPanel>
        </Tabs>
        <div className="save-name flex justify-between">
          <p className="applysettings-content">
            <input
              id="link-checkbox"
              type="checkbox"
              checked={isChecked}
              onChange={(e) => {
                setIsChecked(e.target.checked);
              }}
              className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            Apply this setting to all my buckets.
          </p>
          <button onClick={SaveCustomBucket}>Save</button>
          {/* <button >Save</button> */}
        </div>
      </div>
      <div className="w-[40%] right bg-[#FFF6F0] dark:bg-[#112750]">
        <h3>Preview</h3>
        {/* <p className="filecustom-content mt-2">File uploads</p>  */}

        <div className="card">
          <div className="flex">
            <div>
              <img className="dark-icon-clr" src={unpinIcon} alt="pattern" />
            </div>
            <div className="ml-[auto] flex">
              <img
                className="pr-[10px] dark-icon-clr"
                src={shareIcon}
                alt="pattern"
              />
              <img className="dark-icon-clr" src={ThreeDots} alt="pattern" />
            </div>
          </div>

          <a href="javascript:void(0);">
            <div
              className="gradient-wholebackground rounded-lg cardlist-content"
              style={{
                background:
                  background && background?.type === "image"
                    ? `url(${background?.value})`
                    : selectedColor?.value,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "auto",
              }}
            >
              <div>
                <p className="popuptext-background">{selectedBucketName}</p>
              </div>
              <div className="flex justify-center icon-emoji-collage">
                <span className="mr-[10px] emoji-icon-inner">
                  <Emoji
                    unified={selectedEmoji}
                    emojiStyle={EmojiStyle.APPLE}
                  />
                </span>
                <span
                  className="svg-icon-inner"
                  style={{
                    fontSize: "33px",
                    width: background && background?.type === "image" ?"36px":"40px",
                    background: selectedIcon
                      ? selectedColor?.value
                        ? selectedColor?.value
                        : "#74CFFB"
                      : "transparent",
                  }}
                >
                  <img src={selectedIcon} />
                </span>
              </div>
            </div>
          </a>
          {/* <div className="flex">
            <div className="shareCount col-span-2">
              <div className="profile flex"> 
                <img src={Profile1} alt="pattern" />
                <img src={Profile2} alt="pattern" />
                <img src={Profile3} alt="pattern" />
              </div>
              <span className="share-count">3+ Shares</span>
            </div>
            <div className="relative px-3 ml-[auto]">
              <FontAwesomeIcon className="text-xl text-[#9D9D9D]" icon={fasFaFile} />
              <span className="card-textcontent absolute right-0 bottom-0 mb-0 text-white bg-[rgba(0,0,0,0.7)] group-hover:bg-gray-200 inline-block rounded-full py-0.5 text-xs font-medium w-5 h-5 flex justify-center items-center">4</span>
            </div>                      
          </div> */}
          <div className="innerBucketDetails grid grid-cols-3 pt-[4px]">
            <div className="shareCount col-span-2">
              <div className="profile-imgcontent">
                <img src={Profile1} alt="profile1" />
                <img src={Profile2} alt="profile2" />
                <img src={Profile3} alt="profile3" />
              </div>
              <p className="share-content dark:text-[#d3d3d3]">3+ Share(s)</p>
            </div>
            <div className="bucketsinside flex justify-end">
              <div className="relative px-3">
                <FontAwesomeIcon
                  className="text-xl text-[#9D9D9D]"
                  icon={fasFaFolder}
                />
                <span className="card-textcontent cardtext-buttoncontent absolute right-0 bottom-0 mb-0 text-white bg-[rgba(0,0,0,0.7)] group-hover:bg-gray-200 inline-block rounded-full py-2 px-3 font-medium w-6 h-6 flex justify-center items-center">
                  4
                </span>
              </div>
              <div className="relative px-3">
                <FontAwesomeIcon
                  className="text-xl text-[#9D9D9D]"
                  icon={fasFaFile}
                />
                <span className="card-textcontent cardtext-buttoncontent absolute right-0 bottom-0 mb-0 text-white bg-[rgba(0,0,0,0.7)] group-hover:bg-gray-200 inline-block rounded-full py-2 px-3 font-medium w-6 h-6 flex justify-center items-center">
                  2
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="update-history">
          {background || selectedColor || selectedEmoji || selectedIcon ? (
            <h6 className="mt-[20px]">History</h6>
          ) : null}
          {background?.type === "image" ? (
            <>
              <div className="flex change-histoty mt-[12px]">
                <p>Background image Changed</p>
                <img
                  className="close-history"
                  src={Close}
                  alt="background-content"
                  onClick={() => {
                    setBackground(null);
                  }}
                />
              </div>
            </>
          ) : null}
          {selectedColor !== null ? (
            <>
              <div className="flex change-histoty mt-[18px]">
                <p>Changed Color to</p>{" "}
                <span style={{ background: `${selectedColor?.value}` }}></span>{" "}
                <img
                  className="close-history"
                  src={Close}
                  alt="close-button"
                  onClick={() => setSelectedColor(null)}
                />
              </div>
            </>
          ) : null}

          {selectedImage ? (
            <>
              <div className="flex change-histoty mt-[18px]">
                <p>Background image Changed</p>
                <img
                  className="close-history"
                  src={Close}
                  alt="close-button"
                  onClick={() => {
                    setSelectedImage("");
                  }}
                />{" "}
              </div>
            </>
          ) : null}

          {selectedIcon ? (
            <>
              {" "}
              <div className="flex change-histoty mt-[18px] icon-dark-space">
                <p>Next Icon added</p>{" "}
                <img className="selectedicon-content" src={selectedIcon} />{" "}
                <img
                  className="close-history"
                  src={Close}
                  alt="close-button"
                  onClick={() => {
                    setSelectedIcon(null);
                  }}
                />
              </div>
            </>
          ) : null}

          {selectedEmoji === null ? null : (
            <>
              <div className="flex change-histoty mt-[18px]">
                <p>Emoji added</p>
                <div className="selectedemoji-content">
                  <Emoji
                    unified={selectedEmoji}
                    emojiStyle={EmojiStyle.APPLE}
                  />
                </div>
                <img
                  className="close-history history-image"
                  src={Close}
                  alt="close-button"
                  onClick={() => {
                    setSelectedEmoji(null);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PopupFinal;
