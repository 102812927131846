import { React, Fragment, useState,useRef,useEffect } from "react";
// import './Files.scss'
// import '../Tags/Tags.scss'
import axios from "axios"; 
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNoteSticky, faPencil, faTrash, faFolder, faChevronDown,faChevronRight } from "@fortawesome/free-solid-svg-icons";
import * as fontIcons from '@fortawesome/free-solid-svg-icons';
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { Menu, Dialog, Transition } from '@headlessui/react';
import ThreeDots from '../../../assets/icons/threeDots.svg';
import DeleteIcon from '../../../assets/icons/delete.svg';
import ListView from '../../../assets/icons/listView.svg';
import { toastify } from '../../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import compactCardIcon from "../../../assets/icons/gridcard.svg";
import Document from "../../../assets/images/document.svg";
import ImageFolder from "../../../assets/images/imageFolder.svg";
import PDF from "../../../assets/images/pdf.svg";
import ClipBoard from '../../../assets/icons/subClipboard.svg';
import SubStar from '../../../assets/icons/subStar.svg';
import SubTag from '../../../assets/icons/subTag.svg';
import SubShare from '../../../assets/icons/subShare.svg';
import Profile1 from '../../../assets/icons/profile1.png';
import Profile2 from '../../../assets/icons/profile2.png';
import ProjectImg2 from "../../../assets/images/Projects/projectImg2.png";
import Profile3 from '../../../assets/icons/profile3.png';
import CardImage1 from "../../../assets/images/cardImage1.png";
import CardImage2 from "../../../assets/images/cardImage2.png";
import CardImage3 from "../../../assets/images/cardImage3.svg";
import linkIcon from "../../../assets/icons/linksicon.svg";
import UploadIcon from "../../../assets/icons/uploadIcon.svg";
import ListviewIcon from "../../../assets/icons/listBlackIcon.svg";
import GridviewIcon from "../../../assets/icons/gridgreyIcon.svg"; 
import DismissButton from "../../../assets/icons/dismissButton.svg";
import SortMbl from '../../../assets/icons/sortmbl.svg';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SingleFile from "../../../shades/cards/SingleFile";
import BlackDismiss from "../../../assets/icons/dismissButton.svg";
import ShareCard from "../../../shades/cards/shareCard";
import Quill from 'quill';
import FileDownload from '../../../assets/images/file-download.jpg'
// import axios from "axios";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

function RecentFiles({UpdateRecentCards}) {
 
  const [files, setFiles] = useState([]);
  const [filesData, setFilesData] = useState([]);
  const [buckets, setBuckets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fileUpdate,setFileUpdate]=useState(false)
  const [isLoadings, setIsLoadings] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const[RecentFile,setRecentFile]=useState([])
  const [FileSharePopup, setFileSharePopup] = useState(false);
  const [ShareFileId, setShareFileId] = useState("");
  const [ClickedCardId, setClickedCardId] = useState("");

  let cardFilterv = '';
  if (!localStorage.getItem("cardFilter")) {
    localStorage.setItem('cardFilter','Created_Date');
    cardFilterv = 'Created_Date';
  }else{
    cardFilterv = localStorage.getItem("cardFilter");
  }
  const [cardFilterDefault,setCardFilterDefault] = useState(cardFilterv);
  const allowedImageTypes = [
    "png",
    "jpg",
    "jpeg",
    "svg",
    "gif",
    "bmp",
    "webp",
    "tiff",
    "ico",
    "apng",
    "Image",
    "svg+xml",
    "x-icon",
    "zip"
    // Add more image types here if needed
  ];
  useEffect(() => {
    // Function to fetch data from the API and update the state
    const token = localStorage.getItem('token');
    const fetchBuckets = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}buckets/user-buckets`,{
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request header
          },
        }); // Replace this with the actual API endpoint
        setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, []);
  const fetchTags = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API}tags/list`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request header
        },
      });
      console.log(response?.data?.data?.name, "tags list");
      setTagOptions(response?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  useEffect(() => {
    fetchTags();
  }, []);
  useEffect(() => {
    // api url

    const token = localStorage.getItem("token");

    axios
      .get(
        `
          https://api.zimzee.com/api/dashboard/recent-items`,
        
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        
        setRecentFile(response?.data?.data?.filesList);
        // setbookmarksRefresh(false);
        setIsLoadings(false);
        // setIsLoadings(false);
        // setFilesData(response?.data?.data)
        // setNotes(response?.data?.data?.note?.bookmarksList);
        // setTag(response?.data?.data?.tags?.bookmarksList);
        // console.log("filessssssssssss", response?.data?.data?.bookmarksList);
        // setFileRefresh(false);
        // updateFiles()
        // setCardList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoadings(false);
      });
  }, [cardFilterDefault, fileUpdate]);
  const updateFiles=()=>{
    setFileUpdate(!fileUpdate)
      }
      const [searchValue, setSearchValue] = useState('');
  const [subtags,setSubTags] = useState([]);
  const [tagActiveId,setTagActiveId] = useState(0);
    const [tagActiveName,setTagActiveName] = useState('');
//   useEffect(()=>{
//     // api url
  

//      const token = localStorage.getItem('token');

//       axios.post(`
//       https://api.zimzee.com/api/cards/cards-with-files`,{"filterValue":cardFilterDefault, limit: 100},{
//           headers: {
//           Authorization: `Bearer ${token}`,
//         }
//         })
//         .then(function (response) {
//           setIsLoading(false);
//           setFilesData(response?.data?.data)

//           console.log("filessssssssssss",response?.data?.data)
//           // setCardList(response.data.data);
          
//         })
//       .catch(function (error) {
//         console.log(error);
//       });
 
//  },[cardFilterDefault,fileUpdate])
 useEffect(()=>{
  setTimeout(() => {
    console.log(filesData,"filessssss title")
  }, 5000);
  
 },[filesData])
  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files);
    setFiles(newFiles);
  };
//   const handleCheckboxChange = (event) => {
//     const value = event.target.value;
//     if (event.target.checked) {
//       setSelectedItems((prevSelectedItems) => [...prevSelectedItems, value]);
//     } else {
//       setSelectedItems((prevSelectedItems) =>
//         prevSelectedItems.filter((item) => item !== value)
//       );
//     }
//   };
const handleCardClick = (cardId) => {
  setClickedCardId(cardId);
};
const handleSharePop = (id) => {
  console.log(id, "share card id");
  setShareFileId(id);
  setFileSharePopup(true);
};
const closeSharePop = () => {
  console.log("close share pop");
  setFileSharePopup(false);
};

  return (
  <>
    {isLoadings ? (
        <div className="App-loading">
          <div className="App-logo" title="Zimzee" />
        </div>
      ) : (
    <div className="files-wholesection">
      
        <div className="filescard-section recent-files-section flex flex-wrap">
         
          {RecentFile.map((file)=>{
            const sharedWithObjects = file?.shared
            ? file.shared
              .filter(shares => shares.sharedWith != null)
              .map(shares => shares.sharedWith)
            : [];
            return(
              
                <SingleFile
                  key={file?._id}
                  Url={file?.content?.imageUrl[0]?.url}
                  handleSharePop={handleSharePop}
                  starred={file?.starred}
                  buckets={buckets}
                  cardId={file?._id}
                  sharedUsers={sharedWithObjects}
                  cardType={file?.cardType}
                  sharedLength={file?.shared.length}
                  title={file?.title}
                  note={file?.note}
                  tags={file?.tags}
                  created={file?.createdAt}
                  content={file?.content}
                  // handleCheckboxChange={handleCheckboxChange}
                  handleCardClick={handleCardClick}
                  cardsRefresh={updateFiles}
                  setIsLoading={setIsLoading}
                />
             
//             <div className="cardHolder-cards rounded-[14px] overflow-hidden mb-3 break-inside" >
//             <div className="flex justify-between w-full pt-3 pb-1">
//               <div className="inputCheck">
//                 <input type="checkbox" value={file._id} />
//               </div>
//             </div>
//             <div className="cardHeader flex items-center pb-5">
//               <div className="Cardtitle flex">
//                 <img className="mr-2" src={PDF}  alt="pdf" />
//                 <h3 className="design-textcontent">{file.title}</h3>
//               </div>
//               <div className="iconsRight flex iconsalign-content">
//                 <FontAwesomeIcon className="text-[#707070] text-[22px] ml-4 mr-1" icon={faEllipsisVertical} />
//               </div>
//             </div>
//             <div className="cardContent w-full">
//             {file.content.imageUrl.map((image, index) => (
//   <div key={index}>
//     {allowedImageTypes.includes(image.urlType) ? (
//       <img className="w-full" src={image.url} alt={`projectImg${index}`} />
//     ) : (
//       <img className="w-full" src={FileDownload} alt={`projectImg${index}`} />
//     )}
//   </div>
// ))}
//               {/* <img className="w-full imagefile-content" src={CardImage2} alt="projectImg1" /> */}
//             </div>
//             <div className="flex">
//               <div>
//                 <div className="fashioncards-content createalign-content">Fashion Trends 2022 Sketch</div>
//                 <div className="uploadedcards-content pb-2">Uploaded on {new Date(file.createdAt).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</div>
//               </div>
//               <div className="profile-displaycontent">
//                 <div className="profile-imgcontent">
//                   <img src={Profile1} alt="profile1" />
//                   <img src={Profile2} alt="profile2" />
//                   <img src={Profile3} alt="profile3" /><br />
//                 </div>
//                 <p className="sharesright-content">0+ Shares</p>
//               </div>
//             </div>
//             <div className="flex cardsicon-content">
//               <img className="tooltip-icon mr-8" src={ClipBoard} alt="clipboardNotes" style={{ width: "20px" }} />
//               <img className="mr-8" src={SubStar} alt="subStar" style={{ width: "20px" }} />
//               <img className="subtags mr-8" src={SubTag} alt="subTag" />
//               <img className="mr-8" src={SubShare} alt="subShare" />
//               <img className="linkcards-icon" src={linkIcon} alt="link-icon" style={{ width: "20px" }} />
//             </div>
//           </div>
          )})}
          {FileSharePopup && (
            <div
              className="filesuploadpopup relative !z-[999]"
              style={{ height: "100%" }}
              onClick={closeSharePop}
            >
              <div
                className="subinviteupload-section"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="teaminvitesection flex">
                  <div className="teammembers-content">Share</div>
                  <div
                    className="dismissSubfiles-content"
                    onClick={closeSharePop}
                  >
                    <img src={BlackDismiss} alt="dismissButton" />
                  </div>
                </div>
                <ShareCard
                  shareBucketId={ShareFileId}
                  closeSharePop={closeSharePop}
                  cardsRefresh={UpdateRecentCards}
                />
              </div>
            </div>
          )}
          
        </div>
    
    </div>  
   
        
      )}
        
         </>
  )
}
export default RecentFiles;