import { React, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./Tags.scss";
import { useEffect } from "react";
import { toastify } from "../../components/Toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function DeleteTags({
  setPopup,
  parent,
  bucketUpdate,
  bucketOperation,
  bucketNameText,
  changeTxt,
  bucketUpdateId,
  parentDelete,
}) {
  const [selectedOption, setSelectedOption] = useState("");

  const handleRadioButtonChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const createBucket = async (name) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://api.zimzee.com/api/tags/add",
        { name, userId: userData._id, parentId: parent },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toastify("success", response.data.message);
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
  };

  const updateCreateBucket = async (id, name) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://api.zimzee.com/api/tags/update-tag",
        { tagId: id, sourceId: null, destinationId: null, name: name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
  };

  const handleButtonDelete = async (id) => {
    // setIsOpen(true);
    console.log("Selected radio button value:", selectedOption);
    console.log(id);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    // setIsLoading(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}tags/delete-tag`,
        {
          data: {
            tagId: id,
            isChildDeleted:
              selectedOption == "option1"
                ? false
                : selectedOption == "option2"
                ? true
                : false,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "response");
      toastify("success", response.data.message);
      bucketUpdate();
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
    setPopup(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(bucketUpdateId, "bucketUpdateId");
    const name = event.target.name.value; // Get the name value from the form
    if (bucketOperation == "Cancel") {
      await createBucket(name);
    } else if (bucketOperation == "subbucket") {
      await createBucket(name);
    } else {
      console.log(bucketUpdateId, "bucketNameText");
      console.log(name, "name params");
      await updateCreateBucket(bucketUpdateId, name);
    }

    setPopup(false);
    bucketUpdate();
  };

  function closePopup() {
    setPopup(false);
  }

  return (
    <div
      className="bucketCreationPopuptags relative !z-[999]"
      onClick={closePopup}
    >
      <form
        className={`addBucket relative w-[85%] md:w-[500px] ${
          parentDelete > 0 ? "pwc" : "pnc"
        }`}
        onSubmit={handleSubmit}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <span
          onClick={closePopup}
          className="absolute right-0 top-0 w-6 h-6 bg-[#000] m-4 flex justify-center items-center rounded-full"
        >
          <FontAwesomeIcon
            className="cursor-pointer text-white text-[14px]"
            icon={faClose}
          />
        </span>
        <h2 className="createpopup-content" style={{ textAlign: "left" }}>
          Delete tag?
        </h2>
        <hr />
        {parentDelete > 0 ? (
          <>
            <div className="tagsdesc-content">
              This tag includes one or more sub tags
            </div>
            <div className="subtagsdelete-content">
              <input
                type="radio"
                name="deleteTag"
                value="option1"
                checked
                onChange={handleRadioButtonChange}
              />
               {" "}
              <label className="tagsdesc-content" for="html">
                Delete only this tag, and keep all sub tags as parent tags
              </label>
            </div>
            <div className="subtagdelete-content">
              <input
                type="radio"
                name="deleteTag"
                value="option2"
                checked={selectedOption === "option2"}
                onChange={handleRadioButtonChange}
              />
               {" "}
              <label className="tagsdesc-content" for="html">
                Delete this tag with all sub tags
              </label>
            </div>
          </>
        ) : (
          <>
            <div className="tagsdesc-content">
              Are you sure you want to delete this tag? This tag will be removed
              from all content.
            </div>
            <div className="subtagdelete-content"></div>
            {/* <div className="subtagdelete-content" ></div> */}
          </>
        )}

        {/* <hr /> */}
        {/* <input className="flex items-center rounded-3xl border-0 menusubitems-content createdtagstext-content" type="text" name="name" placeholder="Tag Name" onChange={changeTxt} value={bucketNameText} /> */}
        <div className="mt-4 flex justify-end">
          <button
            style={{
              filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
            }}
            className="canceltag-content text-[16px] rounded-full savebutton-context"
            type="button"
            onClick={closePopup}
          >
            Cancel
          </button>
          <button
            style={{
              filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
            }}
            className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white savebutton-context"
            type="button"
            onClick={() => handleButtonDelete(bucketUpdateId)}
          >
            Delete
          </button>
          {/* <button style={{ filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))' }} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
                    <span className="">Create</span>
                  </button> */}
        </div>
        {/* <button type="submit">{(bucketOperation == "subbucket")? "Cancel": (bucketOperation == "Update Sub")? "Update":bucketOperation}</button> */}
      </form>
    </div>
  );
}

export default DeleteTags;
