import {React,Fragment,useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faNoteSticky, faPencil,faTrash,faFolder,faChevronDown} from "@fortawesome/free-solid-svg-icons";
import { Menu, Transition } from '@headlessui/react';
import SingleBucket from "../../shades/SingleBucket";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import ThreeDots from "../../assets/icons/threeDots.svg";
import { useOutletContext } from "react-router-dom";
import compactCardIcon from "../../assets/icons/Grid view - Grey.svg";
import EditIcon from '../../assets/icons/edit.svg';
import DeleteIcon from '../../assets/icons/delete.svg';
import CalenderIcon from '../../assets/icons/calendar.svg';
import FolderBucket from '../../assets/icons/folderbucket.svg';
import FileBucket from '../../assets/icons/fileBucket.svg';
import Arrow from '../../assets/icons/arrow.svg';
import ListView from '../../assets/icons/List view - Black.svg';
import CardList from '../../components/CardList/CardList.js'

function CardListView(shared) {
    const [subBucket,setSubBucket] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [popup,setPopup] = useState(false);
    const [bucketCreated,setBucketCreated] = useState(false);
    const [isOpen] = useOutletContext();
   
   
     const bucketId = useParams();
     console.log(bucketId,"bucketId");
     useEffect(()=>{
       // api url
   
        const token = localStorage.getItem('token');
     
        axios.get(`${process.env.REACT_APP_API}buckets/single/${bucketId.id}`,{
          headers: {
           Authorization: `Bearer ${token}`,
         }
        })
        .then(function (response) {
         setIsLoading(false);
          setSubBucket(response?.data?.data);
          console.log(subBucket);
        })
        .catch(function (error) {
          console.log(error);
        });
    
    },[bucketCreated])
       const filterDropDown = [
           { name: 'Created Date', href: '#' },
           { name: 'Recent Activity', href: '#' },
           { name: 'Ascending Order', href: '#' },
           { name: 'Descending Order', href: '#' },
           { name: 'Manual', href: '#' },
         ]
       
         const [filtersText, setFiltersText] = useState(filterDropDown[0].name);
       
         const handleFilterText = (text) => {
             setFiltersText(text);
           }
       const bucketNav = [
           {icon:faPencil, name: 'Edit Name', href: '#' },
          //  {icon:faNoteSticky, name: 'Create New Card', href: '#' },
           {icon:faTrash, name: 'Delete', href: '#' },
          //  {icon:faFolder, name: 'Customize New Card', href: '#' },
         ]
   
         const createBucket = async (name) => {
           const userData = JSON.parse(localStorage.getItem('userData'));
           const token = localStorage.getItem('token');
            try {
              const response = await axios.post('https://api.zimzee.com/api/buckets/add/', { name,userId:userData._id,parentId:bucketId.id },
              {
                headers: {
                    Authorization: `Bearer ${token}`,
                  }
              }
              );
              console.log(response);
            } catch (error) {
              console.error(error);
            }
          };
          const createSubBucket = async (event) => {
             event.preventDefault();
             const name = event.target.name.value; // Get the name value from the form
             await createBucket(name); 
             setPopup(false);
           };
   
   
         function closePopup(){
             setPopup(false);
         }
   
         function bucketUpdate(){
           setBucketCreated(true);
         }
       
         function getUrl(){
            return `/app/buckets/${bucketId.id}`
         } 
          
         function classNames(...classes) {
           return classes.filter(Boolean).join(' ')
         }  
  return(
    
    <div className="subBuckets-whole listingCards">
            <div className="cards">
                <div className="card-header flex flex-col md:flex-row justify-start md:justify-between items-center w-full mb-3">
                    <div className="cardHeaderContent">
                        <div className="bucketNameTag">
                            <p className="subBucket-content">My Bucket <span className="uxtext-content">/ UX</span></p>
                        </div>
                        <div className="flex items-center">
                            <h4 className="bucketName-content">{subBucket.name}</h4>
                            <div className="bucketActions flex items-center">
                                <span>
                                  <img className="subbuckets-dark" src={EditIcon} alt="editIcon" />
                                  </span>
                                <span>
                                  <img className="ml-[40px]" src={DeleteIcon} alt="DeleteIcon" />
                                  {/* <FontAwesomeIcon className="px-3 text-[#707070]" icon={faTrash} /> */}
                                  </span>
                            </div>
                        </div>
                        <div className="cardHeaderDetails">
                            <ul className="flex flex-wrap items-center">
                                <li className="text-[14px] lg:text-[17px]"><a className="flex items-center" href="/"><img src={CalenderIcon} alt='calenderIcon' className="w-[22px] dark-mode-icon"/> <span className="createdtext-content pl-2 dark:text-[#bbbbbb]">Created {moment(subBucket.createdAt).format('MMM D, YYYY @ hh:mm A')}</span></a> </li>
                                <li className="text-[14px] lg:text-[17px] text-[#707070]"><a className="flex items-center" href="/"><img src={FolderBucket} alt="folderBucket" className="w-[22px] dark-mode-icon" /> <span className="createdtext-content pl-2 dark:text-[#bbbbbb]">{subBucket?.subBuckets?.length} Sub Buckets</span></a> </li>
                                <li className="text-[14px] lg:text-[17px] text-[#707070]"><a className="flex items-center" href="/"><img src={FileBucket} alt="fileBucket" className="h-[22px] dark-mode-icon" /> <span className="createdtext-content pl-2 dark:text-[#bbbbbb]">{subBucket?.cards?.length} Cards</span></a> </li>
                                <li>
                                    <Menu as="div" className="relative">
                                      <Menu.Button
                                          className="relative background-primary text-white rounded-full px-2 pt-[0px] pb-[2px] sm:text-sm  md:text-md lg:text-lg flex items-center justify-center mt-3 md:mt-0"
                                      >
                                          <span className="text-[20px] lg:text-[20px]">+</span>
                                      </Menu.Button>
                                      <Transition
                                              as={Fragment}
                                              enter="transition ease-out duration-100"
                                              enterFrom="transform opacity-0 scale-95"
                                              enterTo="transform opacity-100 scale-100"
                                              leave="transition ease-in duration-75"
                                              leaveFrom="transform opacity-100 scale-100"
                                              leaveTo="transform opacity-0 scale-95"
                                          >
                                              <Menu.Items className="popup-subbucket absolute left-0 px-4 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                  <Menu.Item>
                                                    <p onClick={()=>setPopup(true)} className="text-black border-b py-2 cursor-pointer">Create Sub Bucket</p>                                                  
                                                  </Menu.Item>
                                                  {/* <Menu.Item>
                                                    <p className="text-black py-2 cursor-pointer">Create Card</p>                                                        
                                                  </Menu.Item> */}
                                              </Menu.Items>
                                          </Transition>
                                    </Menu>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="inviteBtn w-full md:w-60 lg:w-48 mt-3 md:mt-0">
                        <a style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="btn-invite rounded-full bg-[#FF6600] text-white sm:text-sm  md:text-md lg:text-lg" href="#">@ Invite People</a>
                    </div>
                </div>
                <hr />
                <div className="subBucketsHolder">
                  <div className="subbucket-displaycontent">
                    <div className="subBuckets flex items-center">
                      <h4 className="subbucketstext-content">Sub Buckets</h4><span className="subbucketsbg-content background-primary text-white rounded-full ml-4 text-[16px]">{subBucket?.subBuckets?.length}</span>
                    </div>
                    <div className="subbucket-endcard filterHolder flex items-center justify-end">
                        <div className="filter display-content">
                            {/* <a className="viewGrid" href="#">                            
                             <img className="mt-1" src={ListView} alt="listView" />
                            </a> */}
                            {/* <a className="viewGrid" href="#">                            
                             <img className="mt-1" src={compactCardIcon} style={{color:'#000000'}} alt="compactIcon" />
                            </a> */}
                        </div>
                        <span className="sorttext-content mx-3 dark:text-[#acacac]">Sort By</span>
                        <Menu as="div" className="relative flex-shrink-0">
                          <div>
                            <Menu.Button className="flex rounded-full focus:outline-none ">
                                  <a
                                    href="#"
                                    className="flex-shrink-0 rounded-full  text-gray-400 hover:text-gray-500"
                                  >
                                  <strong className="createdtext-content pr-4">{filtersText}</strong>
                                  <FontAwesomeIcon style={{fontSize:'12px',color:'#707070'}} icon={faChevronDown} />
                              </a>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          
                              {filterDropDown.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (

                                    <a
                                      onClick={()=>{handleFilterText(item.name)}}
                                      href={item.href}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block py-2 px-4 text-sm text-gray-700'
                                      )}
                                    >
                                      {item.name}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                    </div>
                  </div>
                  <div className="subBucketsList">
                  <div className={`BucketListHolder-content`}>
                    {subBucket?.subBuckets?.map(bucket=>{
                      return <SingleBucket bucketId={bucket._id} bucketName={bucket.name} subBucket={bucket.subBuckets} pined={bucket.pinned} shared={bucket.shared} bucketCard={bucket.cards} bucketDesign={bucket.bucketDesignId}/>
                    })}
                    </div>
                  </div>
                </div>

                <div className="cards-filter pb-[40px] pt-[80px] flex justify-between">
                    <div className="flex items-center">
                        <div className="card-count flex items-center">
                            <h4 className="cardssubBuckets-content">Cards</h4><span className="subbucketsbg-content background-primary text-white rounded-full ml-4 text-[16px]">{subBucket?.cards?.length}</span>
                        </div>
                        <Menu as="div" className="relative px-2">
                            <Menu.Button
                                className="relative ml-3 inline-flex items-center px-1 py-2 text-sm font-semibold text-gray-900 "
                            >
                                {/* <FontAwesomeIcon className="text-lg text-[#707070]" icon={faEllipsisVertical} /> */}
                                <img src={ThreeDots} alt="threeDots" />
                            </Menu.Button>
                            <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {bucketNav.map((item) => (
                                        <Menu.Item key={item.name}>
                                        {({ active }) => (
                                            <a
                                            href={item.href}
                                            className={classNames(
                                                active ? 'bg-gray-100' : '',
                                                'block py-2 px-4 text-sm text-gray-700'
                                            )}
                                            >
                                                <span className="pr-2"><FontAwesomeIcon className="text-[#797979]" icon={item.icon} /></span>
                                            <span className="text-black">{item.name}</span>
                                            </a>
                                        )}
                                        </Menu.Item>
                                    ))}
                                    </Menu.Items>
                                </Transition>
                        </Menu>
                        <img src={DeleteIcon} alt="deleteIcon" className="px-3"/>
                        {/* <FontAwesomeIcon className="transform rotate-45 px-3" icon={faArrowUp } /> */}
                        <img src={Arrow} alt="arrow" className="px-3" />
                    </div>
                    <div className="filterHolder flex items-center justify-end">
                        <div className="filter display-content">
                             {/*/app/buckets/645a1ec5d85e441bb6a8fb99  */}
                            <a className="viewGrid" href={getUrl()}>                            
                             <img className="mt-1 listcard-imgcontent" src={ListView} alt="listView" />
                            </a>
                            <a className="viewGrid" href="#">                            
                             <img className="mt-1 compact-imgcontent" src={compactCardIcon} style={{color:'#000000'}} alt="compactIcon" />
                            </a>  
                        </div>
                        
                        <span className="sorttext-content mx-3 dark:text-[#acacac]">Sort By</span>
                        <Menu as="div" className="relative flex-shrink-0">
                          <div>
                            <Menu.Button className="flex rounded-full focus:outline-none ">
                                  <a
                                    href="#"
                                    className="flex-shrink-0 rounded-full p-1 text-gray-400 hover:text-gray-500"
                                  >
                                  <strong className="createdtext-content pr-4">{filtersText}</strong>
                                  <FontAwesomeIcon style={{fontSize:'12px',color:'#707070'}} icon={faChevronDown} />
                              </a>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          
                              {filterDropDown.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (

                                    <a
                                      onClick={()=>{handleFilterText(item.name)}}
                                      href={item.href}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block py-2 px-4 text-sm text-gray-700'
                                      )}
                                    >
                                      {item.name}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                    </div>
                </div>
            </div>
            {/* Single Card */}
                <CardList />
                
          </div>
        
  );
}
export default CardListView