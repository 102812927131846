import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useEffect } from "react";
import { toastify } from '../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MoveTags({setPopup,parent,bucketUpdate,bucketOperation,bucketNameText,changeTxt,bucketUpdateId,subtags}){
   
    const createBucket = async (name) => {
       const userData = JSON.parse(localStorage.getItem('userData'));
       const token = localStorage.getItem('token');
        try {
          const response = await axios.post('https://api.zimzee.com/api/tags/add', { name,userId:userData._id,parentId:parent },
          {
            headers: {
                Authorization: `Bearer ${token}`,
              }
          }
          );
          toastify("success", response.data.message);
        } catch (error) {
          console.error(error);
          toastify("error", error.response.data.message );
        }
      };

      const updateCreateBucket = async (id,destination) => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = localStorage.getItem('token');
         try {
           const response = await axios.patch('https://api.zimzee.com/api/tags/update-tag', {tagId:id,sourceId:id,destinationId:destination },
           {
             headers: {
                 Authorization: `Bearer ${token}`,
               }
           }
           );
           console.log(response);
           toastify("success", response.data.message);
         } catch (error) {
           console.error(error);
           toastify("error", error.response.data.message );
         }
       };
       
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(bucketUpdateId,"bucketUpdateId");
        const destination = event.target.destination.value == 'parent' ? null : event.target.destination.value; // Get the name value from the form
        await updateCreateBucket(bucketUpdateId,destination);
        setPopup(false);
        bucketUpdate();
      };

    function closePopup(){
        setPopup(false); 
    }
    
    return(
        <div className="bucketCreationPopuptags relative !z-[999]"onClick={closePopup}>
            <form className="addBucket relative w-[85%] md:w-[500px]" onSubmit={handleSubmit} onClick={(e)=>{e.stopPropagation()}}>
                <span onClick={closePopup} className="absolute right-0 top-0 w-6 h-6 bg-[#000] m-4 flex justify-center items-center rounded-full">
                    <FontAwesomeIcon className="cursor-pointer text-white text-[14px]" icon={faClose} />
                </span>
                <h2 style={{textAlign:"left"}}>Move Tag</h2>
                {/* <input className="p-3 border border-1 rounded-lg mt-4" type="text" name="name" placeholder="Enter Tag Name" onChange={changeTxt} value={bucketNameText} /> */}
                <select className="p-3 border border-1 rounded-lg mt-4" name="destination">
                    {/* <option value={`parent`}>Make Parent</option> */}
                    {subtags?.map((tag, index) => (
                    <option value={tag._id} key={tag._id}>{tag.name}</option>
                    ))}
                </select>
                <button style={{ filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))' }} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
                  <span className="savebutton-context">Move</span>
                </button>
                {/* <button type="submit">Move</button>  */}
            </form>
        </div>
    )
}

export default MoveTags;