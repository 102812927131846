import { React, useState, Fragment, useEffect } from "react";
import "./shared.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Youtube from "../../assets/icons/youtube.svg";
import Twitter from "../../assets/icons/twitter.svg";
import Instagram from "../../assets/icons/instagram.svg";
import Wikipedia from "../../assets/icons/Wikipedia.svg";
import Shareicon from "../../assets/icons/gotourl.svg";
import Trashs from "../../assets/icons/delete.svg";
import LightDelete from "../../assets/icons/Grey_Delete.svg";
import FbIcon from "../../assets/images/fbnewlogo.svg";
import youtubeIcon from "../../assets/images/youtube.svg";
import tiktokIcon from "../../assets/images/download-tiktok.png";
import instagramIcon from "../../assets/images/instagram-icon-png.png";
import ZimzeeIcon from "../../assets/icons/logoSymbol.svg";
import FullScreen from "../../assets/icons/FullScreen.svg";
import RegionalScreen from "../../assets/icons/RegionalScreenshot.svg";
import BookmarkDetailIcon from "../../assets/icons/bookmarkdetail.svg";
import Highlight from "../../assets/icons/HighlightText.svg";
import twitterlogo from "../../assets/images/twitternewlogo.svg";
import defaultUser from "../../assets/images/default-user.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Menu, Transition, Dialog } from "@headlessui/react";
import linkIcon from "../../assets/icons/copyurl.svg";
import { toastify } from "../../components/Toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
function WhatIShared() {
  const navigate=useNavigate()
  const [sharedData, setSharedData] = useState([]);
  const [sharedBucketData, setSharedBucketData] = useState([]);
  const[BucketitemCount,setBucketitemCount] = useState(0);
  const[CarditemCount,setCarditemCount] = useState(0);
  const [DeactiveOpen, setDeactiveOpen] = useState(false);
  const [DeleteOpen, setDeleteOpen] = useState(false);
  const [SelectedCardId, setSelectedCardId] = useState([]);
  const [itemRefresh, setItemRefresh] = useState(false);
  const [ActiveOpen, setActiveOpen] = useState(false);
  const[openCopyLink, setOpenCopyLink] = useState(false);
  const [publicUrl, setPublicUrl] = useState("");
  const [iOS, setiOS] = useState(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
  // const [SelectedItems,setSelectedItems]=useState([])
  const [appTheme,setAppTheme]=useState("")
  const filterDropDown = [
    // { name: 'Recent Activity', href: '#' },
    { name: "Ascending", href: "#" },
    { name: "Descending", href: "#" },
    { name: "Shared Date", href: "#" },
    { name: "Views", href: "#" },
    { name: "Clicks", href: "#" },
    // { name: 'Views', href: '#' },
    // { name: 'Clicks', href: '#' },
  ];

  let cardFilterv = "Shared_Date";
  if (!localStorage.getItem("sharecardFilter")) {
    localStorage.setItem("sharecardFilter", "Shared_Date");
    cardFilterv = "Shared_Date";
  } else {
    cardFilterv = localStorage.getItem("sharecardFilter");
  }
  console.log(cardFilterv, "sharecardFilter");
  const [cardFilterDefault, setCardFilterDefault] = useState(cardFilterv);
  useEffect(() => {
    const handleStorageChange = () => {
      console.log('app theme')
      const theme = localStorage.getItem("theme");
      setAppTheme(theme);
    };
  
    window.addEventListener('storage', handleStorageChange);
  
    // Force a re-render when localStorage changes
    setAppTheme(localStorage.getItem("theme"));
  
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  },[]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `${process.env.REACT_APP_API}team/whatIhaveShared?filterValue=${cardFilterDefault}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        console.log(response?.data?.data, "tags list");
        const BucketItems = response?.data?.data.filter(item => item.model == "Bucket");
        // console.log(BucketItems,"BucketItems");
        setSharedBucketData(BucketItems);
        const filteredBucketData = BucketItems.filter(data => data.item !== null);
        // Get the count of items where data.item !== null
        const itemCount = filteredBucketData.length;
        setBucketitemCount(itemCount);
        const CardItems = response?.data?.data.filter(item => item.model == "Card");
        // console.log(CardItems,"CardItems")
        setSharedData(CardItems);
        const filteredCardData = CardItems.filter(data => data.item !== null);
        // Get the count of items where data.item !== null
        const itemCountCard = filteredCardData.length;
        setCarditemCount(itemCountCard);
        setItemRefresh(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [itemRefresh]);
  useEffect(() => {
    console.log(SelectedCardId, "selected card idsssssss");
  }, [SelectedCardId]);

  const handleDeleteCard = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        "https://api.zimzee.com/api/team/deletePublicSharedItems",
        {
          data: { itemIds: SelectedCardId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      closeDeleteCard();
      setSelectedCardId([]);
      setItemRefresh(true);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeactivateCard = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://api.zimzee.com/api/team/updatePublicSharedItem",
        { status: "false", itemId: SelectedCardId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      closeDeactiveCard();
      setItemRefresh(true);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  const handleActiveCard = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://api.zimzee.com/api/team/updatePublicSharedItem",
        { status: "true", itemId: SelectedCardId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      closeActiveCard();
      setItemRefresh(true);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  const closeDeleteCard = () => {
    setDeleteOpen(false);
  };
  const closeActiveCard = () => {
    setActiveOpen(false);
  };
  const closeDeactiveCard = () => {
    setDeactiveOpen(false);
  };
  const filterDropDownselect = [
    { name: "Deactivate", href: `javaxcript:void(0);` },
    { name: "Delete", href: `javaxcript:void(0);` },
  ];

  const [filtersText, setFiltersText] = useState(
    cardFilterv == "Shared_Date"
      ? filterDropDown[2].name
      : cardFilterv == "Descending_Order"
      ? filterDropDown[1].name
      : cardFilterv == "Ascending_Order"
      ? filterDropDown[0].name
      : cardFilterv == "Views"
      ? filterDropDown[3].name
      : cardFilterv == "Clicks"
      ? filterDropDown[4].name
      : ""
  );

  const [FiltersSelectText, setFiltersSelectText] = useState(
    filterDropDownselect[0].name
  );

  const handleFilterText = (text) => {
    // setIsLoading(true);
    setFiltersText(text);
    const filterValues =
      text == "Shared Date"
        ? "Shared_Date"
        : text == "Descending"
        ? "Descending_Order"
        : text == "Ascending"
        ? "Ascending_Order"
        : text == "Recent Activity"
        ? "Recent_Activity"
        : text == "Views"
        ? "Views"
        : text == "Clicks"
        ? "Clicks"
        : "";
    localStorage.setItem("sharecardFilter", filterValues);
    setCardFilterDefault(filterValues);
    setItemRefresh(!itemRefresh);
  };
  const deleteCard = () => {
    setDeleteOpen(true);
  };
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedCardId((prevSelectedItems) => [...prevSelectedItems, value]);
    } else {
      setSelectedCardId((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== value)
      );
    }
  };
  const handleFilterSelectText = (text, id) => {
    setFiltersSelectText(text);
    setSelectedCardId([id]);
    if (text === "Deactivate") {
      setDeactiveOpen(true);
    } else if (text === "Activate") {
      setActiveOpen(true);
    } else if (text === "Delete") {
      setDeleteOpen(true);
    }
  };
  const closeCopyLinkModal = () => {
    setOpenCopyLink(false);
  }

  const handleCopyText = (url) => {
      var emailLink = document.querySelector('.publicUrlInput');
      console.log(emailLink,"copyEmailBtn"); 
      emailLink.select();
      var range = document.createRange();  
      range.selectNode(emailLink);  
      window.getSelection().addRange(range);  

      try {  
        // Now that we've selected the anchor text, execute the copy command  
        var successful = document.execCommand('copy');  
        var msg = successful ? 'successful' : 'unsuccessful';  
        console.log('Copy email command was ' + msg);  
        toastify("success", "Public link is copied to clipboard");
      } catch(err) {  
        console.log('Oops, unable to copy');  
        toastify("success", 'Oops, unable to copy');
      }  

      // Remove the selections - NOTE: Should use
      // removeRange(range) when it is supported  
      window.getSelection().removeAllRanges();  
      setOpenCopyLink(false);
  }

  const handleCardLink = async (itemId) => {
    const url = `https://share.zimzee.com/public/card/${itemId}`;
    if(iOS){
      setOpenCopyLink(true);
      setPublicUrl(url);
      // var data = [new ClipboardItem({ "text/plain": Promise.resolve(new Blob([url], { type: "text/plain" }) )})];
      // navigator.clipboard.write(data).then(function() {
      //   // console.log("Copied to clipboard successfully!");
      //   toastify("success", "Public link is copied to clipboard");
      // }, function() {
      //   // console.error("Unable to write to clipboard. :-(");
      //   toastify("error", "Unable to write to clipboard. :-(" );
      // });
    }else{
      const tempInput = document.createElement("input");
      tempInput.value = url;

      // Append the input element to the DOM
      document.body.appendChild(tempInput);

      // Select the input element's text and copy it to clipboard
      tempInput.select();
      document.execCommand("copy");

      // Remove the temporary input element
      document.body.removeChild(tempInput);
      
      toastify("success", "Public link is copied to clipboard");
    }
    // if (url) {
    //   // Create a temporary input element to copy the link to clipboard
    //   const tempInput = document.createElement("input");
    //   tempInput.value = url;

    //   // Append the input element to the DOM
    //   document.body.appendChild(tempInput);

    //   // Select the input element's text and copy it to clipboard
    //   tempInput.select();
    //   document.execCommand("copy");

    //   // Remove the temporary input element
    //   document.body.removeChild(tempInput);

    //   // Optionally, you can show a message to indicate that the link has been copied
    //   // console.log(response.data.message)
    //   toastify("success", "Public link is copied to clipboard");
    // }
  };

  const handleBucketLink = async (itemId) => {
    const url = `https://share.zimzee.com/public/bucket/${itemId}`;
    if(iOS){
      setOpenCopyLink(true);
      setPublicUrl(url);
      // var data = [new ClipboardItem({ "text/plain": Promise.resolve(new Blob([url], { type: "text/plain" }) )})];
      // navigator.clipboard.write(data).then(function() {
      //   // console.log("Copied to clipboard successfully!");
      //   toastify("success", "Public link is copied to clipboard");
      // }, function() {
      //   // console.error("Unable to write to clipboard. :-(");
      //   toastify("error", "Unable to write to clipboard. :-(" );
      // });
    }else{
      const tempInput = document.createElement("input");
      tempInput.value = url;

      // Append the input element to the DOM
      document.body.appendChild(tempInput);

      // Select the input element's text and copy it to clipboard
      tempInput.select();
      document.execCommand("copy");

      // Remove the temporary input element
      document.body.removeChild(tempInput);
      
      toastify("success", "Public link is copied to clipboard");
    }
    // if (url) {
    //   // Create a temporary input element to copy the link to clipboard
    //   const tempInput = document.createElement("input");
    //   tempInput.value = url;

    //   // Append the input element to the DOM
    //   document.body.appendChild(tempInput);

    //   // Select the input element's text and copy it to clipboard
    //   tempInput.select();
    //   document.execCommand("copy");

    //   // Remove the temporary input element
    //   document.body.removeChild(tempInput);

    //   // Optionally, you can show a message to indicate that the link has been copied
    //   // console.log(response.data.message)
    //   toastify("success", "Public link is copied to clipboard");
    // }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return ( 
    <div className="WhatIShared">
      <h5>What I Shared</h5>
      {/* <hr className="my-[30px]" />  */}
      <Tabs>
        <TabList>
          <Tab> 
            Publicly shared cards{" "}
            <span className="tab-count rounded">{CarditemCount}</span>
          </Tab>
          <Tab> 
            Publicly shared buckets{" "}
            <span className="tab-count rounded">{BucketitemCount}</span>
          </Tab>
        </TabList>
        <TabPanel>
          <div className="flex">
            <div className="flex">
            
              {SelectedCardId?.length ?<img
                className=" mr-[10px] px-3"
                src={appTheme==="light"?Trashs:LightDelete}
                alt="delete"
                onClick={deleteCard}
              />:<img
              className=" mr-[10px] px-3"
              src={appTheme==="light"?LightDelete:Trashs}
              alt="delete"
            />}
              {" "}
              <span
                className={
                  SelectedCardId?.length ? `delete-shares` : `delete-share`
                }
                onClick={SelectedCardId?.length ? deleteCard: null}
                style={{ cursor: "pointer" }}
              >
                Delete
              </span>
            </div>
            <div className="ml-auto">
              <div className="ml-[auto] flex items-center justify-end">
                <span className="sorttext-content mx-3 dark:text-[#acacac]">
                  Sort By
                </span>
                <Menu
                  as="div"
                  className="relative flex-shrink-0"
                >
                  <div>
                    <Menu.Button className="flex rounded-full focus:outline-none ">
                      <div
                        href="#"
                        className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                      >
                        <strong className="createdtext-content pr-4">
                          {filtersText}
                        </strong>
                        <FontAwesomeIcon
                          style={{ fontSize: "12px", color: "#707070" }}
                          icon={faChevronDown}
                        />
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {filterDropDown.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                handleFilterText(item.name);
                              }}
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-[13px] text-gray-700"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
                <Transition appear show={DeleteOpen} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={closeDeleteCard}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              Delete
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to delete?
                              </p>
                            </div>

                            <div className="mt-4">
                              <button
                                style={{
                                  background: "#FF6600",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={handleDeleteCard}
                              >
                                Yes
                              </button>
                              <button
                                style={{
                                  background: "#000",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={closeDeleteCard}
                              >
                                No
                              </button>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
                <Transition appear show={DeactiveOpen} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={closeDeactiveCard}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              Deactivate
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to Deactivate?
                              </p>
                            </div>

                            <div className="mt-4">
                              <button
                                style={{
                                  background: "#FF6600",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={handleDeactivateCard}
                              >
                                Yes
                              </button>
                              <button
                                style={{
                                  background: "#000",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={closeDeactiveCard}
                              >
                                No
                              </button>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
                <Transition appear show={ActiveOpen} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={closeActiveCard}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              Activate
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to Activate?
                              </p>
                            </div>

                            <div className="mt-4">
                              <button
                                style={{
                                  background: "#FF6600",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={handleActiveCard}
                              >
                                Yes
                              </button>
                              <button
                                style={{
                                  background: "#000",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={closeActiveCard}
                              >
                                No
                              </button>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition> 
              </div>
            </div>
          </div>
          <div className="shareddesktop-section">
            {sharedData?.map((data) => {
              return data?.item !== null ? (
                <div className="flex mt-[32px] my-auto" key={data?._id}>
                  <div className="my-auto checkshared-content checkbox-shared">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      value={data?._id}
                      onChange={handleCheckboxChange}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div className="my-auto facebook-shared" onClick={()=>navigate(`/app/card-detail/${data?.item?._id}`)}>
                    <img
                      className="youtubeshared-content"
                      src={
                        data?.item?.cardType
                          ? data?.item?.cardType == "twitter"
                            ? twitterlogo
                            : data?.item?.cardType == "facebook" ||
                              data?.item?.cardType == "Facebook"
                            ?  data?.item?.postDetail
                            ? data?.item?.postDetail?.imageUrl
                            : FbIcon
                            : data?.item?.cardType == "screenshoot"
                            ? ZimzeeIcon
                            : data?.item?.cardType == "fullScreen"
                            ? FullScreen
                            : data?.item?.cardType == "partScreen"
                            ? RegionalScreen
                            : data?.item?.cardType == "highlightText"
                            ? Highlight
                            : data?.item?.cardType == "youtube"
                            ? data?.item?.postDetail
                            ? data?.item?.postDetail?.imageUrl
                            : youtubeIcon
                            : data?.item?.cardType == "TikTok"
                            ? data?.item?.postDetail
                            ? data?.item?.postDetail?.imageUrl
                            : tiktokIcon
                            : data?.item?.cardType == "instagram"
                            ? data?.item?.postDetail
                            ? data?.item?.postDetail?.imageUrl
                            : instagramIcon
                            : data?.item?.cardType == "bookmark"
                            ? BookmarkDetailIcon
                            : defaultUser
                          : "" 
                      }
                      alt="youtube"
                    />
                  </div>
                  <div className="my-auto sharedtitle-content name-whatishared" style={{cursor:"pointer"}} onClick={()=>navigate(`/app/card-detail/${data?.item?._id}`)}>
                    <div className="tooltip">
                      <span className="tooltip-text bucketshrename">
                        <p className="block text-backgroundshare">
                          {data?.item?.title}
                        </p>
                      </span>
                      <h6 className="bucket-title">
                        {data?.item?.title}
                      </h6>
                    </div>

                    {/* <p className="bucket-name">Fashion Bucket</p> */}
                  </div>

                  <div className="shared-whatishared">
                    <h6 className="td-head">Shared on</h6>

                    <p className="td-count ">
                      {moment(data?.createdAt).format("MMM D, YYYY @ hh:mm A")}
                    </p>
                  </div>
                  <div className="views-whatishared">
                    <h6 className="td-head">Views</h6>
                    <p className="td-count ">{data?.views}</p>
                  </div>
                  <div className="clicks-whatishared">
                    <h6 className="td-head">Clicks</h6>
                    <p className="td-count ">{data?.clicks}</p>
                  </div>
                  <div className="flex my-[auto] shareditem-content goto-whatishared">
                    {/* <div className="tooltip"> 
               <span className="tooltip-text bucketsshare">
                 <p className="block text-background">{`https://staging.zimzee.com/public/card/${data?.item?._id}`}</p>
               </span> */}
                    {/* <h6><p className="link-td">Go-To URL</p></h6> */}
                    {/* </div> */}
                    <a
                      href={`https://share.zimzee.com/public/card/${data?.item?._id}`}
                      target="_blank"
                    >
                      {" "}
                      <img
                        className="ml-[20px] share-icon"
                        src={Shareicon}
                        alt="shareicon"
                      />
                    </a>
                  </div>
                  <div className="flex shareditem-content copyto-whatishared">
                    <p className="flex justify-spacebetween">
                      <img
                        className="publiclink-imgcontent"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "7px",
                          marginLeft: "10px",
                        }}
                        src={linkIcon}
                        alt="link-icon"
                        onClick={() => {
                          handleCardLink(data?.item?._id);
                        }}
                      />
                    </p>
                  </div>
                  <div className="my-[auto] active-whatishared">
                    {data?.isActive === true ? (
                      <button className="class-active">Active</button>
                    ) : (
                      <button className="class-inactive">Inactive</button>
                    )}
                    {/* <button className="class-active">Active</button> */}
                  </div>
                  <div className="my-[auto] threedot-whatishared">
                    <Menu as="div" className="relative flex-shrink-0">
                      <div>
                        <Menu.Button className="flex rounded-full focus:outline-none ">
                          <div
                            href="#"
                            className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                          >
                            <strong>
                              <FontAwesomeIcon
                                className="vertical-iconcontent text-[#707070] text-[22px]"
                                icon={faEllipsisVertical}
                              />
                            </strong>
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-30 text-left  origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleFilterSelectText(
                                    data?.isActive ? "Deactivate" : "Activate",
                                    data?._id
                                  );
                                }}
                                //  href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-[13px] text-gray-700"
                                )}
                              >
                                {data?.isActive ? "Deactivate" : "Activate"}
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleFilterSelectText("Delete", data?._id);
                                }}
                                //  href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-[13px] text-gray-700"
                                )}
                              >
                                Delete
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              ) : null;
            })}
          </div>

          <div className="sharedmbl-section">
          {sharedData?.map((data) => {
              return data?.item !== null ? (
                <div className="flex justify-between mt-[32px] my-auto padding-auto" key={data?._id}>
                  <div className="my-auto">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      value={data?._id}
                      onChange={handleCheckboxChange}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div className="my-auto" onClick={()=>navigate(`/app/card-detail/${data?.item?._id}`)}>
                    <img
                      className="youtubeshared-content"
                      src={
                        data?.item?.cardType
                          ? data?.item?.cardType == "twitter"
                            ? twitterlogo
                            : data?.item?.cardType == "facebook" ||
                              data?.item?.cardType == "Facebook"
                            ?  data?.item?.postDetail
                            ? data?.item?.postDetail?.imageUrl
                            : FbIcon
                            : data?.item?.cardType == "screenshoot"
                            ? ZimzeeIcon
                            : data?.item?.cardType == "fullScreen"
                            ? FullScreen
                            : data?.item?.cardType == "partScreen"
                            ? RegionalScreen
                            : data?.item?.cardType == "highlightText"
                            ? Highlight
                            : data?.item?.cardType == "youtube"
                            ? data?.item?.postDetail
                            ? data?.item?.postDetail?.imageUrl
                            : youtubeIcon
                            : data?.item?.cardType == "TikTok"
                            ? data?.item?.postDetail
                            ? data?.item?.postDetail?.imageUrl
                            : tiktokIcon
                            : data?.item?.cardType == "instagram"
                            ? data?.item?.postDetail
                            ? data?.item?.postDetail?.imageUrl
                            : instagramIcon
                            : data?.item?.cardType == "bookmark"
                            ? BookmarkDetailIcon
                            : defaultUser
                          : "" 
                      }
                      alt="youtube"
                    />
                  </div>
                  <div className="my-auto" style={{cursor:"pointer"}} onClick={()=>navigate(`/app/card-detail/${data?.item?._id}`)}>
                    <div className="tooltip">
                      <span className="tooltip-text bucketshrename">
                        <p className="block text-backgroundshare">
                          {data?.item?.title}
                        </p>
                      </span>
                      <h6 className="bucket-title">
                        {data?.item?.title}
                      </h6>
                    </div>

                    {/* <p className="bucket-name">Fashion Bucket</p> */}
                  </div>

                  {/* <div className="shared-whatishared">
                    <h6 className="td-head">Shared on</h6>

                    <p className="td-count ">
                      {moment(data?.createdAt).format("MMM D, YYYY @ hh:mm A")}
                    </p>
                  </div> */}
                  <div>
                    <h6 className="td-head">Views</h6>
                    <p className="td-count ">{data?.views}</p>
                  </div>
                  <div>
                    <h6 className="td-head">Clicks</h6>
                    <p className="td-count ">{data?.clicks}</p>
                  </div>
                  <div className="flex my-[auto]">
                    {/* <div className="tooltip"> 
               <span className="tooltip-text bucketsshare">
                 <p className="block text-background">{`https://staging.zimzee.com/public/card/${data?.item?._id}`}</p>
               </span> */}
                    {/* <h6><p className="link-td">Go-To URL</p></h6> */}
                    {/* </div> */}
                    <a
                      href={`https://share.zimzee.com/public/card/${data?.item?._id}`}
                      target="_blank"
                    >
                      {" "}
                      <img
                        className="ml-[20px] share-icon"
                        src={Shareicon}
                        alt="shareicon"
                      />
                    </a>
                  </div>
                  <div className="flex">
                    <p className="flex justify-spacebetween">
                      <img
                        className="publiclink-imgcontent"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "7px",
                          marginLeft: "10px",
                        }}
                        src={linkIcon}
                        alt="link-icon"
                        onClick={() => {
                          handleCardLink(data?.item?._id);
                        }}
                      />
                    </p>
                  </div>
                  <div className="my-[auto]">
                    {data?.isActive === true ? (
                      <button className="class-active"></button>
                    ) : (
                      <button className="class-inactive"></button>
                    )}
                    {/* <button className="class-active">Active</button> */}
                  </div>
                  <div className="my-[auto]">
                    <Menu as="div" className="relative flex-shrink-0">
                      <div>
                        <Menu.Button className="flex rounded-full focus:outline-none ">
                          <div
                            href="#"
                            className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                          >
                            <strong>
                              <FontAwesomeIcon
                                className="vertical-iconcontent text-[#707070] text-[22px]"
                                icon={faEllipsisVertical}
                              />
                            </strong>
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-30 text-left  origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleFilterSelectText(
                                    data?.isActive ? "Deactivate" : "Activate",
                                    data?._id
                                  );
                                }}
                                //  href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-[13px] text-gray-700"
                                )}
                              >
                                {data?.isActive ? "Deactivate" : "Activate"}
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleFilterSelectText("Delete", data?._id);
                                }}
                                //  href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-[13px] text-gray-700"
                                )}
                              >
                                Delete
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              ) : null;
            })}
            {/* <div className="flex justify-between mt-[32px] my-auto">
              <div className=" my-auto">
                <input
                  id="default-checkboxs"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
              <div className="ml-[14px]  my-auto">
                <img
                  className="youtubeshared-content"
                  src={Youtube}
                  alt="youtube"
                />
              </div>
              <div className="ml-[14px] my-auto">
                <h6 className="bucket-title">UX/UI Design Trends 2022</h6>
                <p className="bucket-name">Fashion Bucket</p>
                <p className="link-td">
                  https://material.io/components/tabs#theming
                </p>
                <button className="class-active">Active</button>
              </div>
              <div className="ml-[40px] ">
                <h6 className="td-head">Views</h6>
                <p className="td-count ">28</p>
              </div>
              <div className="ml-[40px]">
                <h6 className="td-head">Clicks</h6>
                <p className="td-count ">50</p>
              </div>
              <div className="ml-[30px] my-[auto]">
                <Menu as="div" className="relative flex-shrink-0">
                  <div>
                    <Menu.Button className="flex rounded-full focus:outline-none ">
                      <div
                        href="#"
                        className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                      >
                        <strong>
                          <FontAwesomeIcon
                            className="vertical-iconcontent text-[#707070] text-[22px] mr-4"
                            icon={faEllipsisVertical}
                          />
                        </strong>
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-30 text-left  origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {filterDropDownselect.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                handleFilterSelectText(item.name);
                              }}
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-[13px] text-gray-700"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="flex justify-between mt-[32px] my-auto">
              <div className=" my-auto">
                <input
                  id="default-checkboxs"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
              <div className="ml-[14px]  my-auto">
                <img src={Twitter} alt="twitter" />
              </div>
              <div className="ml-[14px] my-auto">
                <h6 className="bucket-title">
                  Save the internet with Trends 2022
                </h6>
                <p className="bucket-name">Fashion Trends</p>
                <p className="link-td">
                  https://material.io/components/tabs#theming
                </p>
                <button className="class-active">Active</button>
              </div>
              <div className="ml-[40px] ">
                <h6 className="td-head">Views</h6>
                <p className="td-count ">28</p>
              </div>
              <div className="ml-[40px]">
                <h6 className="td-head">Clicks</h6>
                <p className="td-count ">50</p>
              </div>
              <div className="ml-[30px] my-[auto]">
                <Menu as="div" className="relative flex-shrink-0">
                  <div>
                    <Menu.Button className="flex rounded-full focus:outline-none ">
                      <div
                        href="#"
                        className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                      >
                        <strong>
                          <FontAwesomeIcon
                            className="vertical-iconcontent text-[#707070] text-[22px] mr-4"
                            icon={faEllipsisVertical}
                          />
                        </strong>
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-30 text-left  origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {filterDropDownselect.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                handleFilterSelectText(item.name);
                              }}
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-[13px] text-gray-700"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="flex justify-between mt-[32px] my-auto">
              <div className=" my-auto">
                <input
                  id="default-checkboxs"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
              <div className="ml-[14px]  my-auto">
                <img src={Instagram} alt="instagram" />
              </div>
              <div className="ml-[14px] my-auto">
                <h6 className="bucket-title">UX/UI Design Trends 2022</h6>
                <p className="bucket-name">Fashion Bucket</p>
                <p className="link-td">
                  https://material.io/components/tabs#theming
                </p>
                <button className="class-active">Active</button>
              </div>
              <div className="ml-[40px] ">
                <h6 className="td-head">Views</h6>
                <p className="td-count ">28</p>
              </div>
              <div className="ml-[40px]">
                <h6 className="td-head">Clicks</h6>
                <p className="td-count ">50</p>
              </div>
              <div className="ml-[30px] my-[auto]">
                <Menu as="div" className="relative flex-shrink-0">
                  <div>
                    <Menu.Button className="flex rounded-full focus:outline-none ">
                      <div
                        href="#"
                        className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                      >
                        <strong>
                          <FontAwesomeIcon
                            className="vertical-iconcontent text-[#707070] text-[22px] mr-4"
                            icon={faEllipsisVertical}
                          />
                        </strong>
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-30 text-left  origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {filterDropDownselect.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                handleFilterSelectText(item.name);
                              }}
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-[13px] text-gray-700"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="flex justify-between mt-[32px] my-auto">
              <div className=" my-auto">
                <input
                  id="default-checkboxs"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
              <div className="ml-[14px]  my-auto">
                <img src={Wikipedia} alt="wikipedia" />
              </div>
              <div className="ml-[14px] my-auto">
                <h6 className="bucket-title">UX/UI Design Trends 2022</h6>
                <p className="bucket-name">Fashion Bucket</p>
                <p className="link-td">
                  https://material.io/components/tabs#theming
                </p>
                <button className="class-active">Active</button>
              </div>
              <div className="ml-[40px] ">
                <h6 className="td-head">Views</h6>
                <p className="td-count ">28</p>
              </div>
              <div className="ml-[40px]">
                <h6 className="td-head">Clicks</h6>
                <p className="td-count ">50</p>
              </div>
              <div className="ml-[30px] my-[auto]">
                <Menu as="div" className="relative flex-shrink-0">
                  <div>
                    <Menu.Button className="flex rounded-full focus:outline-none ">
                      <div
                        href="#"
                        className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                      >
                        <strong>
                          <FontAwesomeIcon
                            className="vertical-iconcontent text-[#707070] text-[22px] mr-4"
                            icon={faEllipsisVertical}
                          />
                        </strong>
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-30 text-left  origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {filterDropDownselect.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                handleFilterSelectText(item.name);
                              }}
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-[13px] text-gray-700"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div> */}
          </div>
        </TabPanel>
        <TabPanel>
        <div className="flex">
            <div className="flex">
            
              {SelectedCardId?.length ?<img
                className=" mr-[10px] px-3"
                src={appTheme==="light"?Trashs:LightDelete}
                alt="delete"
                onClick={deleteCard}
              />:<img
              className=" mr-[10px] px-3"
              src={appTheme==="light"?LightDelete:Trashs}
              alt="delete"
            />}
              {" "}
              <span
                className={
                  SelectedCardId?.length ? `delete-shares` : `delete-share`
                }
                onClick={SelectedCardId?.length ? deleteCard: null}
                style={{ cursor: "pointer" }}
              >
                Delete
              </span>
            </div>
            <div className="ml-auto">
              <div className="ml-[auto] flex items-center justify-end">
                <span className="sorttext-content mx-3 dark:text-[#acacac]">
                  Sort By
                </span>
                <Menu
                  as="div"
                  className="relative flex-shrink-0"
                >
                  <div>
                    <Menu.Button className="flex rounded-full focus:outline-none ">
                      <div
                        href="#"
                        className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                      >
                        <strong className="createdtext-content pr-4">
                          {filtersText}
                        </strong>
                        <FontAwesomeIcon
                          style={{ fontSize: "12px", color: "#707070" }}
                          icon={faChevronDown}
                        />
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {filterDropDown.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                handleFilterText(item.name);
                              }}
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-[13px] text-gray-700"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
                <Transition appear show={DeleteOpen} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={closeDeleteCard}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              Delete
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to delete?
                              </p>
                            </div>

                            <div className="mt-4">
                              <button
                                style={{
                                  background: "#FF6600",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={handleDeleteCard}
                              >
                                Yes
                              </button>
                              <button
                                style={{
                                  background: "#000",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={closeDeleteCard}
                              >
                                No
                              </button>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
                <Transition appear show={DeactiveOpen} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={closeDeactiveCard}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              Deactivate
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to Deactivate?
                              </p>
                            </div>

                            <div className="mt-4">
                              <button
                                style={{
                                  background: "#FF6600",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={handleDeactivateCard}
                              >
                                Yes
                              </button>
                              <button
                                style={{
                                  background: "#000",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={closeDeactiveCard}
                              >
                                No
                              </button>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
                <Transition appear show={ActiveOpen} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={closeActiveCard}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              Activate
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to Activate?
                              </p>
                            </div>

                            <div className="mt-4">
                              <button
                                style={{
                                  background: "#FF6600",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={handleActiveCard}
                              >
                                Yes
                              </button>
                              <button
                                style={{
                                  background: "#000",
                                  width: "80px",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "50px",
                                }}
                                type="button"
                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={closeActiveCard}
                              >
                                No
                              </button>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition> 
              </div>
            </div>
          </div>
          <div className="shareddesktop-section">
            {sharedBucketData?.map((data) => {
              return data?.item !== null ? (
                <div className="flex mt-[32px] my-auto" key={data?._id}>
                  <div className="my-auto checkshared-content checkbox-shared">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      value={data?._id}
                      onChange={handleCheckboxChange}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div className="my-auto sharedtitle-content name-whatishared" style={{cursor:"pointer"}} onClick={()=>navigate(`/public/bucket/${data?.item?._id}`)}>
                    <div className="tooltip">
                      <span className="tooltip-text bucketshrename">
                        <p className="block text-backgroundshare">
                          {data?.item?.name}
                        </p>
                      </span>
                      <h6 className="bucket-title">
                        {data?.item?.name}
                      </h6>
                    </div>

                    {/* <p className="bucket-name">Fashion Bucket</p> */}
                  </div>

                  <div className="shared-whatishared">
                    <h6 className="td-head">Shared on</h6>

                    <p className="td-count ">
                      {moment(data?.createdAt).format("MMM D, YYYY @ hh:mm A")}
                    </p>
                  </div>
                  <div className="views-whatishared">
                    <h6 className="td-head">Views</h6>
                    <p className="td-count ">{data?.views}</p>
                  </div>
                  <div className="clicks-whatishared">
                    <h6 className="td-head">Clicks</h6>
                    <p className="td-count ">{data?.clicks}</p>
                  </div>
                  <div className="flex my-[auto] shareditem-content goto-whatishared">
                    {/* <div className="tooltip"> 
               <span className="tooltip-text bucketsshare">
                 <p className="block text-background">{`https://staging.zimzee.com/public/card/${data?.item?._id}`}</p>
               </span> */}
                    {/* <h6><p className="link-td">Go-To URL</p></h6> */}
                    {/* </div> */}
                    <a
                      href={`https://share.zimzee.com/public/bucket/${data?.item?._id}`}
                      target="_blank"
                    >
                      {" "}
                      <img
                        className="ml-[20px] share-icon"
                        src={Shareicon}
                        alt="shareicon"
                      />
                    </a>
                  </div>
                  <div className="flex shareditem-content copyto-whatishared">
                    <p className="flex justify-spacebetween">
                      <img
                        className="publiclink-imgcontent"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "7px",
                          marginLeft: "10px",
                        }}
                        src={linkIcon}
                        alt="link-icon"
                        onClick={() => {
                          handleBucketLink(data?.item?._id);
                        }}
                      />
                    </p>
                  </div>
                  <div className="my-[auto] active-whatishared">
                    {data?.isActive === true ? (
                      <button className="class-active">Active</button>
                    ) : (
                      <button className="class-inactive">Inactive</button>
                    )}
                    {/* <button className="class-active">Active</button> */}
                  </div>
                  <div className="my-[auto] threedot-whatishared">
                    <Menu as="div" className="relative flex-shrink-0">
                      <div>
                        <Menu.Button className="flex rounded-full focus:outline-none ">
                          <div
                            href="#"
                            className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                          >
                            <strong>
                              <FontAwesomeIcon
                                className="vertical-iconcontent text-[#707070] text-[22px]"
                                icon={faEllipsisVertical}
                              />
                            </strong>
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-30 text-left  origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleFilterSelectText(
                                    data?.isActive ? "Deactivate" : "Activate",
                                    data?._id
                                  );
                                }}
                                //  href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-[13px] text-gray-700"
                                )}
                              >
                                {data?.isActive ? "Deactivate" : "Activate"}
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleFilterSelectText("Delete", data?._id);
                                }}
                                //  href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-[13px] text-gray-700"
                                )}
                              >
                                Delete
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              ) : null;
            })}
          </div>

          <div className="sharedmbl-section">
          {sharedBucketData?.map((data) => {
              return data?.item !== null ? (
                <div className="flex justify-between mt-[32px] my-auto padding-auto" key={data?._id}>
                  <div className="my-auto">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      value={data?._id}
                      onChange={handleCheckboxChange}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div className="my-auto" style={{cursor:"pointer"}} onClick={()=>navigate(`/public/bucket/${data?.item?._id}`)}>
                    <div className="tooltip">
                      <span className="tooltip-text bucketshrename">
                        <p className="block text-backgroundshare">
                          {data?.item?.name}
                        </p>
                      </span>
                      <h6 className="bucket-title">
                          {data?.item?.name}
                      </h6>
                    </div>

                    {/* <p className="bucket-name">Fashion Bucket</p> */}
                  </div>

                  {/* <div className="shared-whatishared">
                    <h6 className="td-head">Shared on</h6>

                    <p className="td-count ">
                      {moment(data?.createdAt).format("MMM D, YYYY @ hh:mm A")}
                    </p>
                  </div> */}
                  <div>
                    <h6 className="td-head">Views</h6>
                    <p className="td-count ">{data?.views}</p>
                  </div>
                  <div>
                    <h6 className="td-head">Clicks</h6>
                    <p className="td-count ">{data?.clicks}</p>
                  </div>
                  <div className="flex my-[auto]">
                    {/* <div className="tooltip"> 
               <span className="tooltip-text bucketsshare">
                 <p className="block text-background">{`https://staging.zimzee.com/public/card/${data?.item?._id}`}</p>
               </span> */}
                    {/* <h6><p className="link-td">Go-To URL</p></h6> */}
                    {/* </div> */}
                    <a
                      href={`https://share.zimzee.com/public/bucket/${data?.item?._id}`}
                      target="_blank"
                    >
                      {" "}
                      <img
                        className="ml-[20px] share-icon"
                        src={Shareicon}
                        alt="shareicon"
                      />
                    </a>
                  </div>
                  <div className="flex">
                    <p className="flex justify-spacebetween">
                      <img
                        className="publiclink-imgcontent"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "7px",
                          marginLeft: "10px",
                        }}
                        src={linkIcon}
                        alt="link-icon"
                        onClick={() => {
                          handleBucketLink(data?.item?._id);
                        }}
                      />
                    </p>
                  </div>
                  <div className="my-[auto]">
                    {data?.isActive === true ? (
                      <button className="class-active"></button>
                    ) : (
                      <button className="class-inactive"></button>
                    )}
                    {/* <button className="class-active">Active</button> */}
                  </div>
                  <div className="my-[auto]">
                    <Menu as="div" className="relative flex-shrink-0">
                      <div>
                        <Menu.Button className="flex rounded-full focus:outline-none ">
                          <div
                            href="#"
                            className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                          >
                            <strong>
                              <FontAwesomeIcon
                                className="vertical-iconcontent text-[#707070] text-[22px]"
                                icon={faEllipsisVertical}
                              />
                            </strong>
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-30 text-left  origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleFilterSelectText(
                                    data?.isActive ? "Deactivate" : "Activate",
                                    data?._id
                                  );
                                }}
                                //  href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-[13px] text-gray-700"
                                )}
                              >
                                {data?.isActive ? "Deactivate" : "Activate"}
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleFilterSelectText("Delete", data?._id);
                                }}
                                //  href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-[13px] text-gray-700"
                                )}
                              >
                                Delete
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              ) : null;
            })}
            {/* <div className="flex justify-between mt-[32px] my-auto">
              <div className=" my-auto">
                <input
                  id="default-checkboxs"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
              <div className="ml-[14px]  my-auto">
                <img
                  className="youtubeshared-content"
                  src={Youtube}
                  alt="youtube"
                />
              </div>
              <div className="ml-[14px] my-auto">
                <h6 className="bucket-title">UX/UI Design Trends 2022</h6>
                <p className="bucket-name">Fashion Bucket</p>
                <p className="link-td">
                  https://material.io/components/tabs#theming
                </p>
                <button className="class-active">Active</button>
              </div>
              <div className="ml-[40px] ">
                <h6 className="td-head">Views</h6>
                <p className="td-count ">28</p>
              </div>
              <div className="ml-[40px]">
                <h6 className="td-head">Clicks</h6>
                <p className="td-count ">50</p>
              </div>
              <div className="ml-[30px] my-[auto]">
                <Menu as="div" className="relative flex-shrink-0">
                  <div>
                    <Menu.Button className="flex rounded-full focus:outline-none ">
                      <div
                        href="#"
                        className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                      >
                        <strong>
                          <FontAwesomeIcon
                            className="vertical-iconcontent text-[#707070] text-[22px] mr-4"
                            icon={faEllipsisVertical}
                          />
                        </strong>
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-30 text-left  origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {filterDropDownselect.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                handleFilterSelectText(item.name);
                              }}
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-[13px] text-gray-700"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="flex justify-between mt-[32px] my-auto">
              <div className=" my-auto">
                <input
                  id="default-checkboxs"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
              <div className="ml-[14px]  my-auto">
                <img src={Twitter} alt="twitter" />
              </div>
              <div className="ml-[14px] my-auto">
                <h6 className="bucket-title">
                  Save the internet with Trends 2022
                </h6>
                <p className="bucket-name">Fashion Trends</p>
                <p className="link-td">
                  https://material.io/components/tabs#theming
                </p>
                <button className="class-active">Active</button>
              </div>
              <div className="ml-[40px] ">
                <h6 className="td-head">Views</h6>
                <p className="td-count ">28</p>
              </div>
              <div className="ml-[40px]">
                <h6 className="td-head">Clicks</h6>
                <p className="td-count ">50</p>
              </div>
              <div className="ml-[30px] my-[auto]">
                <Menu as="div" className="relative flex-shrink-0">
                  <div>
                    <Menu.Button className="flex rounded-full focus:outline-none ">
                      <div
                        href="#"
                        className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                      >
                        <strong>
                          <FontAwesomeIcon
                            className="vertical-iconcontent text-[#707070] text-[22px] mr-4"
                            icon={faEllipsisVertical}
                          />
                        </strong>
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-30 text-left  origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {filterDropDownselect.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                handleFilterSelectText(item.name);
                              }}
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-[13px] text-gray-700"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="flex justify-between mt-[32px] my-auto">
              <div className=" my-auto">
                <input
                  id="default-checkboxs"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
              <div className="ml-[14px]  my-auto">
                <img src={Instagram} alt="instagram" />
              </div>
              <div className="ml-[14px] my-auto">
                <h6 className="bucket-title">UX/UI Design Trends 2022</h6>
                <p className="bucket-name">Fashion Bucket</p>
                <p className="link-td">
                  https://material.io/components/tabs#theming
                </p>
                <button className="class-active">Active</button>
              </div>
              <div className="ml-[40px] ">
                <h6 className="td-head">Views</h6>
                <p className="td-count ">28</p>
              </div>
              <div className="ml-[40px]">
                <h6 className="td-head">Clicks</h6>
                <p className="td-count ">50</p>
              </div>
              <div className="ml-[30px] my-[auto]">
                <Menu as="div" className="relative flex-shrink-0">
                  <div>
                    <Menu.Button className="flex rounded-full focus:outline-none ">
                      <div
                        href="#"
                        className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                      >
                        <strong>
                          <FontAwesomeIcon
                            className="vertical-iconcontent text-[#707070] text-[22px] mr-4"
                            icon={faEllipsisVertical}
                          />
                        </strong>
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-30 text-left  origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {filterDropDownselect.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                handleFilterSelectText(item.name);
                              }}
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-[13px] text-gray-700"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="flex justify-between mt-[32px] my-auto">
              <div className=" my-auto">
                <input
                  id="default-checkboxs"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
              <div className="ml-[14px]  my-auto">
                <img src={Wikipedia} alt="wikipedia" />
              </div>
              <div className="ml-[14px] my-auto">
                <h6 className="bucket-title">UX/UI Design Trends 2022</h6>
                <p className="bucket-name">Fashion Bucket</p>
                <p className="link-td">
                  https://material.io/components/tabs#theming
                </p>
                <button className="class-active">Active</button>
              </div>
              <div className="ml-[40px] ">
                <h6 className="td-head">Views</h6>
                <p className="td-count ">28</p>
              </div>
              <div className="ml-[40px]">
                <h6 className="td-head">Clicks</h6>
                <p className="td-count ">50</p>
              </div>
              <div className="ml-[30px] my-[auto]">
                <Menu as="div" className="relative flex-shrink-0">
                  <div>
                    <Menu.Button className="flex rounded-full focus:outline-none ">
                      <div
                        href="#"
                        className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                      >
                        <strong>
                          <FontAwesomeIcon
                            className="vertical-iconcontent text-[#707070] text-[22px] mr-4"
                            icon={faEllipsisVertical}
                          />
                        </strong>
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-30 text-left  origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {filterDropDownselect.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={() => {
                                handleFilterSelectText(item.name);
                              }}
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block py-2 px-4 text-[13px] text-gray-700"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div> */}
          </div>
        </TabPanel>
      </Tabs>
      <Transition appear show={openCopyLink} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={closeCopyLinkModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Copy and Paste this URL
                        </Dialog.Title>
                        <div
                          style={{ display: "block" }}
                          className="custom-list-dropdown"
                        >
                          <div className="position-relative">
                            <input
                              type="text"
                              placeholder="URL"
                              value={publicUrl}
                              className="publicUrlInput"
                              // onChange={handleFileInput}
                            />
                          </div>
                          <div className="flex justify-end">
                            <div
                              className="cancelfilecard-content"
                              onClick={closeCopyLinkModal}
                            >
                              Cancel
                            </div>
                            <button
                              style={{
                                filter:
                                  "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                              }}
                              className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white"
                              onClick={() => handleCopyText(publicUrl)}
                            >
                              <span className="savebutton-context">Copy</span>
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
      <ToastContainer />
    </div>
  );
}
export default WhatIShared;
