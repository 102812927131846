import { React, useEffect, useState } from "react";
import "../Bucket.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import EmojiPicker from "emoji-picker-react";
import {
  faCoffee,
  faStar,
  faHeart,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { SketchPicker } from "react-color";
import ColorPicker from "../../components/ColorPicker";
import pattern1 from "../../assets/images/Pattern 1.png";
import pattern2 from "../../assets/images/Pattern 2.png";
import pattern3 from "../../assets/images/Pattern 3.png";
import pattern4 from "../../assets/images/Pattern 4.png";
import pattern5 from "../../assets/images/Pattern 5.png";
import pattern6 from "../../assets/images/Pattern 6.png";
import Color from "../../assets/icons/Color.svg";
import search from "../../assets/icons/Color selector.svg";
import Image from "../../assets/icons/image (1) 1.svg";
import Icon from "../../assets/icons/shapes 1.svg";
import Face from "../../assets/icons/happy (1) 1.svg";
import image1 from "../../assets/images/Rectangle 2996.png";
import image2 from "../../assets/images/Rectangle 2997.png";
import image3 from "../../assets/images/Rectangle 2998.png";
import image4 from "../../assets/images/Rectangle 2999.png";
import image5 from "../../assets/images/Rectangle 3000.png";
import image6 from "../../assets/images/Rectangle 3001.png";
import image7 from "../../assets/images/Rectangle 3002.png";
import image8 from "../../assets/images/Rectangle 3003.png";
import image9 from "../../assets/images/Rectangle 3005.png";
import image10 from "../../assets/images/Rectangle 3006.png";
import image11 from "../../assets/images/Rectangle 3007.png";
import image12 from "../../assets/images/Rectangle 3008.png";
import image13 from "../../assets/images/Rectangle 3009.png";
import image14 from "../../assets/images/Rectangle 3010.png";
import icon1 from "../../assets/icons/Vector (3).svg";
import icon2 from "../../assets/icons/Vector (4).svg";
import icon3 from "../../assets/icons/Group (3).svg";
import icon4 from "../../assets/icons/path11 (1).svg";
import icon5 from "../../assets/icons/Vector (5).svg";
import icon6 from "../../assets/icons/Group (4).svg";
import shareIcon from "../../assets/icons/bucketShareIcon.svg";
import pinIcon from "../../assets/icons/pin-yellow.svg";
import unpinIcon from "../../assets/icons/Pin.svg";
import ThreeDots from "../../assets/icons/threeDots.svg";
import Emoji from "../../assets/icons/emoji.svg";
import Profile1 from "../../assets/icons/profile1.png";
import Profile2 from "../../assets/icons/profile2.png";
import Profile3 from "../../assets/icons/profile3.png";
import Faces from "../../assets/icons/smiling-face-with-heart-shaped-eyes_1f60d.svg";
import button from "../../assets/icons/Group 60985.svg";
import Close from "../../assets/icons/Vector (6).svg";
import {
  faFolder as fasFaFolder,
  faFile as fasFaFile,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Frame from "../../assets/icons/Frame.png";
import { SwatchesPicker } from "react-color";
import { margin } from "@mui/system";

function Popup({ selectedBucketName, selectedBucketId }) {
  const [selectedEmoji, setSelectedEmoji] = useState([]);
  const [background, setBackground] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageLibrary, setImageLibrary] = useState([]);
  const [RefreshImgLibrary, setRefreshImgLibrary] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  useEffect(() => {
    // api url

    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API}buckets/user-library-images`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setImageLibrary(response?.data?.data);
        console.log(response);
        setRefreshImgLibrary(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [RefreshImgLibrary]);
  useEffect(() => {
    console.log(selectedEmoji.emoji, "selected emojiiiiiiiii");
    console.log(
      { ...selectedIcon, color: "#dddd" },
      "selected iconnnnnnnnnnnnnnnnnnn"
    );
    console.log(background, "selected colorrrrrrrrr");
  }, [selectedEmoji, selectedIcon, background]);
  const SaveUpdateBucket = () => {};
  const handleChangeComplete = (color) => {
    console.log(color.hex, "selected colorrrr");
    setBackground(color.hex);
    setSelectedColor(color.hex);
  };

  const handleColorChange = (event) => {
    const newColor = event.target.value;
    setSelectedColor(newColor);
  };
  const handleImageSelect = async (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    console.log(file, "selected fileeeeeeee");
    if (file) {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("imageFile", file);
      try {
        const response = await axios.post(
          "https://api.zimzee.com/api/buckets/upload-library-image",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setRefreshImgLibrary(true);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const iconList = [
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    { icon: faCoffee, name: "Coffee" },
    { icon: faStar, name: "Star" },
    { icon: faHeart, name: "Heart" },
    { icon: faCircle, name: "Circle" },
    // Add more icons here
  ];
  const sampleImg = [
    { url: image1 },
    { url: image2 },
    { url: image3 },
    { url: image4 },
    { url: image5 },
    { url: image6 },
    { url: image7 },
    { url: image8 },
    { url: image9 },
    { url: image10 },
  ];
  const handleEmojiSelect = (emoji) => {
    console.log(emoji, "emojiiiiiiiiiiiiiiiiiii");
    setSelectedEmoji(emoji);
  };

  const handleImageClick = (img) => {
    setBackground(img);
  };
  const handleIconSelect = (icon) => {
    console.log(icon, "sel icon icon");
    setSelectedIcon(icon);
  };
  return (
    <div className="popup-upload customize flex">
      <div className="w-[65%] dark:bg-[#0A1D42] left customize-bucket-tabs">
        <h3 className="px-[20px]">Customize Bucket</h3>
        <Tabs>
          <TabList className="justify-between px-[20px]">
            <Tab>
              <img src={Color} /> Color
            </Tab>
            <Tab>
              <img src={Image} /> Images
            </Tab>
            <Tab>
              <img src={Icon} /> Icons
            </Tab>
            <Tab>
              <img src={Face} />
              Emojis
            </Tab>
          </TabList>

          <TabPanel>
            <div className="flex justify-between pb-[5px]">
              <div>
                {/* <form className="search-color " >
  <input type="text" placeholder="Custom color code e.g #000000" name="search" />
  <button type="submit">Select</button>
</form> */}
                <div className="searchwhole-content flex item-center ">
                  <div className="w-full">
                    <label
                      htmlFor="search"
                      className="dark:border-white-700 sr-only"
                    >
                      Search
                    </label>
                    <div className="relative">
                      <input
                        id="search"
                        value={
                          background.includes("http") ? null : selectedColor
                        }
                        name="search"
                        className="dark:bg-[#202F4F] dark:text-white search-input block w-full rounded-3xl border-0 bg-[#F5F5F5] py-2.5 pr-3 text-gray-900 placeholder:text-gray-400 focus:outline-none m:text-sm sm:leading-6"
                        placeholder="eg:#000000"
                        type="search"
                      />
                      <div className="input-color-picker">
                        <label>
                          <img
                            className="magnifier"
                            src={search}
                            alt="magnifying-glass"
                          />
                          <input
                            type="color"
                            className="d-none"
                            name="favcolor"
                            onChange={handleColorChange}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recent-color flex">
                <p className="pr-[8px]">Recently Used Colors</p>
                <div className="bg-blue-800 "></div>
                <div className="bg-orange-800 "></div>
                <div className="bg-amber-100"></div>
                <div className="bg-teal-300"></div>
              </div>
            </div>
            <div className="color-upload">
              <SwatchesPicker onChangeComplete={handleChangeComplete} />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="bgimage-upload">
              <div className="grid">
                <div className="image-upload" style={{ position: "relative" }}>
                  <label
                    for="file-input"
                    class="custom-button"
                    style={{ cursor: "pointer" }}
                  >
                    <img className="pt-[10px] text-center" src={Frame} />
                    <h6 className="my-[3px]">Select Files</h6>
                    <p className="mb-[20px]">
                      Recommended image size 1920x1080
                    </p>
                  </label>
                  <input
                    type="file"
                    id="file-input"
                    style={{ display: "none" }}
                    onChange={handleImageSelect}
                  />
                </div>
                {imageLibrary?.map((img, index) => (
                  <div
                    key={index}
                    onClick={() => handleImageClick(img.s3Url)}
                    className="grid-box-image"
                  >
                    <img src={img.s3Url} alt="pattern-image" />
                  </div>
                ))}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="icon-upload icon-upload-wrapper">
              {iconList.map((item, index) => (
                <div
                  key={index}
                  className={`icon-item ${
                    selectedIcon === item.icon ? "selected" : ""
                  }`}
                  onClick={() => handleIconSelect(item.icon)}
                >
                  <FontAwesomeIcon icon={item.icon} />
                </div>
              ))}
              {/* <div className="grid">
      <div><img src={icon6} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon3} alt="pattern-image" /></div>
        <div><img src={icon4} alt="pattern-image" /></div>
        <div><img src={icon5} alt="pattern-image" /></div>
        <div><img src={icon6} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon3} alt="pattern-image" /></div>
        <div><img src={icon4} alt="pattern-image" /></div>
        <div><img src={icon5} alt="pattern-image" /></div>
        <div><img src={icon6} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon3} alt="pattern-image" /></div>
        <div><img src={icon4} alt="pattern-image" /></div>
        <div><img src={icon5} alt="pattern-image" /></div>
        <div><img src={icon6} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>      
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon3} alt="pattern-image" /></div>
        <div><img src={icon4} alt="pattern-image" /></div>
        <div><img src={icon5} alt="pattern-image" /></div>
        <div><img src={icon6} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon6} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>      
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon3} alt="pattern-image" /></div>
        <div><img src={icon4} alt="pattern-image" /></div>
        <div><img src={icon5} alt="pattern-image" /></div>
        <div><img src={icon6} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon3} alt="pattern-image" /></div>
        <div><img src={icon4} alt="pattern-image" /></div>
        <div><img src={icon5} alt="pattern-image" /></div>
        <div><img src={icon6} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon3} alt="pattern-image" /></div>
        <div><img src={icon4} alt="pattern-image" /></div>
        <div><img src={icon5} alt="pattern-image" /></div>
        <div><img src={icon6} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon3} alt="pattern-image" /></div>
        <div><img src={icon4} alt="pattern-image" /></div>
        <div><img src={icon5} alt="pattern-image" /></div>
        <div><img src={icon6} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon3} alt="pattern-image" /></div>
        <div><img src={icon4} alt="pattern-image" /></div>
        <div><img src={icon5} alt="pattern-image" /></div>
        <div><img src={icon6} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
        <div><img src={icon3} alt="pattern-image" /></div>
        <div><img src={icon4} alt="pattern-image" /></div>
        <div><img src={icon5} alt="pattern-image" /></div>
        <div><img src={icon6} alt="pattern-image" /></div>
        <div><img src={icon1} alt="pattern-image" /></div>
        <div><img src={icon2} alt="pattern-image" /></div>
      </div> */}
            </div>
          </TabPanel>

          <TabPanel>
            <div className="emojis">
              {" "}
              <EmojiPicker onEmojiClick={handleEmojiSelect} />
            </div>
          </TabPanel>
        </Tabs>
        <div className="save-name flex justify-between">
          <p className="my-auto">
            <input
              id="link-checkbox"
              type="checkbox"
              value=""
              className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            Apply this setting to all my buckets.
          </p>
          <button>Save</button>
        </div>
      </div>
      <div className="w-[35%] right bg-[#FFF6F0] dark:bg-[#112750]">
        <h3>Preview</h3>
        <p className="filecustom-content mt-2">File uploads</p>

        <div className="card p-[15px]">
          <div className="" style={{ height: "auto" }}>
            <div className="px-4 pt-2 pb-2 sm:px-3">
              <div className="flex">
                <div>
                  <img
                    className="dark-icon-clr"
                    src={unpinIcon}
                    alt="pattern"
                  />
                </div>
                <div className="ml-[auto] flex">
                  <img
                    className="pr-[10px] dark-icon-clr"
                    src={shareIcon}
                    alt="pattern"
                  />
                  <img
                    className="dark-icon-clr"
                    src={ThreeDots}
                    alt="pattern"
                  />
                </div>
              </div>

              <a href="javascript:void(0);">
                <div
                  className="gradient-wholebackground rounded-lg cardlist-content"
                  style={{
                    background:
                      background && background.includes("http")
                        ? `url(${background})`
                        : selectedColor,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "auto",
                  }}
                >
                  <p className="block text-background">{selectedBucketName}</p>
                  <div className="icon-emoji-collage">
                    <span className="emoji-icon-inner">
                      {selectedEmoji.emoji}
                    </span>
                    <span
                      className="svg-icon-inner"
                      style={{
                        background: selectedColor ? selectedColor : "#74CFFB",
                      }}
                    >
                      <FontAwesomeIcon icon={selectedIcon} />
                    </span>{" "}
                  </div>
                </div>
              </a>
              <div className="innerBucketDetails grid grid-cols-3 pt-[4px]">
                <div
                  className="shareCount col-span-2"
                  style={{ visibility: "visible" }}
                >
                  <div className="profile-imgcontent">
                    <img src={Profile1} alt="profile1" />
                    <img src={Profile2} alt="profile2" />
                    <img src={Profile3} alt="profile3" />
                  </div>
                  <p className="share-content dark:text-[#d3d3d3]">
                    0 Share(s)
                  </p>
                </div>
                <div className="bucketsinside flex justify-end">
                  <div
                    className="relative px-3"
                    style={{ visibility: "visible" }}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="far"
                      data-icon="folder"
                      className="svg-inline--fa fa-folder text-xl text-[#9D9D9D]"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M0 96C0 60.7 28.7 32 64 32H196.1c19.1 0 37.4 7.6 50.9 21.1L289.9 96H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16H286.6c-10.6 0-20.8-4.2-28.3-11.7L213.1 87c-4.5-4.5-10.6-7-17-7H64z"
                      ></path>
                    </svg>
                    <span className="card-textcontent cardtext-buttoncontent absolute right-0 bottom-0 mb-0 text-white bg-[rgba(0,0,0,0.7)] group-hover:bg-gray-200 inline-block rounded-full py-2 px-3 font-medium w-6 h-6 flex justify-center items-center">
                      0
                    </span>
                  </div>
                  <div className="relative px-3">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="far"
                      data-icon="file"
                      className="svg-inline--fa fa-file text-xl text-[#9D9D9D]"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"
                      ></path>
                    </svg>
                    <span className="card-textcontent cardtext-buttoncontent absolute right-0 bottom-0 mb-0 text-white bg-[rgba(0,0,0,0.7)] group-hover:bg-gray-200 inline-block rounded-full py-2 px-3 font-medium w-6 h-6 flex justify-center items-center">
                      25
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="card p-[15px]">
          <div className="flex">
            <div>
            <img className="dark-icon-clr" src={unpinIcon} alt="pattern-image" />

            </div>
            <div className="ml-[auto] flex">
            <img className="pr-[10px] dark-icon-clr" src={shareIcon} alt="pattern-image" />
            <img className="dark-icon-clr" src={ThreeDots} alt="pattern-image" />

            </div>
          
          </div>
          <div className="upload-container flex text-center" style={{ background:  selectedColor || "#74CFFB", backgroundImage: background && background.includes('http') ? `url(${background})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center', }}>

           <span style={{fontSize:"30px",marginTop:"13px"}}>{selectedEmoji.emoji}</span>
          <span style={{marginTop:"25px",fontSize:"26px",background:selectedColor ? selectedColor : "#74CFFB"}}><FontAwesomeIcon icon={selectedIcon} /></span> 

          </div>
          <div className="flex">
            <div className="profile flex">
            <img src={Profile1} alt="pattern-image" />
            <img src={Profile2} alt="pattern-image" />
            <img src={Profile3} alt="pattern-image" />
            </div>
            <p className="share-count">3+ Shares</p>
            <div className="relative px-3 ml-[auto]">
                        <FontAwesomeIcon className="text-xl text-[#9D9D9D]" icon={fasFaFile} />
                        <span className="card-textcontent absolute right-0 bottom-0 mb-0 text-white bg-[rgba(0,0,0,0.7)] group-hover:bg-gray-200 inline-block rounded-full py-0.5 text-xs font-medium w-5 h-5 flex justify-center items-center">4</span>
                       </div>
                       
              </div>


        </div> */}
        <div className="update-history">
          <h6 className="mt-[30px]">History</h6>

          {background.includes("http") ? (
            <>
              <div className="history-list-item">
                <p>Background image Changed</p>
                <img
                  className="close-history"
                  src={Close}
                  onClick={() => {
                    setBackground("");
                  }}
                />
              </div>
            </>
          ) : null}

          {selectedColor ? (
            <>
              <div className="history-list-item">
                <p>Changed Color to</p>{" "}
                <span style={{ background: `${selectedColor}` }}></span>{" "}
                <img
                  className="close-history"
                  src={Close}
                  onClick={() => setSelectedColor("")}
                />
              </div>
            </>
          ) : null}

          {selectedImage ? (
            <>
              <div className="history-list-item">
                <p>Background image Changed</p>{" "}
                <img
                  className="close-history"
                  src={Close}
                  onClick={() => {
                    setSelectedImage("");
                  }}
                />{" "}
              </div>
            </>
          ) : null}

          {selectedIcon ? (
            <>
              {" "}
              <div className="history-list-item ">
                <p>Next Icon added</p> <FontAwesomeIcon icon={selectedIcon} />{" "}
                <img
                  className="close-history"
                  src={Close}
                  onClick={() => {
                    setSelectedIcon("");
                  }}
                />
              </div>
            </>
          ) : null}
          {selectedEmoji.length === 0 ? null : (
            <>
              <div className="history-list-item">
                <p>Emoji added</p> <small>{selectedEmoji.emoji}</small>{" "}
                <img
                  className="close-history"
                  src={Close}
                  onClick={() => {
                    setSelectedEmoji([]);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Popup;
