import { React, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import "./PricingPlans.scss";
import PricingPlan from "../Plans/YearlyPlan";
import MonthlyPlan from "../Plans/MonthlyPlans";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Planarrow from "../../../assets/icons/Big arrow.svg";
import { toastify } from "../../Toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PricingPlans() {
  const navigate = useNavigate();
  const [priceListY, setPriceListY] = useState([]);
  const [priceListM, setPriceListM] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userData =JSON.parse(localStorage.getItem('userData'));
    if (userData.isActiveSubscription) {
      navigate('/app/dashboard'); // Redirect to your main app page
    }
    
    const findObjectById = (array, id) => {
      return array.find(obj => obj.id === id);
    };

    const findObjectBytype = (array, subscriptionType) => {
      return array.find(obj => obj.subscriptionType === subscriptionType);
    };
    const fetchPlans = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}plans/list`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        // setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
        if(response){
          let productList = findObjectById(response?.data?.data?.productList,`${process.env.REACT_APP_MAIN_PLAN_ID}`);
          let pricelistY = findObjectBytype(productList.priceList,'year');
          let pricelistM = findObjectBytype(productList.priceList,'month');
          setPriceListY(pricelistY);
          setPriceListM(pricelistM);
        }
        setIsLoading(false);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchPlans();
    }
  }, []);

useEffect(()=>{
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tab= urlSearchParams.get('tab');
  const tokenId = urlSearchParams.get('tokenId');
  const token = localStorage.getItem('token');
  const userID=JSON.parse(localStorage.getItem('userData'))
  const setting=localStorage.getItem('settingsToken')
  if(!apiCalled && tokenId || setting){
    try {
      const response = axios.post('https://api.zimzee.com/api/users/token-verify',
      {
     token:tokenId ? tokenId :setting
      },
      {
        headers: {
            Authorization: `Bearer ${token}`,
          }
      }
      );
      
      console.log(response);
      if(response && tokenId || setting && userID){
        try {
          const response = axios.post('https://api.zimzee.com/api/team/accept-invitation',
          {
         tokenId:tokenId ? tokenId :setting,
         recipientUserId:userID._id,
          },
          {
            headers: {
                Authorization: `Bearer ${token}`,
              }
          }
          );
          if(response){
            localStorage.removeItem('settingsToken')
            setApiCalled(true)
            userID.isActiveSubscription = true;
            localStorage.setItem('userData',JSON.stringify(userID));
            navigate('/app/buckets');
            toastify("success", response.data.message);
          }
          
          console.log(response);
         
        } catch (error) {
          console.log(error)
          toastify("error", error?.response?.data?.message);
        }
      }

     
    } catch (error) {
      console.log(error)
      // console.error(error?.response?.data?.message,"error msg");
      toastify("error", error?.response?.data?.message);
    }
  }
},[])

  const createSubscription = async (planId,priceId,type,planPrice) => {
    localStorage.setItem('subscriptionType',type);
    localStorage.setItem('subscriptionplanId',planId);
    localStorage.setItem('subscriptionpriceId',priceId);
    localStorage.setItem('planPrice',planPrice);
    navigate('/app/planCheckout');
    // const token = localStorage.getItem('token');
    // try{
    //   const response = await axios.get(
    //     `${process.env.REACT_APP_API}subscriptions/attach-paymentMethod`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`, // Include the token in the request header
    //       },
    //     }
    //   ); // Replace this with the actual API endpoint
    //   console.log(response);
    //   if(response.data.data.client_secret){
    //     localStorage.setItem('subscriptionType',type);
    //     localStorage.setItem('subscriptionplanId',planId);
    //     localStorage.setItem('subscriptionpriceId',priceId);
    //     localStorage.setItem('planPrice',planPrice);
    //     localStorage.setItem('subscriptionclientSecret',response.data.data.client_secret);
    //     navigate('/app/planCheckout?clientSecret='+response.data.data.client_secret); // Redirect to your checkout page
    //   }
    // } catch (error) {
    //   console.error(error);
    //   toastify("error", error.response.data.message );
    // }
    // try {
    //   const response = await axios.post(`${process.env.REACT_APP_API}subscriptions/create`, { priceId:priceId,planId:planId},
    //   {
    //     headers: {
    //         Authorization: `Bearer ${token}`,
    //       }
    //   }
    //   );
    //   // console.log(response);
    //   if(response.data.data.clientSecret){
    //       localStorage.setItem('subscriptionType',type);
    //       localStorage.setItem('subscriptionplanId',planId);
    //       localStorage.setItem('subscriptionpriceId',priceId);
    //       localStorage.setItem('planPrice',planPrice);
    //       localStorage.setItem('subscriptionclientSecret',response.data.data.clientSecret);
    //       localStorage.setItem('subscriptionId',response.data.data.subscriptionId);
    //       navigate('/app/planCheckout?clientSecret='+response.data.data.clientSecret); // Redirect to your checkout page
    //       // localStorage.setItem('subscription')
    //   }
    //   // toastify("success", response.data.data.clientSecret);
    // } catch (error) {
    //   console.error(error);
    //   toastify("error", error.response.data.data.message );
    // }
  }
  return (
    <>
    {isLoading ? (
      <div className="App-loading">
        <div className="App-logo" title="Zimzee" />
      </div>
    ) : (
    <div className="pricing-plans">
      <div className="page-heading">
        <h3>Pricing Plans</h3>
        <p>Choose the perfect plan for you</p>
      </div>
      <div className="save-plan">
        <div className="sec-sectio">
          <h6>Save up to 33%</h6>
          <img src={Planarrow} alt="chrome-icon" />
        </div>
      </div>
      <Tabs> 
        <TabList>
          <Tab>Monthly</Tab>
          <Tab>Yearly</Tab>
        </TabList>
        <TabPanel>
          <MonthlyPlan  priceListM = {priceListM} createSubscription = {createSubscription} />
        </TabPanel>
        <TabPanel>
          <PricingPlan priceListY = {priceListY} createSubscription={createSubscription} />
        </TabPanel>
      </Tabs>
      <ToastContainer />
    </div>
    )}
    </>
  );
}
export default PricingPlans;
